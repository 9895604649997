<template>
    <svg :height="getSize" :width="getSize" @mousemove="mouseMoving" @click="selected" style="overflow:visible;">
        <linearGradient :id="grad" x1="0" x2="100%" y1="0" y2="0">
            <stop :offset="getFill" :stop-color="(rtl) ? inactiveColor : activeColor" />
            <stop :offset="getFill" :stop-color="(rtl) ? activeColor : inactiveColor" />
        </linearGradient>
        <polygon :points="starPointsToString" :fill="getGradId" :stroke="borderColor" :stroke-width="borderWidth" />
        <polygon :points="starPointsToString" :fill="getGradId" />
    </svg>
</template>

<script>
export default {
    name: 'Star',
    props: {
        fill: {
            type: Number,
            default: 0
        },
        size: {
            type: Number,
            default: 50
        },
        starId: {
            type: Number,
            required: true
        },
        activeColor: {
            type: String,
            required: true
        },
        inactiveColor: {
            type: String,
            required: true
        },
        borderColor: {
            type: String,
            default: '#000'
        },
        borderWidth: {
            type: Number,
            default: 0
        },
        padding: {
            type: Number,
            default: 0
        },
        rtl: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.calculatePoints();
        this.grad = Math.random().toString(36).substring(7);
    },
    computed: {
        starPointsToString() {
            return this.starPoints.join(',');
        },
        getGradId() {
            return `url(#${this.grad})`;
        },
        getSize() {
            return parseInt(this.size, 10) + parseInt(this.borderWidth * 3, 10) + this.padding;
        },
        getFill() {
            return (this.rtl) ? `${100 - this.fill}%` : `${this.fill}%`;
        }
    },
    methods: {
        calculatePoints() {
            this.starPoints = this.starPoints.map(point => ((this.size / 43) * point) + (this.borderWidth * 1.5));
        },
        /** Triggered when mouse is over component
         * @event star-mouse-move
         * @type {Object}
         */
        mouseMoving($event) {
            this.$emit('star-mouse-move', {
                event: $event,
                position: this.getPosition($event),
                id: this.starId
            });
        },
        getPosition($event) {
            // calculate position in percentage.
            const starWidth = (92 / 100) * this.size;
            const offset = (this.rtl) ? Math.min($event.offsetX, 45) : Math.max($event.offsetX, 1);
            const position = Math.round((100 / starWidth) * offset);
            return Math.min(position, 100);
        },
        /** Triggered when a star is selected
         * @event star-selected
         * @type {Object}
         */
        selected($event) {
            this.$emit('star-selected', {
                id: this.starId,
                position: this.getPosition($event)
            });
        }
    },
    data() {
        return {
            starPoints: [19.8, 2.2, 6.6, 43.56, 39.6, 17.16, 0, 17.16, 33, 43.56],
            grad: ''
        };
    }
};

</script>
