<template>
    <div class="media-upload" :class="{'media-upload-button': !expanded, 'media-upload-collapsible': collapsed}">
        <div class="dropzone" ref="upload"></div>
        <header class="media-upload-notice" v-if="expanded && set === 7">{{ t.cover_image_notice }}</header>
        <header class="media-upload-notice" v-if="expanded && isMisc">{{ t.misc_notice }}</header>
        <div class="media-upload-files">
            <div class="file" :class="{'success': uploading || file.success || file.complete}" v-for="(file, index) in files" :key="index">
                <div class="preview-image">
                    <div v-if="uploading && !file.success" class="dz-progress progress progress-striped active">
                        <span class="progress-bar" :style="`width: 100%`"></span>
                    </div>
                    <div v-if="file.success" class="dz-success-mark">
                        <i class="fa fa-4x fa-check"></i>
                    </div>
                    <div v-if="file.error" class="dz-error-mark">
                        <i class="fa fa-4x fa-times"></i>
                    </div>
                    <span v-if="!uploading && !file.success" class="fa-stack fa-stack-remove fa-lg" @click="removeFile(file.file)">
                        <i class="fa fa-circle fa-stack-2x"></i>
                        <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
                    </span>
                    <i v-if="!file.dataUrl && file.file.type.indexOf('video') !== -1" class="fa fa-play"></i>
                    <img class="preview-image" v-if="file.dataUrl" :src="file.dataUrl" />
                </div>
                <div class="file-slow alert-info" v-if="uploading && !file.success && file.slow">
                    {{ t.slow_message }}
                </div>
                <div v-if="!uploading && !file.success" class="preview-form">
                    <textarea v-if="showDescription" class="form-control dz-description"
                        :class="{'no-checkboxes': !showAdultCheckbox && !showPrivateCheckbox}" v-model="file.description"
                        :placeholder="t.description"></textarea>
                    <div class="sh-checkboxes">
                        <template v-if="showAdultCheckbox">
                            <input type="checkbox" v-model="file.adult" :id="file.file.name + 'adult'">
                            <label :for="file.file.name + 'adult'">{{ t.adult }}</label>
                        </template>
                        <template v-if="showPrivateCheckbox">
                            <input type="checkbox" v-model="file.private" :id="file.file.name + 'private'">
                            <label :for="file.file.name + 'private'">{{ t.private }}</label>
                        </template>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="text-danger" v-for="(error, index) in errors" :key="index" v-if="error">{{ error }}</div>
        <div class="btn-section">
            <button class="btn btn-primary media-upload-upload" v-if="showUploadButton && incompleteFiles"
                @click.prevent="start"><i class="fa fa-m3 fa-upload"></i> {{ gt.common.upload }}</button>
            <button class="btn btn-default" v-if="files.length && files.length < maxFiles"
                @click.prevent="addItem"><i class="fa fa-m3 fa-plus"></i> {{ t.add_item }}</button>
            <button class="btn btn-default media-upload-cancel" v-if="showCancel && expanded" @click.prevent="cancel">{{ gt.common.close }}</button>
        </div>
    </div>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';
import { mapState } from 'vuex';

export default {
    name: 'MediaUpload',
    props: {
        album: {
            type: Number
        },
        background: {
            type: Boolean,
            default: true
        },
        buttonText: {
            type: String,
            default: ''
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        cover: {
            type: Object
        },
        isAvatar: {
            type: Boolean,
            default: false
        },
        isMisc: {
            type: Boolean,
            default: false
        },
        maxFiles: {
            type: Number,
            default: 50
        },
        maxSetFiles: {
            type: Number,
            default: null
        },
        mediaType: {
            type: String,
            default: 'both'
        },
        privateAlbum: {
            type: Number,
        },
        set: {
            type: Number,
            default: null
        },
        showCancel: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        addItem() {
            this.$refs.upload.click();
        },
        // Dropzone callback
        cancel() {
            this.reset();
            this.$emit('media-upload-cancel');
            this.uploading = false;
        },
        // Dropzone callback
        fileAdded(file) {
            if (this.files.length >= this.maxFiles) {
                return false;
            }
            this.files.push({
                adult: false,
                complete: false,
                dataUrl: '',
                description: '',
                error: false,
                file,
                private: false,
                progress: 0,
                success: false,
                slow: false
            });
            return false;
        },
        fileThumbnailAdded(file, dataUrl) {
            if (this.files.length > this.maxFiles) {
                return;
            }
            const vueFile = this.getFile(file);
            vueFile.dataUrl = dataUrl;
        },
        getFile(file) {
            return this.files.filter(checkFile => checkFile.file === file)[0];
        },
        // Can be called externally '<mediaUpload ref="myUniqueID"></mediaUpload>' vm.$refs.myUniqueID.manuallyAddFile(file)
        manuallyAddFile(file) {
            if (!this.dropzone) {
                this.addFileAfterInit = file;
                return;
            }
            this.dropzone.addFile(file);
        },
        removeFile(file) {
            this.files = this.files.filter(checkFile => checkFile.file !== file);
            this.dropzone.removeFile(file);
            this.errors = [];
        },
        // Initiate file upload, triggered by clicking upload button
        start() {
            this.uploading = true;
            const files = this.dropzone.getQueuedFiles();
            let countResponses = 0;

            const filesLength = files.length;
            files.forEach((file) => {
                const vueFile = this.getFile(file);
                const isVideo = file.type.indexOf('video') !== -1;
                const data = {
                    name: file.name,
                    album: this.album,
                    background: this.background,
                    description: vueFile.description,
                    adult: vueFile.adult,
                    unsearchable: this.unsearchable,
                    isAvatar: this.isAvatar,
                    cover: this.cover
                };
                if (isVideo) {
                    data.media_set = 5;
                } else if (this.set) {
                    data.media_set = this.set;
                    if (this.set === 2) {
                        delete data.album;
                    }
                } else {
                    data.media_set = 1;
                }

                if (this.showPrivateCheckbox) {
                    if (vueFile.private && this.privateAlbum) {
                        data.album = this.privateAlbum;
                    }
                }

                file.time = Date.now();
                vueFile.interval = setInterval(() => {
                    if (Date.now() - file.time > 5000) {
                        vueFile.slow = true;
                    }
                }, 500);
                ajax.create('fileupload', data).then((responseData) => {
                    countResponses += 1;
                    file.uploadUrl = responseData.upload_spec.url;
                    file.hashed = responseData.data.filename;
                    file.params = responseData.upload_spec.params;
                    if (countResponses === filesLength) {
                        this.dropzone.processQueue();
                    }
                });
            });
        },
        // Dropzone callback
        processing(file) {
            this.dropzone.options.url = file.uploadUrl;
        },
        reset() {
            this.dropzone.removeAllFiles(true);
            this.files = [];
        },
        // Dropzone callback
        sending(file, xhr, formData) {
            this.getFile(file).progress = 100;
            Object.keys(file.params).forEach((key) => {
                formData.append(key, file.params[key]);
            });
        },
        // Dropzone callback
        success(file) {
            ajax.create('fileuploadcomplete', { filename: file.hashed }).then((response) => {
                this.$store.dispatch('notify', { msg: response.message });
                if (!this.background) {
                    file.collectionId = response.result;
                }
                this.$emit('media-upload-complete', file);
                const vueFile = this.getFile(file);
                vueFile.success = true;
                setTimeout(() => {
                    clearInterval(vueFile.interval);
                    vueFile.slow = false;
                    vueFile.complete = true;
                    if (!this.incompleteFiles) {
                        this.uploading = false;
                    }
                }, 100);
            });
        }
    },
    computed: {
        ...mapState({
            t: state => state.t.assets.file_upload,
            gt: state => state.gt,
            siteConfig: state => state.siteConfig
        }),
        acceptedFileTypes() {
            const photoTypes = ['.jpg', '.jpeg', '.png', '.gif'];
            const videoTypes = ['.mp4', '.avi', '.mpg', '.mpeg', '.mkv', '.mov', '.flv', '.m4v', '.wmv'];
            if (this.mediaType === 'photo') {
                return photoTypes.join(', ');
            }
            if (this.mediaType === 'video') {
                return videoTypes.join(', ');
            }
            return photoTypes.concat(videoTypes).join(', ');
        },
        dictDefaultMessage() {
            if (this.buttonText) {
                return `<div class="btn btn-default btn-large" onclick="event.preventDefault();">
                    <i class="fa fa-m3 fa-upload"></i>${this.buttonText}
                </div>`;
            }
            if ([1, 5, null].indexOf(this.set) === -1) {
                return this.t.select_photo.replace(
                    '%attributes',
                    'class="btn btn-primary btn-large" onclick="event.preventDefault();"><i class="fa fa-upload"></i'
                );
            }
            return this.t[`select_${this.mediaType}`].replace(
                '%attributes',
                'class="btn btn-primary btn-large" onclick="event.preventDefault();"><i class="fa fa-upload"></i'
            );
        },
        expanded() {
            if (this.files.length) {
                return true;
            }
            return !this.collapsed;
        },
        incompleteFiles() {
            return !!this.files.filter(checkFile => !checkFile.complete).length;
        },
        showAdultCheckbox() {
            return [1, 5, null].indexOf(this.set) !== -1 && (this.siteConfig.userLevel === 'Moderator'
                || this.siteConfig.userLevel === 'Administrator') && !this.isMisc;
        },
        showDescription() {
            return [1, 5, null].indexOf(this.set) !== -1;
        },
        showPrivateCheckbox() {
            return [1, 5, null].indexOf(this.set) !== -1 && this.privateAlbum && !this.isMisc;
        },
        showUploadButton() {
            return this.files.length;
        },
        unsearchable() {
            return this.set === 7 || this.isMisc;
        }
    },
    data() {
        return {
            addFileAfterInit: null,
            errors: [],
            files: [],
            uploading: false
        };
    },
    mounted() {
        import(/* webpackChunkName: "dropzone" */'dropzone').then((dropzoneImport) => {
            const Dropzone = dropzoneImport.default;
            Dropzone.autoDiscover = false;
            let dictMaxFilesExceeded;
            if (this.maxSetFiles === null || this.maxFiles < this.maxSetFiles) {
                dictMaxFilesExceeded += ` ${this.t.upload_limit_upgrade}`;
            } else {
                dictMaxFilesExceeded = this.t.upload_limit_reached.replace('%max', this.maxSetFiles);
            }

            this.$nextTick(() => {
                this.dropzone = new Dropzone(this.$refs.upload, {
                    addRemoveLinks: false,
                    acceptedFiles: this.acceptedFileTypes,
                    autoProcessQueue: false,
                    previewsContainer: false,
                    dictDefaultMessage: this.dictDefaultMessage,
                    dictMaxFilesExceeded,
                    maxFiles: this.maxFiles,
                    maxFilesize: 2000,
                    parallelUploads: 100,
                    error: (a, err) => {
                        this.errors.push(err);
                    },
                    addedfile: this.fileAdded.bind(this),
                    processing: this.processing.bind(this),
                    sending: this.sending.bind(this),
                    success: this.success.bind(this),
                    thumbnail: this.fileThumbnailAdded.bind(this),
                    url: '#'
                });

                if (this.addFileAfterInit) {
                    this.manuallyAddFile(this.addFileAfterInit);
                    this.addFileAfterInit = null;
                }
            });
        });
    },
    watch: {
        album() {
            if (this.dropzone) {
                this.reset();
            }
        }
    },
    beforeDestroy() {
        this.dropzone.disable();
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.media-upload {
    .media-upload-files {
        clear: both;
    }
    .file {
        margin-bottom: 10px;
        clear: both;
        &.success {
            float: left;
            clear: none;
        }
    }
    .success {
        img {
            opacity: 0.7;
        }
    }
    .file-slow {
        float: left;
        padding: 10px;
        width: calc(100% - 90px);
    }
    .preview-image {
        width: 70px;
        height: 70px;
        margin-right: 15px;
        float: left;
        position: relative;
        background-color: #f1f1f1;
        border: 1px solid #eee;
        .dz-progress, .dz-success-mark, .dz-error-mark {
            position: absolute;
            z-index: 2;
        }
        .dz-success-mark, .dz-error-mark {
            left: 12px;
            top: 10px;
            i {
                text-shadow: 0px 2px 4px #666;
            }
        }
        .dz-success-mark {
            color: $brand-success;
        }
        .dz-error-mark {
            color: $brand-danger;
        }
        .dz-progress {
            position: absolute;
            top: 39%;
            left: 5px;
            width: 60px;
        }
        .fa-play {
            position: absolute;
            color: #fff;
            left: 23px;
            font-size: 30px;
            top: 19px;
            text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
            opacity: 0.5;
        }
        .fa-stack-remove {
            position: absolute;
            right: -6px;
            top: -6px;
            z-index: 2;
            cursor: pointer;
            .fa-circle {
                color: $brand-danger;
            }
        }
    }
    .dz-description.no-checkboxes {
        height: 70px;
    }
    .dz-drag-hover {
        background-color: #f6f6f6;
    }
    .preview-form {
        float: left;
        width: calc(100% - 85px);
        textarea {
            height: 32px;
        }
        .sh-checkboxes {
            margin-top: 6px;
            input + label {
                margin: 0;
                width: 49%;
                max-width: 100px;
            }
        }
    }
    .btn-section {
        margin-top: 5px;
    }
    &.media-upload-collapsible .btn-section {
        margin-bottom: 15px;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
    }
    &.media-upload-button {
        display: inline-block;
        .dz-message .btn, .btn-section {
            margin: 0;
        }
        .btn-section {
            padding: 0;
            border: 0;
        }
    }
}

.dz-error .dz-error-mark {
    color: #fff;
    text-shadow: 0 0px 8px #666;
    opacity: 0.8 !important;
}

.media-upload-notice {
    margin-top: -5px;
    margin-bottom: 5px;
    text-align: center;
    padding: 5px;
    font-size: 1.2em;
    font-weight: 300;
}

.dz-message {
    text-align: center;
    .btn {
        margin-bottom: 10px;
    }
}
</style>
