export default function (element) {
    const viewport = {};

    viewport.top = typeof window.scrollY === 'undefined' ? window.pageYOffset : window.scrollY;
    viewport.bottom = viewport.top + window.innerHeight;
    const bounds = {};
    const rect = element.getBoundingClientRect();
    bounds.top = rect.top + viewport.top;
    bounds.bottom = bounds.top + element.offsetHeight;
    return ((bounds.top <= viewport.bottom) && (bounds.bottom >= viewport.top));
}
