
/**
 * @mixin - phtml form pagination user input validation.
 * @requires pageInput, totalPages
 */

import { mapActions, mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            gt: state => state.gt.assets.pagination
        })
    },
    mounted() {
        const form = document.getElementById('page-select-form');
        if (form) {
            form.addEventListener('submit', (event) => {
                event.preventDefault();
                this.checkUserInput(event);
            });
        }
    },
    methods: {
        ...mapActions(['notify']),
        checkUserInput(event) {
            const url = event.target[0].value;
            const totalPages = parseInt(event.target[2].value, 10);
            const multiplier = event.target[3].value;
            let pageInput = parseInt(event.target[4].value, 10);
            if (pageInput > 0 && pageInput <= totalPages) {
                let replacement = 1000001;
                if (multiplier > 1) {
                    replacement = (replacement - 1) * multiplier;
                    pageInput = (pageInput - 1) * multiplier;
                }
                window.location = url.replace(replacement, pageInput);
                return;
            }
            this.notify({ msg: this.gt.page_select_error.replace('%total', totalPages), type: 'error' });
        }
    }
};
