<template>
    <div class="friend-select">
        <div class="form-group">
            <label for="modal-invite-search">{{ gt.username }}</label>
            <div class="input-group">
                <input type="text" :class="['form-control', {'focused': searchFocused}]" @keyup="keyup" name="selectfriend"
                    @keyup.up="position -= 1" @keyup.down="position += 1" autocomplete="off"
                    @keydown.enter.prevent="addUser" @keyup.esc="searchFocused = false" v-model="searchTerm" @click="searchFocused = true"
                        ref="inputBox">
                 <span class="input-group-btn">
                    <button class="btn btn-primary" @click.prevent="addUser">{{ gt.add }}</button>
                </span>
            </div>
            <div class="friend-list" v-if="searchFocused" ref="friendList">
                <loading v-if="loading"></loading>
                <compact-user v-if="!loading && filteredFriends.length" :selectable="true" v-on:userSelected="selected"
                    v-on:userUnselected="unselected"
                    v-for="(friend, index) in filteredFriends" :class="{'highlight': position === index}" :key="friend.id"
                        :user="friend"></compact-user>
                <div class="clearfix"></div>
            </div>
            <div class="selected-list" v-if="!loading && selectedFriends.length">
                <compact-user v-for="friend in selectedFriends" :key="friend.userName" :user="friend">
                    <span class="fa-stack fa-lg" @click="unselected(friend)">
                        <i class="fa fa-circle fa-stack-2x"></i>
                        <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
                    </span>
                </compact-user>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import CompactUser from 'Profile/component/CompactUser.vue';
import Loading from 'Site/component/Loading.vue';

import ajax from '../js/util/ajax.fetch';

export default {
    name: 'SelectFriends',
    components: {
        CompactUser,
        Loading
    },
    computed: {
        filteredFriends() {
            // Apply passed in filter
            let filtered = this.friends.filter(friend => this.filter.indexOf(friend.id) === -1);
            // Apply account type filter
            if (this.accountTypes.indexOf('any') === -1) {
                filtered = filtered.filter(friend => this.accountTypes.indexOf(friend.avatartype) !== -1);
            }
            filtered = filtered.filter(friend => this.selectedFriends.indexOf(friend) === -1);

            // Apply searchTerm filter
            return filtered.filter(friend => friend.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1);
        },
        ...mapState({
            gt: state => state.gt.common,
            t: state => state.t
        })
    },
    data() {
        return {
            filterLocally: false,
            friends: [],
            limit: 200,
            loading: true,
            position: -1,
            previousTerm: '',
            searchTerm: '',
            searchFocused: false,
            selectedFriends: [],
            totalFriends: 0
        };
    },
    methods: {
        addUser() {
            if ((this.position === -1 || !this.filteredFriends.length) && this.searchTerm) {
                this.selected({ userName: this.searchTerm });
            } else if (this.position !== -1) {
                this.selected(this.filteredFriends[this.position]);
            }
            this.position = -1;
            return false;
        },
        fetch() {
            this.selectedFriends = [];
            this.$parent.$emit('hideSubmit');
            if (!this.friends.length || (this.totalFriends > this.limit && !this.filterLocally)) {
                // Timeout ensures we ignore the initial click
                setTimeout(() => {
                    document.querySelector('html').addEventListener('click', (e) => {
                        if (this.$el.contains(e.target)) {
                            return;
                        }

                        this.searchFocused = false;
                    });
                }, 500);
                this.searchFocused = true;
                this.$nextTick(() => {
                    this.$refs.inputBox.focus();
                });
                this.loading = true;
                this.friends = [];
                this.previousTerm = Object.assign({}, this.searchTerm);
                ajax.get('friend', { 'max-results': this.limit, sort: 'identity', filter: this.searchTerm }).then((response) => {
                    this.totalFriends = response.total;
                    if (response.items.length < this.limit) {
                        this.filterLocally = true;
                    }
                    response.items.forEach((val) => {
                        val.name = val.identity;
                        this.friends.push(val);
                    });
                    this.loading = false;
                });
            }
        },
        keyup() {
            this.searchFocused = true;
            if (this.searchTerm === '') {
                this.filterLocally = false;
            }
            if (this.previousTerm !== this.searchTerm || this.searchTerm === '') {
                this.fetch();
            }
        },
        /** Triggered when friends are selected
         * @event selected-friends
         * @type {Array}
         */
        selected(friend) {
            if (this.selectedFriends.map(checkFriend => checkFriend.userName).indexOf(friend.userName) !== -1) {
                return;
            }
            this.$parent.$emit('showSubmit');
            if (this.single) {
                this.selectedFriends = [friend];
            } else {
                this.selectedFriends.push(friend);
            }
            this.searchTerm = '';
            this.searchFocused = false;
            this.$emit('selected-friends', this.selectedFriends);
        },
        unselected(friend) {
            this.selectedFriends = this.selectedFriends.filter(selected => selected !== friend);
            if (this.selectedFriends.length < 1) {
                this.$parent.$emit('hideSubmit');
            }
            this.$emit('selected-friends', this.selectedFriends);
        },
        submit() {
            if (this.selectedFriends.length) {
                this.$emit('submitmodal', this.selectedFriends.map(selectedFriend => selectedFriend.userName));
                this.$parent.$emit('hideModal');
            }
        }
    },
    mounted() {
        this.$on('show', this.fetch);
        this.$on('submit', this.submit);
        if (this.preload) {
            this.fetch();
        }
    },
    watch: {
        position() {
            this.$nextTick(() => {
                if (this.searchFocused) {
                    this.$refs.friendList.scrollTop = this.position >= 0 ? this.position * 60 : 0;
                }
            });
        }
    },
    props: {
        accountTypes: {
            type: Array,
            default() {
                return ['any'];
            }
        },
        filter: {
            type: Array,
            default() {
                return [];
            }
        },
        preload: {
            type: Boolean,
            default: false
        },
        single: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
.friend-select {
    .form-group {
        position: relative;
    }
    input.focused {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .alert {
        border-radius: 0;
        border: 0;
        margin: 0;
    }
    .selected-list {
        margin-top: 7px;
        margin-bottom: -16px;
    }
    .friend-list {
        position: absolute;
        background-color: #fff;
        border: 1px solid #ddd;
        box-shadow: 0 2px 2px #eee;
        border-top: 0;
        margin: 0;
        z-index: 101;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        .highlight {
            background-color: #ddd;
        }
        .compact-user-details .username {
            max-width: 100%;
        }
        .profile-result {
            width: 100%;
            height: 60px;
            margin: 0;
            box-shadow: none;
            padding: 5px;
            float: left;
            &:hover {
                background-color: #eee;
            }
        }
    }
}
</style>
