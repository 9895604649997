<template>
<div class="wall-media-picker">
    <ul class="nav nav-tabs modal-nav">
        <li v-for="page in pages" :key="page.name" :class="{ 'active': currentPage === page.name }">
            <a class="site-bg" href="#" @click.prevent="changePage(page.name)"><i :class="['fa', 'fa-' + page.icon]"></i> {{ page.text }}</a>
        </li>
    </ul>
    <media-picker v-if="currentPage === 'media'" @selected-media="updateMedia" :type="[0, 2]" :show-private="showPrivate"></media-picker>
    <div v-else-if="currentPage === 'upload'" id="media-upload">
        <div class="media-upload-notice">
            <p v-html="uploadMessage"></p>
        </div>
        <media-upload :album="album" :background="false" @media-upload-complete="uploadComplete" :is-misc="true"></media-upload>
    </div>
</div>
</template>

<script>
import MediaPicker from 'Media/component/MediaPicker.vue';
import MediaUpload from 'Media/component/MediaUpload.vue';
import { mapState } from 'vuex';

export default {
    name: 'WallMediaPicker',
    components: {
        MediaPicker,
        MediaUpload
    },
    computed: {
        ...mapState(['gt', 't', 'siteConfig']),
        ...mapState({
            album: state => state.config.data.miscAlbum
        }),
        pages() {
            const pages = [
                { name: 'media', text: this.t.my_media, icon: 'user' }
            ];
            if (this.album) {
                pages.push({ name: 'upload', text: this.gt.common.upload, icon: 'upload' });
            }
            return pages;
        },
        uploadMessage() {
            return this.t.upload_message.replace('REPLACE_ME', this.siteConfig.user.name).replace('APPEND_ALBUM', `/album/${this.album}`);
        }
    },
    data() {
        return {
            currentPage: '',
            media: []
        };
    },
    methods: {
        updateMedia(media) {
            this.media = media;
        },
        changePage(page) {
            this.currentPage = page;
        },
        uploadComplete(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.media.push({
                    id: file.collectionId,
                    media: {
                        medium: {
                            url: e.target.result
                        }
                    }
                });
            };
            reader.readAsDataURL(file);
        }
    },
    mounted() {
        this.$on('show', () => {
            this.currentPage = 'media';
        });
        this.$on('submit', () => {
            this.$parent.$emit('hideModal');
            this.$emit('select-media', this.media);
            this.media = [];
            this.$children.forEach((child) => {
                child.$emit('reset');
            });
        });
    },
    props: {
        showPrivate: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
.wall-media-picker {
    #media-upload > .media-upload-notice {
        margin: 0;
        border: 0;
    }
    .sh-checkboxes {
        margin-top: -10px;
        margin-bottom: 10px;
    }
}
</style>
