import store from 'Site/js/store';

export default {
    bind(el, binding) {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            store.dispatch('sendReport', binding.value);

            return false;
        });
    }
};
