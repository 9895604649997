<template>
<form @submit.prevent="post(false)" class="wall-create-form">
    <div :class="['form-group', { 'has-error': form.errors.has('text') }]">
        <TextBox @input="validate('text')" :placeholder="placeholderText + '...'" name="text" v-model="form.text" ref="textbox"
            autofocus="autofocus" :post-type="postType"></TextBox>
        <span class="help-block" v-if="form.errors.has('text')">{{ form.errors.get('text') }}</span>

        <div v-if="mood" class="mood-choice">
            <span @click.prevent="removeMood" class="cancel-button">
                <site-icon name="times-circle" scale="1.4"></site-icon>
            </span>
            <span><i :class="`icon emote emote-${mood.name}`"></i> {{ t.selected_mood.replace('%mood', mood.title) }} </span>
        </div>

        <div class="wall-create-form-media">
            <media v-for="media in medias" :key="media.id" :media="media" :private-allowed="true" :stats="false" :card="false" :selectable="false"
                size="small">
                <span class="fa-stack fa-stack-remove fa-lg" @click="removeMedia(media)">
                    <i class="fa fa-circle fa-stack-2x"></i>
                    <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
                </span>
            </media>
        </div>

        <event v-if="event" :event="event">
            <span class="cancel-button" @click="removeEvent">
                <site-icon name="times-circle" scale="1.4"></site-icon>
            </span>
        </event>

        <div class="wall-create-form-buttons">
            <button class="btn btn-default" @click.prevent="showMediaPicker = !showMediaPicker">
                <i v-if="!isMobile" class="fa fa-camera"></i> {{ t.add_media }}
            </button>
            <button v-if="!event && canAddEvents" class="btn btn-default" @click.prevent="showEventPicker = !showEventPicker">
                <i v-if="!isMobile" class="fa fa-calendar"></i> {{ t.add_party }}
            </button>
            <button v-if="!mood && canAddMoods" class="btn btn-default" @click.prevent="showMoodPicker = !showMoodPicker">
                <i v-if="!isMobile" class="fa fa-smile-o"></i> {{ t.add_mood }}
            </button>
            <div class="btn-group wall-submit pull-right">
                <button v-if="!showStatusPostSettings" type="submit" class="btn btn-primary" :disabled="loading || form.errors.any() || !validated">
                    <span v-if="!loading">{{ t.post }}</span>
                    <loading v-if="loading" :small="true"></loading>
                </button>
                <button v-if="showStatusPostSettings" class="btn btn-primary" @click.prevent="postStatus('friendsAndFollowers')" :disabled="loading || form.errors.any() || !validated">
                    <span v-if="!loading">{{ t.post_to_friends_followers }}</span>
                    <loading v-if="loading" :small="true"></loading>
                </button>
                <button type="button" class="btn btn-primary dropdown-toggle" v-dropdown :disabled="loading || form.errors.any() || !validated">
                    <i class="fa fa-chevron-down"></i>
                </button>
                <ul v-if="showStatusPostSettings" class="dropdown-menu">
                    <li><a href="#" @click.prevent="postStatus('friends')">{{ t.post_to_friends }}</a></li>
                    <li><a href="#" @click.prevent="postStatus('followers')">{{ t.post_to_followers }}</a></li>
                    <li><a href="#" @click.prevent="postStatus('matches')">{{ t.post_to_matches }}</a></li>
                </ul>
                <ul v-if="!showStatusPostSettings" class="dropdown-menu">
                    <li><a href="#" @click.prevent="post(true)">{{ t.post_and_pin }}</a></li>
                </ul>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>

    <modal v-model="showMoodPicker" :title="t.mood_modal_title"><mood-picker @select-mood="addMood"></mood-picker></modal>
    <modal v-model="showEventPicker" :title="t.add_party"><event-picker @select-event="addEvent"></event-picker></modal>
    <modal v-model="showMediaPicker" :title="t.add_media" :submit="t.add">
        <wall-media-picker @select-media="addMedia" :show-private="showPrivateImages"></wall-media-picker>
    </modal>
</form>
</template>

<script>
import 'vue-awesome/icons/times-circle';
import dropdownDirective from 'Site/directive/dropdown';
import Event from 'Parties/component/EventSmall.vue';
import EventPicker from 'Parties/component/EventPicker.vue';
import MoodPicker from 'Profile/component/MoodPicker.vue';
import Form from 'Site/js/util/form';
import Loading from 'Site/component/Loading.vue';
import Media from 'Media/component/Media.vue';
import Modal from 'Site/component/Modal.vue';
import TextBox from 'Site/component/TextBox.vue';
import { uniq } from 'underscore';
import { mapState } from 'vuex';
import WallMediaPicker from './WallMediaPicker.vue';

export default {
    name: 'Form',
    components: {
        Event,
        EventPicker,
        MoodPicker,
        Loading,
        Media,
        Modal,
        TextBox,
        WallMediaPicker
    },
    computed: {
        ...mapState({
            id: state => state.config.data.id,
            t: state => state.t,
            permissions: state => state.config.data.permissions,
            isMobile: state => state.isMobile,
        }),
        showStatusPostSettings() {
            if (this.postType === 'profile') {
                return true;
            }
            return false;
        },
        placeholderText() {
            if (this.postType === 'profile') {
                return this.t.write_status;
            }
            return this.t.write_something;
        },

    },
    data() {
        return {
            loading: false,
            event: null,
            mood: null,
            form: new Form(),
            medias: [],
            showEventPicker: false,
            showMediaPicker: false,
            showMoodPicker: false,
            validated: false
        };
    },
    directives: {
        dropdown: dropdownDirective
    },
    methods: {
        addEvent(selectedEvent) {
            this.event = selectedEvent;
        },
        addMedia(mediaItems) {
            this.medias = this.medias.concat(mediaItems);
        },
        addMood(selectedMood) {
            this.mood = selectedMood;
            this.form.mood = selectedMood;
        },
        removeEvent() {
            this.event = null;
        },
        removeMood() {
            this.mood = null;
        },
        removeMedia(mediaItem) {
            const index = this.medias.indexOf(mediaItem);
            this.medias.splice(index, 1);
        },
        validate(field) {
            this.validated = true;
            this.form.errors.clear(field);
            this.form.validate(field);
        },
        post(pin = false) {
            if (this.event) {
                this.form.event = this.event.id;
            }
            this.form.media = uniq(this.medias.map(mediaItem => mediaItem.id));
            this.form.pinned = pin;
            const form = this.form.create('wall');
            if (form) {
                this.loading = true;
                form.then((response) => {
                    this.loading = false;
                    this.validated = false;
                    this.$store.dispatch('notify', { msg: response.message });
                    this.$emit('create', response.item);
                    this.medias = [];
                    this.event = null;
                    this.$refs.textbox.setContent();
                });
            }
        },
        postStatus(postTo) {
            if (!this.permissions.hasPremium) {
                this.$store.dispatch('showUpgrade', { reason: 'send-profile-status' });
                return false;
            }
            if (postTo === 'matches') {
                if (!this.permissions.hasExtras) {
                    this.$store.dispatch('showUpgrade', { reason: 'send-matches-profile-status' });
                    return false;
                }
            }
            this.form.media = uniq(this.medias.map(mediaItem => mediaItem.id));
            this.form.postTo = postTo;
            this.form.mood = this.mood;
            const form = this.form.create('wall');
            if (form) {
                this.loading = true;
                form.then((response) => {
                    this.loading = false;
                    this.validated = false;
                    this.$store.dispatch('notify', { msg: response.message });
                    this.$emit('create', response.item);
                    this.medias = [];
                    this.mood = null;
                    this.$refs.textbox.setContent();
                });
            }
        }
    },
    mounted() {
        this.form = new Form({
            event: null,
            foreign: this.id,
            pinned: false,
            text: '',
            type: this.postType,
            media: [],
            postTo: 'matches',
            mood: null,
        }, {
            text: [
                { type: 'required' }
            ]
        });
    },
    props: {
        canAddEvents: {
            type: Boolean,
            default: false
        },
        canAddMoods: {
            type: Boolean,
            default: false
        },
        postType: String,
        showPrivateImages: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";
.wall-create-form {
    margin-bottom: 20px;
    padding-bottom: 10px;
    background-color: #fff;
    @include card;
    .form-group {
        margin: 0;
    }
    .textbox {
        border: 0;
    }
    .form-group .wall-create-form-buttons button, .form-group .wall-submit {
        i {
            margin-right: 5px;
        }
        margin: 5px 10px;
    }
    .help-block {
        margin-left: 11px;
    }
    .wall-create-form-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .btn-default {
            @media (max-width: $screen-xs-max) {
                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(2) ~ button {
                    width: 100%;
                }
                &:first-child:nth-last-child(3),
                &:first-child:nth-last-child(3) ~ button {
                    width: 43.333%;
                }
                &:first-child:nth-last-child(4),
                &:first-child:nth-last-child(4) ~ button {
                    width: 27%;
                }
            }
            @media (min-width: $screen-xs-max) {
                width: auto;
                justify-content: flex-start;
            }
        }
        .wall-submit {
            width: 100%;
            @media (min-width: $screen-xs-max) {
                margin-left: auto;
                width: auto;
            }
            button {
                margin: 0;
                &:first-child {
                    width: calc(100% - 36px);
                }
            }
        }
    }
    .event-small {
        margin: 10px 10px 0 10px;
        .fa-stack-remove {
            cursor: pointer;
            .fa-circle {
                color: $brand-danger;
            }
        }
    }
    .mood-choice {
        padding: 0 15px;
        line-height: 43px;
        margin: 10px 10px 0 10px;
        border: 1px solid #eee;
        font-size: 1.15em;
        position: relative;
        .icon {
            margin-right: 5px;
        }
    }
}

.cancel-button {
    position: absolute;
    right: 5px;
    top: 0;
    svg {
        fill: #a94442;
        color: #a94442;
        &:hover {
            cursor: pointer;
        }
    }
}

.wall-create-form-media {
    margin: 0 5px;
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    .fa-remove {
        color: #fff;
    }
}
</style>
