<template>
<div v-if="!isLush" class="extras-logo">
    <span>e</span>
    <span>X</span>
    <span>t</span>
    <span>r</span>
    <span>a</span>
    <span>s</span>
</div>
<span v-else class="supporter-plus-logo">Supporter<span>Plus</span></span>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ExtrasLogo',
    computed: {
        ...mapState(['siteConfig']),
        isLush() {
            return this.siteConfig.siteShortCode === 'lush';
        }
    }
};
</script>
