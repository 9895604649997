<template>
<a :class="['notify', 'alert', 'shake', 'alert-'+ bootstrapType, {'fade':fade}]" @click="clickHandler" :href="url">
    <button type="button" class="close" @click.prevent="remove" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <avatar :user="user" size="medium" v-if="user"></avatar>
    <p :class="{'notify-has-user':user}">{{ msg }}</p>
    <div v-if="buttons" class="notify-buttons">
        <button v-for="(button, index) in buttons" :key="index" :class="['btn', 'btn-' + button.class]"
            @click.prevent.stop="buttonClick(index)">{{ button.text }}</button>
    </div>
</a>
</template>
<script>
import Avatar from 'Profile/component/Avatar.vue';

export default {
    name: 'Notify',
    computed: {
        bootstrapType() {
            if (this.type === 'error') {
                return 'danger';
            }
            return this.type;
        }
    },
    methods: {
        buttonClick(index) {
            const button = this.buttons[index];
            if (typeof button.callback === 'function') {
                button.callback();
            }

            this.remove();
        },
        clickHandler() {
            if (this.callback) {
                this.callback();
                return false;
            }
            return true;
        },
        /** Triggered when a notify is removed
         * @event remove
         * @type {null}
         */
        remove() {
            this.$emit('remove');
        }
    },
    props: {
        buttons: Array,
        fade: Boolean,
        callback: {
            type: Function
        },
        id: {
            type: String,
            required: true
        },
        msg: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'success'
        },
        url: {
            type: String,
            default: '#'
        },
        user: Object
    },
    components: {
        Avatar
    }
};
</script>

<style lang="scss">
.notify {
    display: block;
    opacity: 1;
    transition: opacity 400ms;
    &.fade {
        opacity: 0;
    }
    .profile-result-avatar {
        display: inline-block;
    }
    .notify-has-user {
        display: inline-block;
        margin-left: 5px;
        max-width: 205px;
        vertical-align: top;
    }
    &.alert {
        font-weight: 400;
        border-radius: 2px;
        color: #fff;
        .close {
            text-shadow: none;
            opacity: 0.8;
            color: #fff;
        }
    }
    &.alert-notice {
        background-color: #fff;
        border-color: #D4D4D4;
        color: #464646;
        &:hover {
            background-color: #eee;
        }
        .close {
            color: #464646;
        }
    }
    &.alert-success {
        border: 0;
        background-color: #579958;
    }
    &.alert-danger {
        border: 0;
        background-color: #B4110E;
    }
    &:hover {
        text-decoration: none;
    }
    .notify-buttons {
        margin-top: 15px;
        margin-bottom: -5px;
    }
}
</style>
