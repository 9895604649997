<template>
    <div class="mood-picker">
        <loading v-if="loading"></loading>
        <span v-for="mood in moods" class="mood" :key="mood.name" @click.prevent="select(mood)">
            <i :class="`emote emote-${mood.name}`"></i> {{ mood.title }}
        </span>
    </div>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';
import Loading from 'Site/component/Loading.vue';
import { mapState } from 'vuex';

export default {
    name: 'MoodPicker',
    components: {
        Loading
    },
    computed: mapState(['t']),
    data() {
        return {
            moods: [],
            loading: false,
            selectedMood: null
        };
    },
    methods: {
        load() {
            if (this.moods.length) {
                return;
            }
            this.loading = true;

            ajax.get('mood').then((response) => {
                this.loading = false;
                this.moods = response.items;
            });
        },
        select(mood) {
            this.$emit('select-mood', mood);
            this.$parent.$emit('hideModal');
            if (this.selectedMood === mood) {
                this.selectedMood = null;
            } else {
                this.selectedMood = mood;
            }
        }
    },
    mounted() {
        this.$on('show', this.load);
    },
};

</script>

<style lang="scss">
@import "~Site/scss/variables";
.mood-picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    .mood {
        width: 46%;
        font-size: 1.2em;
        border: 1px solid #ccc;
        transition: border-color 200ms linear;
        margin: 6px 0;
        padding: 10px;
        text-align: center;
        @media( min-width: $screen-sm-max ) {
            text-align: left;
        }
        &:hover {
            cursor: pointer;
            border-color: #444;
        }
        .emote {
            display: block;
            margin: 0 auto;
            @media( min-width: $screen-sm-max ) {
                display: inline-block;
                margin: unset;
            }
        }
    }
}
</style>
