<template>
<div :class="['comment', {'comment-highlight': processedComment.highlight}]">
    <loading v-if="loading"></loading>
    <div v-if="!loading" class="comment-user">
        <avatar :user="processedComment.user" size="medium"></avatar>
    </div>
    <div v-if="!loading" class="comment-content">
        <a v-if="processedComment.user.links" :href="processedComment.user.links.profile" @click="logClick('profile')"
            class="comment-username" v-miniprofile="{ user: processedComment.user ? processedComment.user.id : null, metrics }">
            {{ processedComment.user.identity }}
        </a>
        <span v-if="!processedComment.user.links" class="comment-username"
            v-miniprofile="{ user: processedComment.user ? processedComment.user.id : null, metrics }">
            {{ processedComment.user.identity }}
        </span>
        <div class="profile-result-user-icons" v-if="!processedComment.user.isClub">
            <i class="fa fa-check-square is-verified" v-if="comment.user.isVerified"
            :title="gt.verified.replace('%username', comment.user.userName)"></i>
            <i v-for="(person, index) in processedComment.user.persons" :key="index" :class="['fa', 'fa-user', person.genderClass]"></i>
        </div>
        <div>
            <editable v-if="processedComment.edit && !report" class="comment-text" type="textbox" :editable="enableEdit"
                property="content" v-model="processedComment.content" :params="editParams"></editable>
            <span v-else class="comment-text" v-html="processedComment.content"></span>
            <template v-if="processedComment.likes"><slot name="like"></slot></template>
        </div>
        <div class="comment-media">
            <media v-if="processedComment.media.length" v-for="(media, index) in processedComment.media" :key="media.id" :stats="false" :card="false"
                :media="media" @click.native="checkOnMedia(index)">
                <span class="fa-stack fa-stack-remove fa-lg" @click.stop="removeMedia(media)" v-if="processedComment.edit && enableEdit">
                    <i class="fa fa-circle fa-stack-2x"></i>
                    <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
                </span>
            </media>
        </div>
        <slot name="bottom"></slot>
    </div>
    <div v-if="!loading" class="comment-actions">
        <span class="comment-date">{{ processedComment.createdAt }}</span>
        <a v-if="processedComment.depth < 1 && user && !processedComment.likes" href="#" @click.prevent="canReply()">{{ gt.reply }}</a>
        <span v-if="!report && user" class="btn-group dropup">
            <a href="#" v-dropdown>
                {{ gt.more }}
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
                <li v-if="processedComment.edit && !processedComment.likes">
                    <a href="#" @click.prevent="enableEdit = !enableEdit">{{ gt.edit_comment }}</a>
                </li>
                <li v-if="processedComment.delete">
                    <a href="#" @click.prevent="deleteComment">{{ gt.delete_comment }}</a>
                </li>
                <li v-if="!processedComment.delete">
                    <a href="#" :title="gt.report_comment" v-report="reportParams">{{ gt.report_comment }}</a>
                </li>
                <slot></slot>
            </ul>
        </span>
    </div>
    <comment-form v-if="!loading && showForm" @add="addReply" :upgrade-prompt="upgradePrompt" :id="foreign" :type="type"
        :t="commentFormTranslations" :require-moderation="requireModeration" :parent="processedComment.id"></comment-form>
    <div class="comment-replies" v-if="processedComment.replies.length">
        <a v-if="numRepliesToLoad && !loading && !loadingMore" href="#" @click.prevent="loadMore" class="btn btn-default btn-block">
            {{ globalT.common.load_x_more.replace('%num', numRepliesToLoad) }}
        </a>
        <loading v-if="loadingMore"></loading>
        <comment v-if="processedComment.replies.length" v-for="reply in processedComment.replies" :key="reply.id" :comment="reply" :t="t"
            @reply="bubbleReply" @delete="removeReply" :require-moderation="requireModeration" :upgrade-prompt="upgradePrompt"
            :foreign="foreign" :type="type">
        </comment>
    </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Avatar from 'Profile/component/Avatar.vue';
import Editable from 'Site/component/Editable.vue';
import Loading from 'Site/component/Loading.vue';
import Media from 'Media/component/Media.vue';

import ajax from 'Site/js/util/ajax.fetch';
import { replaceInHtml } from 'Site/js/util/emoticons';
import { emoticons } from 'Site/js/lib/redactor/redactor.config';
import dropdownDirective from 'Site/directive/dropdown';
import { logActivityClick } from 'Site/js/util/metrics';
import miniprofileDirective from 'Profile/directive/miniprofile';
import reportDirective from 'Abuse/directive/report';

import CommentForm from './CommentForm.vue';

export default {
    name: 'Comment',
    components: {
        Avatar,
        CommentForm,
        Editable,
        Loading,
        Media

    },
    computed: {
        ...mapState({
            globalT: state => state.gt,
            user: state => state.siteConfig.user.id,
            gt: state => Object.assign(state.gt.common, state.gt.feedback.comment)
        }),
        commentFormTranslations() {
            const t = Object.assign(this.t);
            t.comment = this.gt.reply;
            t.write_a_comment = this.$store.state.gt.feedback.comment_form.write_a_reply;
            return t;
        },
        numRepliesToLoad() {
            const number = this.comment.numReplies - this.comment.replies.length;
            if (number < this.limit) {
                return number;
            }
            return this.limit;
        },
        processedComment() {
            const processed = this.comment;
            if (!processed.edit) {
                processed.content = replaceInHtml(processed.content, emoticons);
            }
            if (!processed.user) {
                processed.user = {
                    identity: this.gt.unknown,
                    links: {
                        profile: '#'
                    }
                };
            }
            return processed;
        }
    },
    directives: {
        dropdown: dropdownDirective,
        miniprofile: miniprofileDirective,
        report: reportDirective
    },
    data() {
        return {
            limit: 5,
            loading: false,
            loadingMore: false,
            editParams: {
                url: 'feedback',
                type: this.comment.type,
                id: this.comment.id
            },
            enableEdit: false,
            reportParams: {
                component: 'comment',
                componentData: this.comment,
                id: this.comment.id,
                type: 7,
                title: '',
                user: this.comment.user ? this.comment.user.id : null
            },
            showForm: false
        };
    },
    methods: {
        ...mapActions(['showConfirm', 'showMediaViewer']),
        addReply(reply) {
            this.showForm = false;
            this.$emit('reply', { reply, parent: this.comment });
        },
        bubbleReply(data) {
            this.$emit('reply', data);
        },
        canReply() {
            if (!this.comment.canReply) {
                this.$store.dispatch('showUpgrade', { reason: 'leave-comment', metrics: this.metrics });
                return;
            }
            this.showForm = !this.showForm;
        },
        checkOnMedia(index) {
            if (!this.onMedia) {
                this.showMediaViewer({ media: this.processedComment.media, start: index });
            }
        },
        deleteComment() {
            this.showConfirm({
                text: this.gt.delete_confirm,
                callback: () => {
                    ajax.delete('feedback', { id: this.comment.id }).then((response) => {
                        this.$store.dispatch('notify', { msg: response.message });
                        this.$emit('delete', this.comment);
                    });
                }
            });
        },
        loadMore() {
            this.loadingMore = true;
            ajax.get('feedbackReplies', {
                parent: this.comment.id,
                'start-index': this.comment.replies.length,
                'max-results': this.limit
            }).then(({ items }) => {
                items.forEach((item) => { item.highlight = true; });
                this.comment.replies.unshift(...items);
                this.loadingMore = false;
            });
        },
        removeMedia(mediaItem) {
            const index = this.comment.media.indexOf(mediaItem);
            this.comment.media.splice(index, 1);
            ajax.update('feedback', { id: this.comment.id, media: this.comment.media.map(item => item.id) }).then((response) => {
                this.$store.dispatch('notify', { msg: response.message });
            });
        },
        removeReply({ id }) {
            this.comment.replies = this.comment.replies.filter(reply => reply.id !== id);
            this.comment.numReplies -= 1;
        },
        logClick(target) {
            if (this.metrics) {
                logActivityClick(
                    this.metrics.actor[0].id,
                    `comment-${target}`,
                    this.metrics.action
                );
            }
        }
    },
    mounted() {
        this.reportParams.title = this.gt.report_comment;
    },
    inject: {
        onMedia: {
            default: false
        }
    },
    props: {
        comment: {
            type: Object,
            required: true
        },
        foreign: {
            type: Number
        },
        metrics: Object,
        report: {
            type: Boolean,
            default: false
        },
        requireModeration: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            default() {
                return {};
            }
        },
        type: {
            type: String
        },
        upgradePrompt: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.comment {
    padding: 5px 0;
    .btn-group {
        vertical-align: inherit;
    }
    .comment-media {
        display: flex;
    }
    .media-item-card {
        margin-bottom: 0;
        margin-left: 0;
    }
    .profile-result-user-icons {
        padding-right: 3px;
    }
    .comment-form {
        margin-bottom: 45px;
    }
}

@keyframes comment_highlight {
    0% { background-color: transparent; }
    50% { background-color: $siteColorLight; color: $siteColorLightContrast; }
    100% { background-color: transparent; }
}

.comment-highlight .comment-content {
    animation-name: comment_highlight;
    animation-duration: 3s;
}

.comment-actions {
    margin-top: 3px;
    margin-left: 58px;
    margin-bottom: 5px;
    a, .comment-date {
        padding-right: 10px;
    }
    .dropdown-menu {
        min-width: 150px;
    }
}

.comment-content {
    margin-left: 48px;
    background-color: #f6f6f6;
    border-radius: 4px;
    padding: 8px 10px;
    .profile-result-user-icons {
        display: inline;
        float: none;
    }
    .comment-text img {
        max-width: 100%;
    }
}
.comment-date {
    color: #888;
}
.comment-user {
    width: 40px;
    height: 40px;
    float: left;
    .profile-result-avatar {
        height: 40px;
        width: 40px;
        float: left;
        border-radius: 4px;
        div {
            width: 40px;
            height: 40px;
            border-radius: 4px;
        }
    }
}
.comment-username {
    font-weight: 700;
}
.comment-replies {
    padding-left: 50px;
    .comment {
        margin-bottom: 0;
        padding-right: 0;
    }
    .comment-user {
        width: 36px;
        height: 36px;
        .profile-result-avatar {
            height: 36px;
            width: 36px;
            div {
                width: 36px;
                height: 36px;
            }
        }
    }
    .comment-content {
        margin-left: 44px;
    }
    .comment-actions {
        margin-left: 54px;
    }
}
</style>
