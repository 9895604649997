<template>
<form method="post" @submit.prevent="create"  @keyup="validate($event.target.name)"
    :class="['comment-form', {'comment-form-small': small, 'comment-form-like-button': showLikeButton, 'comment-form-expand': expand}]">
    <div :class="['comment-textarea-wrapper', 'form-group', {'has-error': form.errors.has('content')}]" @click="checkPermissions">
        <text-box @check-permissions="checkPermissions" name="content" ref="textarea" v-model="form.content"
            @blur="collapseCommentBox" @focus="expandCommentBox" @update-media="updateMedia" :media-picker="true" :max-media="2"
            :placeholder="gt.write_a_comment" :compact="small"></text-box>
        <span class="help-block" v-if="form.errors.has('content')" v-text="form.errors.get('content')"></span>
    </div>
    <div class="button-area">
        <button type="submit" class="btn comment-btn btn-primary pull-right" :disabled="form.errors.any() || !form.content">
            <template v-if="!loading">{{ gt.comment }}</template>
            <loading v-if="loading"></loading>
        </button>
        <like :class="['comment-btn', {'pull-right': small}]" v-if="showLikeButton && !useStars" :id="likeId" :type="this.type" :foreign="this.id"
            @liked="liked"></like>
        <star-rating v-if="useStars" v-model="newRating.rating" class="stars" :star-size="24" @rating-selected="starred"></star-rating>
        <share-button v-if="shareData" :id="shareData.id" :type="shareData.type" :heading="shareData.heading"
            :icon="shareData.icon" :text="shareData.text"></share-button>
    </div>
</form>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';
import Loading from 'Site/component/Loading.vue';
import ShareButton from 'Messaging/component/ShareButton.vue';
import StarRating from 'Site/component/StarRating.vue';
import TextBox from 'Site/component/TextBox.vue';

import Form from 'Site/js/util/form';

import Like from './Like.vue';

export default {
    name: 'CommentForm',
    components: {
        Like,
        Loading,
        ShareButton,
        StarRating,
        TextBox
    },
    computed: {
        gt() {
            if (this.t) {
                return Object.assign({}, this.$store.state.gt.feedback.comment_form, this.t);
            }
            return this.$store.state.gt.feedback.comment_form;
        },
        useStars() {
            return this.showLikeButton && this.type === 'story_comment' && this.$store.state.siteConfig.siteType === 'story';
        }
    },
    data() {
        return {
            form: new Form({
                content: '',
                media: [],
                type: this.type,
                foreign: this.id,
                parent: this.parent,
            }, {
                content: [
                    { type: 'required', message: this.$store.state.gt.feedback.comment_form.enter_a_comment }
                ]
            }),
            expand: false,
            newRating: this.rating,
            loading: false,
            upgradeModal: false,
            loggedOut: this.$store.state.config.loggedOut || false
        };
    },
    methods: {
        checkPermissions() {
            if (this.loggedOut) {
                this.$store.dispatch('showJoin', { reason: 'leave-comment', metrics: this.metrics });
                return false;
            }

            if (this.upgradePrompt) {
                if (!this.upgradeModal) {
                    this.$store.dispatch('showUpgrade', { reason: 'leave-comment', metrics: this.metrics });
                }
                this.upgradeModal = true;
                return false;
            }
            return true;
        },
        create() {
            if (!this.checkPermissions()) {
                return;
            }

            this.loading = true;
            this.form.foreign = this.id;
            const form = this.form.create('feedback');
            if (form) {
                form.then((response) => {
                    if (!this.requireModeration) {
                        this.$emit('add', response.item);
                    }
                    if (this.callback) {
                        this.callback(response.item);
                    }
                    this.loading = false;
                    this.expand = false;
                    this.$refs.textarea.setContent();
                    this.$refs.textarea.resetMedia();
                    this.$store.dispatch('notify', { msg: response.message });
                }).catch(({ response }) => {
                    response.then((error) => {
                        this.$store.dispatch('notify', { msg: error.message, type: 'error' });
                        this.loading = false;
                    });
                });
            } else {
                this.loading = false;
            }
        },
        collapseCommentBox() {
            this.upgradeModal = false;
            if (!this.form.content) {
                this.expand = false;
            }
        },
        liked(likeId) {
            this.$emit('liked', likeId);
        },
        starred() {
            const method = this.newRating.id ? 'update' : 'create';
            ajax[method]('feedback', {
                id: this.rating.id || null,
                type: this.type,
                foreign: this.id,
                rating: this.newRating.rating
            }).then((response) => {
                this.newRating.id = response.id;
                this.$store.dispatch('notify', { msg: response.message });
            });
        },
        expandCommentBox() {
            this.checkPermissions();
            this.expand = true;
        },
        updateMedia(medias) {
            this.form.media = medias;
        },
        validate(field) {
            this.form.errors.clear(field);
            this.form.validate(field);
        },
    },
    mounted() {
        if (this.parent) {
            this.$nextTick(() => {
                this.$el.querySelector('.message-textarea').focus();
            });
        }
    },
    watch: {
        rating(newValue) {
            this.newRating = newValue;
        }
    },
    props: {
        callback: Function,
        id: {
            type: Number,
            required: true
        },
        likeId: [Number, Boolean],
        metrics: Object,
        requireModeration: {
            type: Boolean,
            default: false
        },
        parent: {
            type: Number
        },
        rating: {
            type: Object,
            default() {
                return {};
            }
        },
        shareData: {
            type: Object
        },
        showLikeButton: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            default() {
                return {};
            }
        },
        type: {
            type: String,
            required: true
        },
        upgradePrompt: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.comment-form {
    margin-bottom: 40px;
    .comment-textarea-wrapper {
        margin-bottom: 0;
        margin-top: 5px;
        transition: margin 500ms;
        .textbox {
            margin-bottom: 10px;
        }
        .textbox .message-textarea {
            transition: min-height 500ms;
            line-height: 1.2;
            min-height: 70px;
            padding: 8px;
        }
        .textbox-buttons {
            &, button {
                height: 30px;
            }
        }
    }
    .stars {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
}
.comment-form-expand .textbox-compact .message-textarea {
    min-height: 70px !important;
}

.comment-form-small {
    margin-bottom: 0;
    .comment-textarea-wrapper .textbox {
        margin-bottom: 0;
    }
    .help-block {
        display: none;
    }
    .btn:disabled {
        display: none;
    }
    &.comment-form-expand {
        .btn:disabled {
            display: block;
        }
    }
    &.comment-form-like-button {
        .comment-textarea-wrapper {
            margin-right: 115px;
        }
        .like {
            width: 108px;
        }
        .btn-primary {
            margin-top: -72px;
            min-width: 108px;
        }
    }
    .comment-btn {
        margin-top: -32px;
    }
    .comment-textarea-wrapper {
        margin-right: 113px;
        .textbox .message-textarea {
            min-height: 30px;
        }
        .textbox-compact .message-textarea {
            padding: 6px 12px;
        }
    }
    .textbox {
        border: 1px solid #ccc;
        border-radius: 2px;
    }
}

@media (max-width: $screen-xs-max) {
    .comment-form-small {
        &.comment-form-like-button {
            &.comment-form-expand {
                margin-bottom: 40px;
                .comment-textarea-wrapper {
                    margin-right: 0;
                }
                .btn {
                    margin-top: 0;
                }
            }
            .comment-textarea-wrapper {
                margin-right: 27%;
                .textbox-compact .message-textarea {
                    padding: 6px 9px;
                }
            }
            .btn-primary {
                margin-left: 2%;
                margin-right: 0;
            }
            .comment-btn {
                width: 26%;
            }
        }
        .comment-textarea-wrapper {
            margin-right: 0;
        }
        textarea {
            padding-right: 0;
        }
    }
}
</style>
