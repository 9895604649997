<template>
<div class="mediaviewer-body" v-if="current.user">
    <div class="mediaviewer-user">
        <avatar :user="current.user" size="medium"></avatar>
        <div class="profile-result-details">
            <div class="username"><a :href="current.user.links.profile" @click="logClick('profile')"
                v-miniprofile="{ user: current.user.id, metrics }">{{ current.user.userName }}</a></div>
            <div class="profile-result-user-icons">
                <i class="fa fa-check-square is-verified" v-if="current.user.isVerified"
                :title="gt.common.verified.replace('%username', current.user.userName)"></i>
                <i v-for="(person, index) in current.user.persons" :key="index" :class="['fa', 'fa-user', person.genderClass]"></i>
            </div>
            <div v-if="current.user.distance" class="mediaviewer-user-distance"><i class="fa fa-map-marker"></i> {{ current.user.distance }}</div>
        </div>
        <div class="mediaviewer-stats">
            <i class="fa fa-eye"></i> {{ current.views }}
            <div class="like-count">
                <i class="sh-icon"></i> {{ current.likes }}
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="mediaviewer-media-meta">
        <a href="#" @click.prevent="sendReport(reportData)" @click="pauseVideo">{{ gt.mediaviewer.report }}</a>
        <span class="pull-right">{{ current.created }}</span>
    </div>
    <div class="mediaviewer-content">
        <div v-if="current.description" class="mediaviewer-description">
            {{ current.description }}
        </div>
        <comments ref="mediaviewerComments" :metrics="metrics" :show-form="false" type="collection_comment"
            :show-like-button="true" :id="current.id" @upgradeprompt="setFeedbackUpgradePrompt"></comments>
    </div>
    <comment-form class="mediaviewer-comment-form" :show-like-button="true" type="collection_comment"
        :like-id="current.liked" :id="current.id" @add="addComment" :upgrade-prompt="feedbackUpgradePrompt" :metrics="metrics"
        @liked="liked"></comment-form>
</div>
</template>

<script>
import Avatar from 'Profile/component/Avatar.vue';
import CommentForm from 'Feedback/component/CommentForm.vue';
import Comments from 'Feedback/component/Comments.vue';
import miniprofileDirective from 'Profile/directive/miniprofile';
import { logActivityClick, logActivityMessage } from 'Site/js/util/metrics';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'MediaViewerSide',
    components: {
        Avatar,
        CommentForm,
        Comments
    },
    provide: {
        onMedia: true
    },
    directives: {
        miniprofile: miniprofileDirective
    },
    computed: {
        ...mapState(['gt']),
        reportData() {
            let url = '';
            if (!Array.isArray(this.current.media)) {
                url = this.current.media['video-thumbnail'] ?
                    this.current.media['video-thumbnail'].url : this.current.media['large-thumbnail'].url;
            }
            return {
                component: 'image',
                componentData: {
                    url
                },
                id: this.current.id,
                title: this.$store.state.gt.report.report_media,
                type: 2,
                user: this.current.user.id
            };
        },
    },
    data() {
        return {
            feedbackUpgradePrompt: false
        };
    },
    methods: {
        ...mapActions(['sendReport']),
        addComment(comment) {
            this.$refs.mediaviewerComments.$emit('add', comment);

            if (this.metrics) {
                logActivityMessage(
                    this.metrics.actor[0].id,
                    this.metrics.action
                );
            }
        },
        changeMedia() {
            this.$nextTick(() => {
                this.$refs.mediaviewerComments.$emit('load');
            });
        },
        liked(likeId) {
            this.current.liked = likeId;

            if (this.metrics) {
                logActivityClick(
                    this.metrics.actor[0].id,
                    'media-viewer-like',
                    this.metrics.action
                );
            }
        },
        logClick(target) {
            if (this.metrics) {
                logActivityClick(
                    `media-viewer-${target}`,
                    this.metrics.action
                );
            }
        },
        pauseVideo() {
            const $videoPlayer = document.querySelector('.slick-current video');
            if ($videoPlayer) {
                $videoPlayer.pause();
            }
        },
        setFeedbackUpgradePrompt(prompt) {
            this.feedbackUpgradePrompt = prompt;
        }
    },
    mounted() {
        this.$on('changemedia', this.changeMedia);
    },
    props: {
        current: {
            type: Object,
            required: true
        },
        metrics: Object
    }
};

</script>

<style lang="scss">
@import "../../../../../../module/application/frontend-src/scss/variables";

.mediaviewer-body {
    position: relative;
    .comment {
        padding: 0;
    }
    .comment-container, .comment-content {
        padding: 5px;
    }
    .comment-replies {
        padding-left: 15px;
    }
    .comment-actions {
        font-size: 0.9em;
    }
    .mediaviewer-comment-form {
        margin: 0;
        padding: 5px;
        padding-top: 0;
        width: 100%;
        .comment-btn {
            margin-top: -5px;
        }
    }
    .message-textarea {
        min-height: 50px !important;
    }
}
.mediaviewer-content {
    padding-bottom: 5px;
    .comment-media .media-item {
        width: 100px;
        height: 100px;
    }
}
.mediaviewer-description {
    padding: 5px 5px 10px 5px;
    border-bottom: 1px solid #eee;
}
.mediaviewer-media-meta {
    border-bottom: 1px solid #eee;
    padding: 2px 10px;
}
.mediaviewer-user-distance {
    max-width: 120px;
}

@media (min-width: $screen-sm-min) {
    .mediaviewer-body {
        padding-bottom: 100px;
        .mediaviewer-comment-form {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}
</style>
