<template>
<div class="forum-post">
    <header>
        <div v-if="showUser" class="forum-post-user">
            <avatar size="medium" :user="post.user" v-miniprofile="post.user.id"  v-if="post.user" class="avatar-circle"></avatar>
        </div>
        <div>
            <div class="forum-post-user-name" v-if="post.user && showUser">
                <a :href="post.user.links.profile" v-miniprofile="post.user.id">
                    {{ post.user.userName }}
                </a>
                <i class="fa fa-check-square is-verified" v-if="post.user.isVerified"
                :title="gt.verified.replace('%username', post.user.userName)"></i>
                <span class="person-icons">
                    <i v-for="(person, index) in post.user.persons" :key="index" :class="['fa', 'fa-user', person.genderClass]"></i>
                </span>
                <span v-if="post.rank" class="forum-post-rank" :title="t.num_posts.replace('%number', post.posts)">{{ post.rank }}</span>
            </div>
            <div class="forum-post-unknown-user" v-if="!post.user && showUser">
                {{ gt.unknown_user }}
            </div>
            <div class="forum-post-user-date pull-right">
                {{ post.created }}
                <span class="forum-post-like-count">
                    <i class="sh sh-icon"></i> {{ post.likes }} {{ post.likes === 1 ? gt.like : t.likes }}
                </span>
            </div>
        </div>
    </header>
    <div class="forum-post-content">
        <p v-if="!enableEdit || readonly" v-html="post.text"></p>
        <editable v-if="enableEdit && !readonly" v-model="post.text" property="message" type="wysiwyg" :inline="false"
            :params="ajaxParams"></editable>
        <div class="forum-post-media" v-if="!readonly">
            <media v-for="(media, index) in previewMedia" :key="index" :media="media" @click.native="mediaViewer(index)"
                :card="false" :stats="false">
                <span v-if="enableEdit" class="fa-stack fa-stack-remove fa-lg" @click.stop.prevent="removeMedia(media)">
                    <i class="fa fa-circle fa-stack-2x"></i>
                    <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
                </span>
            </media>
            <div class="forum-post-media-more" v-if="post.medias.length > splitMediaIndex" @click="mediaViewer(splitMediaIndex)">
                <span>+ {{ post.medias.length - splitMediaIndex }} {{ t.more }}</span>
            </div>
        </div>
        <slot></slot>
    </div>
    <footer v-if="siteConfig.user.id">
        <like v-if="post.user && post.user.id !== parseInt(siteConfig.user.id, 10) && !locked && !readonly" class="forum-post-btn"
            :foreign="post.id" :id="post.isLiked" type="forum_post_comment" @liked="liked"></like>
        <button v-if="!readonly && post.showQuote && !locked" class="btn forum-post-btn" @click.prevent="quote">
            <i class="fa fa-quote-right"></i>{{ t.quote }}
        </button>
        <button v-if="!readonly && !post.showDelete && post.showReport" class="btn forum-post-btn" v-report="reportData">
            <i class="fa fa-exclamation-triangle"></i>{{ t.report }}
        </button>
        <a v-if="showThreadLink" :href="post.url" class="btn forum-post-btn">{{ t.view_thread }}</a>
        <a v-if="!readonly" class="btn forum-post-btn" :href="`${currentPage}#post${post.id}`" @click="copyLink" :title="t.post_link" target="_new">
            <i class="fa fa-link"></i>
        </a>
        <div class="dropdown pull-left" v-if="!readonly && (post.showEdit || post.showDelete)">
            <button type="button" class="forum-post-btn btn" v-dropdown>
                <i class="fa fa-chevron-down"></i>
            </button>
            <ul class="dropdown-menu">
                <li v-if="post.showEdit && !readonly"><a href="#" @click.prevent="enableEdit = !enableEdit">{{ gt.toggle_edit }}</a></li>
                <li v-if="post.showDelete"><a @click.prevent="deletePost" href="#">{{ t.delete_post }}</a></li>
            </ul>
        </div>
    </footer>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Avatar from 'Profile/component/Avatar.vue';
import Editable from 'Site/component/Editable.vue';
import Like from 'Feedback/component/Like.vue';
import Media from 'Media/component/Media.vue';

import dropdownDirective from 'Site/directive/dropdown';
import miniprofileDirective from 'Profile/directive/miniprofile';
import reportDirective from 'Abuse/directive/report';

import ajax from 'Site/js/util/ajax.fetch';

export default {
    name: 'ForumPost',
    components: {
        Avatar,
        Editable,
        Like,
        Media
    },
    computed: {
        previewMedia() {
            if (!this.post.medias) {
                return [];
            }
            return this.post.medias.slice(0, this.splitMediaIndex);
        },
        splitMediaIndex() {
            if (!this.post.medias) {
                return 1;
            }
            if (window.innerWidth >= 600) {
                if (this.post.medias.length > 4) {
                    return 3;
                }
                return 4;
            } else if (window.innerWidth >= 470) {
                if (this.post.medias.length > 3) {
                    return 2;
                }
                return 3;
            }
            if (this.post.medias.length > 2) {
                return 1;
            }
            return 2;
        },
        ...mapState({
            ajaxParams(state) {
                return {
                    url: 'post',
                    id: this.post.id,
                    foreign: this.id,
                    type: this.post.postType
                };
            },
            gt: state => state.gt.common,
            id: state => state.config.data.id,
            reportData(state) {
                return {
                    component: 'forumPost',
                    componentData: this.post,
                    id: this.post.id,
                    title: state.t.report_post,
                    type: this.post.reportType,
                    user: this.post.user ? this.post.user.id : null
                };
            },
            siteConfig: state => state.siteConfig,
            t: state => state.t
        })
    },
    data() {
        return {
            enableEdit: false,
            currentPage: window.location.origin + window.location.pathname
        };
    },
    directives: {
        dropdown: dropdownDirective,
        miniprofile: miniprofileDirective,
        report: reportDirective
    },
    methods: {
        ...mapActions(['notify', 'showConfirm', 'showJoin', 'showMediaViewer']),
        copyLink(e) {
            if (navigator.clipboard) {
                e.preventDefault();
                navigator.clipboard.writeText(`${this.currentPage}#post${this.post.id}`).then(() => {
                    this.notify({ msg: this.t.link_copied });
                });
            }
        },
        deletePost() {
            this.showConfirm({
                text: this.t.delete_post_confirm,
                callback: () => {
                    ajax.delete('post', {
                        id: this.post.id
                    }).then((response) => {
                        this.notify({ msg: response.message });
                        this.$emit('removepost', this.post);
                    });
                }
            });
        },
        liked(likeId) {
            this.post.isLiked = likeId;
            if (likeId) {
                this.post.likes++;
            } else {
                this.post.likes--;
            }
        },
        mediaViewer(start) {
            if (this.siteConfig.user.id) {
                this.showMediaViewer({ media: this.post.medias, start });
            } else {
                this.$store.dispatch('showJoin');
            }
        },
        quote() {
            this.$emit('quote', this.post);
        },
        removeMedia(mediaItem) {
            this.showConfirm({
                text: this.t.delete_media_confirm,
                callback: () => {
                    const index = this.post.medias.indexOf(mediaItem);
                    this.post.medias.splice(index, 1);

                    ajax.update('post', {
                        id: this.post.id,
                        media: this.post.medias.map(checkMedia => checkMedia.id),
                        message: this.post.text
                    }).then((response) => {
                        this.notify({ msg: response.message });
                    });
                }
            });
        }
    },
    props: {
        locked: {
            type: Boolean,
            default: false
        },
        post: Object,
        readonly: {
            type: Boolean,
            default: false
        },
        showThreadLink: {
            type: Boolean,
            default: false
        },
        showUser: {
            type: Boolean,
            default: true
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.forum-post {
    padding: 10px;
    border-bottom: 1px solid #eee;
    transition: background-color 500ms, color 500ms;
    a {
        transition: color 500ms;
    }
    .forum-post-actions button i, .wall-create-form button i {
        margin-right: 5px;
    }
    .forum-post-btn {
        @include button-variant(#999, white, white);
        &.liked {
            color: #fff;
        }
        float: right;
    }
    .media-item-large-thumbnail {
        margin: 5px 0;
    }
    &.site-bg, &.site-bg a {
        color: #fff;
    }
}

.forum-post-content {
    clear: right;
    padding: 15px 0;
    margin-left: 65px;
    line-height: 1.5em;
    font-size: 14px;
    overflow-wrap: break-word;
}

.forum-post footer {
    height: 30px;
    color: #ddd;
    button {
        float: right;
        margin-left: 5px;
    }
    .fa-quote-right, .fa-exclamation-triangle {
        margin-right: 5px;
    }
    .forum-post-like-count {
        color: #999;
        padding: 6px 12px;
        float: right;
    }
}

.forum-post-media {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 0 -10px;
}

.forum-post-media-more {
    width: 150px;
    height: 150px;
    background-color: #eee;
    text-align: center;
    margin: 5px 0;
    cursor: pointer;
    @include card;
    span {
        line-height: 150px;
        font-size: 1.5em;
    }
}

.forum-post-counts {
    &, a {
        color: #999;
    }
    i {
        vertical-align: text-bottom;
        margin-left: 5px;
    }
}

.forum-post-like-count {
    padding-right: 10px;
    float: left;
    i {
        vertical-align: text-bottom;
    }
}

.forum-post-user {
    .profile-result-avatar {
        margin-right:15px;
        float: left;
    }
}
.forum-post-user-name {
    float: left;
    font-size: 1.4em;
    i {
        font-size: 0.8em;
    }
}
.forum-post-unknown-user {
    float: left;
    color: #999;
    padding-left: 60px;
    font-size: 1.4em;
    font-weight: 300;
}
.forum-post-user-date, .forum-post-rank {
    color: #999;
    line-height: 26px;
    font-size: 13px;
    margin-left: 5px;
}

@media (max-width: $screen-xs-max) {
    .forum-post-content {
        clear: both;
        margin: 0;
        padding: 10px 0;
    }
    .forum-post-counts {
        font-size: 0.9em;
        i {
            width: 13px;
            height: 13px;
        }
    }
    .forum-post-user .profile-result-avatar {
        margin-right:10px;
    }
    .forum-post-user-name {
        margin-top: 3px;
        font-size: 1.2em;
        max-width: 55%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .forum-post-unknown-user {
        padding-left: 0px;
        padding-top: 10px;
    }
    .forum-post-like-count {
        display: block;
        float: none;
        margin-top: -3px;
    }
    .forum-post-user-date {
        margin-top: 3px;
        margin-bottom: -3px;
    }
    .forum-post-rank {
        display: block;
        margin: 0;
    }
    .btn.forum-post-btn {
        padding: 6px 10px;
    }
}
</style>

