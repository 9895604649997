import { render, staticRenderFns } from "./EmailVerify.vue?vue&type=template&id=2cac5440&scoped=true"
import script from "./EmailVerify.vue?vue&type=script&lang=js"
export * from "./EmailVerify.vue?vue&type=script&lang=js"
import style0 from "./EmailVerify.vue?vue&type=style&index=0&id=2cac5440&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cac5440",
  null
  
)

export default component.exports