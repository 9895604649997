<template>
<form method="post" @submit.prevent="submit" @keyup="validate($event.target.name)" class="report-form">
    <comment v-if="this.component === 'comment'" :report="true" :comment="this.componentData"></comment>
    <img v-if="this.component === 'image'" :src="this.componentData.url">
    <wall-post v-if="this.component === 'wallPost'" :readonly="true" :post="this.componentData"></wall-post>
    <forum-post v-if="this.component === 'forumPost'" :readonly="true" :post="this.componentData"></forum-post>
    <div v-if="!loading" :class="['form-group', { 'has-error': form.errors.has('description') }]">
        <textarea v-model="form.description" class="form-control" :placeholder="gt.describe_the_issue" rows="8" ref="reportTextArea"></textarea>
        <span class="help-block" v-if="form.errors.has('description')" v-text="form.errors.get('description')"></span>
    </div>
    <loading v-if="loading"></loading>
</form>
</template>

<script>
import { mapState } from 'vuex';
import Comment from 'Feedback/component/Comment.vue';
import ForumPost from 'Forum/component/ForumPost.vue';
import Loading from 'Site/component/Loading.vue';
import WallPost from 'Wall/component/Post.vue';

import ajax from 'Site/js/util/ajax.fetch';
import Form from 'Site/js/util/form';

export default {
    name: 'Report',
    components: {
        Comment,
        ForumPost,
        Loading,
        WallPost
    },
    computed: mapState({
        gt: state => state.gt.report
    }),
    data() {
        return {
            form: new Form({
                description: ''
            }, {
                description: ['required']
            }),
            loading: false
        };
    },
    methods: {
        submit() {
            if (!this.form.validate()) {
                return false;
            }
            this.loading = true;
            return ajax.create('abuse', {
                description: this.form.description,
                typeId: this.id,
                type: this.type,
                user: this.user
            }).then((response) => {
                this.loading = false;
                this.form.reset();
                this.$parent.hideModal();
                this.$store.dispatch('notify', { msg: response.message });
            })
                .catch((error) => {
                    this.form.errors.record(error.response.data);
                    this.loading = false;
                });
        },
        validate(field) {
            this.form.errors.clear(field);
            this.form.validate(field);
        }
    },
    mounted() {
        this.$on('submit', this.submit);
        this.$on('show', () => {
            this.$nextTick(() => {
                this.$refs.reportTextArea.focus();
            });
        });
    },
    props: {
        component: String,
        componentData: Object,
        id: Number,
        type: Number,
        user: Number
    }
};
</script>

<style lang="scss">
.report-form {
    .comment, img {
        margin-bottom: 10px;
    }
}
</style>
