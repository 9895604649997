function getValue(bind) {
    const { value } = bind;
    return value.user ? value.user : value;
}

function getMetrics(bind) {
    const { value } = bind;
    return value.metrics ? value.metrics : null;
}

function showHandler(e, binding) {
    /* eslint no-underscore-dangle: 0 */
    // @todo find a btter way to do this
    document.querySelector('#vue-wrapper').__vue__.$refs.miniprofile.show(e, getValue(binding), getMetrics(binding));
}

function hideHandler(e) {
    document.querySelector('#vue-wrapper').__vue__.$refs.miniprofile.hide(e);
}

export default {
    bind(el, binding) {
        if (window.innerWidth < 768 || !binding.value) {
            return;
        }
        el.addEventListener('mouseover', e => showHandler(e, binding));
        el.addEventListener('mouseleave', hideHandler);
    },
    update(el, binding) {
        if (!binding.value) {
            return;
        }
        el.removeEventListener('mouseover', showHandler);
        el.addEventListener('mouseover', e => showHandler(e, binding));
    },
    unbind(el) {
        el.removeEventListener('mouseover', showHandler);
        el.removeEventListener('mouseleave', hideHandler);
    }
};
