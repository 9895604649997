<template>
<div class="event-card">
    <a :href="event.links.view" class="coverimage-header-top site-bg coverimage-header-listing" :style="coverStyle">
        <div class="event-time">
            <div class="month site-bg-light">{{ event.date.month }}</div>
            <div class="date site-bg">{{ event.date.day }}</div>
        </div>
        <div class="event-icons">
            <span v-if="event.hosting" class="event-attending">
                <i class="fa fa-check"></i> {{ t.hosting }}
            </span>
            <span v-if="event.attending && !event.hosting" class="event-attending">
                <i class="fa fa-check"></i> {{ t.attending }}
            </span>
            <span v-if="event.pending" class="event-attending">
                <i class="fa fa-check"></i> {{ t.asked_to_attend }}
            </span>
            <span v-if="event.commercial" class="event-commercial">
                <i class="fa fa-dollar"></i>
            </span>
        </div>
        <h1 class="coverimage-text-wrapper">
            <span class="coverimage-text">
                {{ event.title }}
            </span>
        </h1>
        <div class="coverimage-icon">
            <i class="fa fa-calendar"></i>
        </div>
        <slot></slot>
    </a>
    <div class="event-description" v-html="event.description"></div>
    <div class="event-stats">
        <div class="pull-right">
            <span data-container="body" data-toggle="tooltip" data-placement="top" :data-original-title="event.type">
                <i :class="['fa', event.typeIcon]"></i>
            </span>
            <a v-if="event.links.attendees" :href="event.links.attendees">
                <span data-container="body" data-toggle="tooltip" data-placement="top" :data-original-title="event.attendees">
                    <i class="fa fa-users"></i> {{ event.attendeeCount }}
                </span>
            </a>
            <span v-else data-container="body" data-toggle="tooltip" data-placement="top" :data-original-title="event.attendees">
                <i class="fa fa-users"></i> {{ event.attendeeCount }}
            </span>
        </div>
        <div v-if="event.venue">
            <p :title="event.venue"><i class="fa fa-map-marker"></i>
                {{ event.venue }}
            </p>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'EventCard',
    computed: {
        ...mapState({
            t: state => state.t.event_card
        }),
        coverStyle() {
            return this.event.cover ? `background-image: url(${this.event.cover})` : '';
        }
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";
.event-time {
    text-align: center;
    color: white;
    font-weight: 300;
    .month {
        border-radius: 2px 2px 0 0;
    }
    .date {
        border-radius: 0 0 2px 2px;
        font-size: 18px;
    }
}
.event-card {
    @include card;
    margin-bottom: 25px;
    border-radius: 0;
    .coverimage-header-listing {
        .event-time {
            position: absolute;
            left: 10px;
            top: 10px;
            width: 40px;
        }
    }
    .event-icons {
        right: 10px;
        top: 10px;
        position: absolute;
    }
    .event-attending, .event-commercial {
        color: #fff;
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        background-color: rgba(0,0,0,.3);
        margin-left: 5px;
    }
    .event-attending i {
        color: #35af49;
        background-color: #fff;
        border-radius: 3px;
    }
    .event-description {
        padding: 0 10px;
        height: 95px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .event-stats {
        padding: 9px 5px 9px !important;
        border-top: .0625rem solid #e5e5e5;
        background-color: #f5f5f5;
        height: 31px;
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 0;
            margin-bottom: 0;
            i {
                margin-right: 3px;
            }
        }
        a {
            color: $text-color;
            &:hover {
                text-decoration: none;
                color: $siteColor;
            }
        }
        span {
            i {
                margin-left: 10px;
                vertical-align: text-top;
            }
        }
    }
}
@media (max-width: $screen-xs-max) {
    .event-card .event-description {
        height: 65px;
    }
}
</style>
