<template>
<div class="profile-result" :class="{'profile-result-card': card, 'profile-result-100': small}">
    <div v-if="owner && !restrictAccess" class="profile-result-dropdown btn-group pull-right">
        <button type="button" class="btn btn-xs dropdown-toggle" v-dropdown>
            <span class="caret"></span>
        </button>
        <slot name="menu"></slot>
    </div>
    <avatar :user="user" :linkTarget="linkTarget" @click.native="showUpgradePrompt" :showRequestPhoto="showRequestPhoto" :size="size"></avatar>
    <slot name="body"></slot>
    <div class="profile-result-details">
        <span class="username">
            <template v-if="!(user.links && user.links.profile) || user.upgradeRequired">
                {{ user.userName || user.identity }}
            </template>
            <a v-else-if="user.links && user.links.profile && !user.upgradeRequired" :href="user.links.profile" @click="showUpgradePrompt" :target="linkTarget" v-miniprofile="user.id">
                {{ user.userName || user.identity }}
            </a>
        </span>
        <span class="profile-result-user-icons">
            <i class="fa fa-check-square is-verified" v-if="user.isVerified" :title="gt.common.verified.replace('%username', user.userName)"></i>
            <i v-for="(person, index) in user.persons" :key="index" :class="['fa', 'fa-user', person.genderClass]"></i>
        </span>
        <template v-if="!small && !profileViews">
            <div v-if="!user.extra && user.distance && !showLocation" class="profile-result-extra">
                {{ user.distance }}{{ user.distanceUnitShort }}
            </div>
            <div v-if="user.extra" class="profile-result-extra">{{ user.extra }}</div>
            <div v-if="showLocation" class="profile-result-location">
                <div v-if="user.location" class="location" :title="user.location">
                    <i class="fa fa-map-marker"></i> {{ user.location }}
                </div>
                <span class="pull-right">{{ user.distance }}{{ user.distanceUnitShort }}</span>
            </div>
        </template>
    </div>
    <slot name="buttons"></slot>
</div>
</template>
<script>
import { mapState } from 'vuex';
import dropdownDirective from 'Site/directive/dropdown';
import Avatar from './Avatar.vue';
import MiniProfile from './MiniProfile.vue';
import miniprofileDirective from '../directive/miniprofile';

export default {
    name: 'User',
    components: {
        Avatar,
        MiniProfile
    },
    computed: {
        ...mapState({
            gt: state => state.gt
        }),
        restrictAccess() {
            return this.profileViews && !this.showUsers;
        },
        t() {
            return this.$store.state.t.profile && this.$store.state.t.profile.component ? this.$store.state.t.profile.component.user : {};
        }
    },
    directives: {
        dropdown: dropdownDirective,
        miniprofile: miniprofileDirective
    },
    methods: {
        showUpgradePrompt(e) {
            if (this.user.upgradeRequired || this.restrictAccess) {
                e.preventDefault();
                this.$store.dispatch('showUpgrade', { reason: this.user.upgradePrompt });
            }
        }
    },
    props: {
        card: {
            type: Boolean,
            default: true
        },
        linkTarget: {
            type: String,
            default: '_self'
        },
        profileViews: {
            type: Boolean,
            default: false
        },
        showLocation: {
            type: Boolean,
            default: false
        },
        showRequestPhoto: {
            type: Boolean,
            default: true
        },
        showUsers: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'large-thumbnail'
        },
        owner: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.profile-result {
    vertical-align: top;
    width: 150px;
    height:195px;
    float: left;
    margin-right: 3px;
    margin-left: 3px;
    position: relative;
    transition: width 500ms, height 500ms, padding 500ms;
    & * {
        transition: width 500ms, height 500ms, padding 500ms;
    }
    .profile-result-extra{
        clear: both;
    }
    .dropdown-menu {
        min-width: 150px;
    }
    &.profile-result-100 {
        .profile-result-dropdown {
            left: 75px;
        }
    }
}
.profile-result-card {
    @include card;
}
.profile-result-100 {
    width: 100px;
    height: 150px;
    .profile-result-avatar {
        height: 100px;
        div {
            height: 100px;
            width: 100px;
        }
    }
    .profile-result-details {
        text-align: center;
        .profile-result-user-icons {
            float: none;
        }
        .username {
            display: block;
            max-width: 100%;
            float: none;
        }
    }
}
.profile-result-body {
    overflow: hidden;
    height: 0;
}
.profile-result-avatar {
    height: 150px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    div {
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        height: 150px;
        width: 150px;
        z-index: 1;
        position: relative;
    }
    .profile-image-photos {
        top: 125px;
        left: 5px;
    }
    .profile-image-verified {
        top: 125px;
        left: 125px;
    }
}
.profile-result .profile-result-dropdown {
    position: absolute;
    z-index: 2;
    left: 125px;
    top: 5px;
    .dropdown-toggle {
        background-color: rgba(0,0,0,0.3);
        color: #fff;
    }
}
.profile-result-location {
    clear: both;
    font-weight: 300;
    .location {
        @include text-overflow();
        max-width: 80px;
        display: inline-block;
        float: left;
        padding-right: 5px;
    }
    span {
        margin-right: 5px;
        max-width: 60px;
        @include text-overflow();
    }
}
.profile-result {
    .buttons, .friend-buttons {
        clear: both;
        @supports(display: flex) {
            display: flex;
            .btn {
                width: 100%;
                border-radius: 0;
            }
            &.two-buttons {
                .btn {
                    border-bottom-left-radius: 2px;
                }
                .btn + .btn {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 2px;
                }
            }
            &.one-button .btn {
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }
    }
}
.profile-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}
@media (max-width: $screen-xs-max) {
    .profile-list {
        .profile-result {
            width: 139px;
            height: 185px;
            .location {
                max-width: 79px;
            }
            .username {
                max-width: 90px;
            }
        }
        .profile-result-avatar {
            width: 139px;
            height: 139px;
            div {
                width: 139px;
                height: 139px;
            }
            .profile-image-photos {
                top: 115px;
            }
            .profile-image-verified {
                left: 114px;
                top: 115px;
            }
        }
        .profile-result .profile-result-dropdown {
            left: 114px;
        }
    }
}
</style>
