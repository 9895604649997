<template>
    <textarea class="form-control" v-model="value"></textarea>
</template>

<script>
import $ from 'jquery';
import redactorConfig from '../js/lib/redactor/redactor.config';

export default {
    name: 'Wysiwyg',
    data() {
        return {
            initialised: false,
            change: false,
            config: redactorConfig
        };
    },
    beforeCreate() {
        import(/* webpackChunkName: "redactor" */'../scss/lib/redactor.css');
        import(/* webpackChunkName: "redactor" */'../js/lib/redactor/redactor').then(() => {
            $.Redactor.opts.langs.en = this.$store.state.gt.wysiwyg;

            if (typeof MutationObserver !== 'undefined' && !this.initialised) {
                this.$nextTick(() => {
                    if (this.buttons && this.buttons.length) {
                        this.config.buttons = this.buttons;
                    }
                    if (this.plugins && this.plugins.length) {
                        this.config.plugins = this.plugins;
                    }
                    $(this.$el).redactor(this.config).on('change.callback.redactor', (content) => {
                        this.change = true;
                        this.$emit('input', content);
                    });
                    this.initialised = true;
                });
            }
        });
    },
    beforeDestroy() {
        if (this.initialised) {
            $(this.$el).redactor('core.destroy');
        }
    },
    methods: {
        focus() {
            $(this.$el).redactor('focus.end');
        }
    },
    watch: {
        value(val, oldVal) {
            if (this.initialised && val !== oldVal && !this.change) {
                $(this.$el).redactor('insert.set', val);
            }
            this.change = false;
        }
    },
    props: {
        buttons: Array,
        plugins: Array,
        value: String
    }
};
</script>

<style lang="scss">
    @import "../scss/variables";
    .has-error .redactor-editor {
        background-color: #ffe7d2;
        border: 1px solid $brand-danger;
    }
</style>
