import io from 'socket.io-client';
import store from '../store';

function noConnection() {
    /* eslint no-console: 0 */
    console.error('Socket connection not established');
}

export default {
    connection: null,
    init() {
        this.connection = io(store.state.siteConfig.endpoints.napi, {
            path: '/napi/notification',
            'force new connection': true
        });
    },
    emit(name, data) {
        if (!this.connection) {
            noConnection();
            return;
        }
        this.connection.emit(name, data);
    },
    on(event, callback) {
        if (!this.connection) {
            noConnection();
            return;
        }
        this.connection.on(event, callback);
    },
    subscribe(name) {
        if (!this.connection) {
            noConnection();
            return;
        }
        this.connection.emit('subscribe', name);
    },
    unsubscribe(name) {
        if (!this.connection) {
            noConnection();
            return;
        }
        this.connection.emit('unsubscribe', name);
    }
};
