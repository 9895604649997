import Vue from 'vue';
import store from 'Site/js/store';
import Upgrade from 'Site/component/SiteUpgrade.vue';

export default function () {
    new Vue({ /* eslint no-new: 0 */
        el: '#upgrade-prompt',
        name: 'Upgrades',
        store,
        components: {
            Upgrade
        }
    });
}
