import store from './store';

export default function () {
    if ('serviceWorker' in navigator) {
        if (window.location.hostname.indexOf('dev.') === -1 && (navigator.userAgent.match(/Chrome/) && !navigator.userAgent.match(/Edge/))) {
            navigator.serviceWorker.register(`/serviceWorker${store.state.siteConfig.siteShortCode}.js`).then(() => {
            }).catch(() => {
            });
        } else {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                /* eslint no-restricted-syntax: 0 */
                for (const registration of registrations) {
                    registration.unregister();
                }
            });
        }
    }
}
