<template>
<div class="infinite-scroll"><loading v-if="loading"></loading></div>
</template>

<script>
import { throttle } from 'underscore';
import Loading from './Loading.vue';

export default {
    name: 'Infinite',
    components: {
        Loading
    },
    props: {
        handler: {
            type: Function,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        threshold: {
            type: Number,
            default: 200
        },
        handleOnMount: {
            type: Boolean,
            default: true
        },
        scrollContainer: {
            type: String
        }
    },
    created() {
        this.triggers = ['scroll', 'resize'];
    },
    mounted() {
        this.checkInView();
    },
    methods: {
        checkInView() {
            // checkInView right after this component is mounted
            if (this.handleOnMount) {
                this.execute();
            }

            this.container = this.scrollContainer ? this.$parent.$refs[this.scrollContainer] : window;

            // add event listeners
            this.check = throttle(this.execute, 200);
            this.triggers.forEach(event => this.container.addEventListener(event, this.check));
        },
        execute() {
            if (this.scrollPosCheck() && !this.loading) {
                this.handler();
            }
        },
        scrollPosCheck() {
            const pageHeight = document.querySelector('.page-wrapper').offsetHeight;
            return typeof window.scrollY === 'undefined' ? window.pageYOffset : window.scrollY >= (pageHeight - window.innerHeight - this.threshold);
        }
    },
    beforeDestroy() {
        this.triggers.forEach(event => this.container.removeEventListener(event, this.check));
    }
};
</script>
