<template>
    <div id="upgrade-form">
        <div class="upgrade-plans">
            <div class="upgrade-headers">
                <div class="flex-row">
                    <div v-if="!isMobile || showToggleSwitch" class="info">
                        <div v-if="showToggleSwitch" class="payment-plan">
                            <h2>{{ t.payment_plan }}</h2>
                            <div class="btn-group">
                                <!-- Package Lengths -->
                                <template v-for="packageLength in packageLengths">
                                    <button :key="packageLength" class="btn btn-default"
                                        :class="{'active': selectedServiceLength === packageLength}"
                                        @click.prevent="setSelectedServiceLength(packageLength)"
                                    >
                                        {{ choosePackageText(packageLength) }}
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- Package Header Costs -->
                    <div v-for="(packageCost, index) in packageCosts" :key="index" class="package cell"
                        :class="packageCost.selectable ? packageCost.activeKey : ''"
                    >
                        <div class="package-title">
                            <span class="title">{{ packageCost.title }}</span>
                            <div class="cost">
                                <s v-if="packageCost.parent" class="full-price">{{ packageCost.parent.cost }}</s>
                                <span v-if="packageCost.selectable" class="current-price" v-html="shrinkDecimal(packageCost.cost)"></span>
                                <span v-else-if="packageCost.current">{{ t.current }}</span>
                                <span v-else>-</span>
                            </div>
                            <span class="cost-info">{{ packageCost.costInfo }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="upgrade-options">
                <!-- Cost breakdown -->
                <div class="flex-row breakdown" v-if="showCostBreakdown">
                    <div class="info"></div>
                    <div  v-for="(packageCost, index) in packageCosts" :key="index" class="package cell"
                        :class="packageCost.selectable ? packageCost.activeKey : ''"
                    >
                        <span v-if="packageCost.costBreakdown">
                            {{ costBreakdownText(packageCost.costBreakdown) }}
                        </span>
                        <span v-else-if="!packageCost.costBreakdown && !isMobile">-</span>
                    </div>
                </div>
                <!-- If recurring cost -->
                <div v-if="showRecurringCost" class="flex-row price">
                    <div class="info">{{ recurringInfo }}</div>
                    <div  v-for="(packageCost, index) in packageCosts" :key="index" class="package cell"
                        :class="packageCost.selectable ? packageCost.activeKey : ''"
                    >
                        <small>{{ packageCost.recurringCost ? packageCost.recurringCost : '-' }}</small>
                    </div>
                </div>
                <!-- Custom reasons -->
                <div class="flex-row">
                    <div class="info">{{ hasRecurringCost ? t.cancel_any_time : t.one_off_payment }}</div>
                    <div v-if="packageCosts.length > 1" class="package cell premium"><i class="fa fa-check text-success"></i></div>
                    <div class="package cell extras"><i class="fa fa-check text-success"></i></div>
                </div>
                <!-- Upgrade reasons -->
                <upgrade-reason v-for="reason in upgradeReasons" :key="reason.id" :reason="reason" :restrictions="restrictions"
                    :showPremium="packageCosts.length > 1" :t="t.reasons"
                />
                <!-- Upgrade buttons -->
                <div class="flex-row" id="upgrade-buttons" :class="{'fixed' : fixedUpgradeButtons || upgradeButtonDiv.fixed }">
                    <div class="info"></div>
                    <div v-for="(packageCost, index) in packageCosts" :key="index" class="package cell"
                        :class="[packageCost.selectable ? packageCost.activeKey : '', packageCosts.length === 1 ? 'single-package' : '']"
                    >
                        <label v-if="packageCost.selectable" class="btn btn-lg btn-success upgrade-now" @click.prevent="submit(packageCost)">
                            {{ t.upgrade_now }}
                        </label>
                        <span v-if="packageCost.current" class="current">{{ !isMobile ? t.currently_have_membership : t.current_membership }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { throttle } from 'underscore';
import UpgradeReason from './SiteUpgradeReason.vue';

export default {
    name: 'SiteUpgradeInfo',
    components: {
        UpgradeReason
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile
        }),
        countPackageOptions() {
            if (!this.localConfig) {
                return null;
            }
            const packageOptions = [];
            Object.keys(this.packageLengths).forEach((length) => {
                packageOptions.push(length);
            });
            return packageOptions.length;
        },
        packageCosts() {
            const packageCosts = [];
            this.services.forEach((service) => {
                const paymentInfo = service.service;
                packageCosts.push(paymentInfo);
                // show if current service length is not the same as breakdown length
                if (paymentInfo.costBreakdown && this.selectedServiceLength !== paymentInfo.costBreakdown.breakdownLength) {
                    this.setAttribute('showCostBreakdown', true);
                }
                if (paymentInfo.recurringCost) {
                    this.setAttribute('hasRecurringCost', true);
                    if (paymentInfo.recurringCost !== paymentInfo.cost) {
                        // show recurring cost if different from initial cost
                        this.setAttribute('showRecurringCost', true);
                    }
                }
            });
            return packageCosts;
        },
        recurringInfo() {
            const serviceLength = this.getPackageLengthText();
            return this.t.recurring_notice.replace('%duration', serviceLength);
        },
        restrictions() {
            return this.localConfig ? this.localConfig.data.restrictions : null;
        },
        showToggleSwitch() {
            if (!this.localConfig) {
                return false;
            }
            return this.countPackageOptions > 1;
        },
        t() {
            return this.localConfig ? this.localConfig.translate : null;
        },
        upgradeReasons() {
            return this.localConfig ? this.localConfig.data.upgradeReasons : [];
        },
    },
    data() {
        return {
            hasRecurringCost: false,
            showCostBreakdown: false,
            showRecurringCost: false,
            upgradeButtonDiv: {
                fixed: false,
                height: 0,
                top: 0
            }
        };
    },
    methods: {
        choosePackageText(length) {
            if (length === 3 && this.isSpecialOffer) {
                // if offer replace monthly text
                return this.t.offer;
            }
            const duration = this.getPackageLengthText(length);
            // set first char to uppercase;
            return duration.charAt(0).toUpperCase() + duration.slice(1);
        },
        costBreakdownText(costBreakdown) {
            return this.t.cost_breakdown.replace('%cost', costBreakdown.cost)
                .replace('%duration', this.getPackageLengthText(costBreakdown.breakdownLength));
        },
        getPackageLengthText(length = this.selectedServiceLength) {
            let duration = '';
            switch (length) {
                case 2:
                    duration = this.t.weekly;
                    break;
                case 3:
                default:
                    duration = this.t.monthly;
                    break;
                case 4:
                    duration = this.t.yearly;
                    break;
            }
            return duration;
        },
        initialPriceDuration(service) {
            // returns string -> ('monthly' || 'yearly') || ('for 10 weeks' || 'for 1 year')
            const initCost = service.cost;
            const { recurringCost, period } = service;
            if (recurringCost && recurringCost === initCost) {
                // if recurring and at the same initial price
                return this.getPackageLengthText(service.paymentServiceLength);
            }
            return this.t.payment_duration.replace('%duration', period);
        },
        setAttribute(attribute, value) {
            this[attribute] = value;
        },
        setSelectedServiceLength(length) {
            this.$emit('set-selected-service-length', length);
        },
        shrinkDecimal(cost) {
            // find decimal and wrap with small tags
            const pattern = /\.\d{2}$/;
            return cost.replace(pattern, '<small>$&</small>');
        },
        submit(upgradeService) {
            const { gateways } = upgradeService;
            // if paypal and card available show payViaOptions
            if (gateways.length > 1) {
                const currentPackage = Object.assign({}, upgradeService, {
                    cost: this.shrinkDecimal(upgradeService.cost),
                    recurringInfo: upgradeService.recurring ? this.recurringInfo : null
                });
                this.$emit('payvia-options', currentPackage);
                return;
            }
            // if only card available show paymentForm
            this.$emit('service-gateway', { upgradeService, serviceGateway: gateways[0] });
        },
        upgradeText(totalGateways, serviceGateway) {
            return totalGateways === 1 ? this.t.upgrade_now : serviceGateway.gateway.description;
        },
        checkPageScroll() {
            const bottomOfScrolledWindow = window.pageYOffset + window.innerHeight;
            // if mobile && page scroll is above static upgrade buttons position -> set to fixed
            if (this.isMobile && (bottomOfScrolledWindow - this.upgradeButtonDiv.height) < this.upgradeButtonDiv.top) {
                // if modal on mobile scroll is ignored, upgrade buttons are fixed positioned
                this.upgradeButtonDiv.fixed = true;
                return;
            }
            this.upgradeButtonDiv.fixed = false;
        },
        getElementTop(el) {
            // loop through dom element nesting to get true distance from page top
            let offsetTop = 0;
            while (el !== null && typeof el.offsetTop !== 'undefined' && el.offsetTop !== 0) {
                offsetTop += el.offsetTop;
                el = el.offsetParent;
            }
            return offsetTop;
        }
    },
    mounted() {
        if (!this.fixedUpgradeButtons) {
            // only use page scroll detection if upgrade buttons are not already position fixed
            const upgradeDiv = document.getElementById('upgrade-buttons');
            if (upgradeDiv !== null) {
                this.upgradeButtonDiv.top = this.getElementTop(upgradeDiv);
                this.upgradeButtonDiv.height = upgradeDiv.offsetHeight;
            }
            this.checkPageScroll();
            window.addEventListener('scroll', throttle(this.checkPageScroll, 300));
        }
    },
    props: {
        fixedUpgradeButtons: {
            type: Boolean,
            default: false
        },
        isSpecialOffer: {
            type: Boolean
        },
        localConfig: {
            type: Object,
            default: null
        },
        packageLengths: {
            type: [Array, Object]
        },
        services: {
            type: Array
        },
        selectedServiceLength: {
            type: Number
        }
    },
    watch: {
        services() {
            // reset on service change for reevaluation
            this.hasRecurringCost = false;
            this.showCostBreakdown = false;
            // only show recurring if different from initial cost
            this.showRecurringCost = false;
        }
    }
};
</script>

<style lang="scss">
@import "~Site/scss/variables";

/**
* Gradient colours for A/B
*
*/
// Theme Case 1
$silverDark: #c0c0c0;
$silverMid: #d0d0d0; // fallback for non gradient supported browsers
$silverLight: #fff;
$silverBgColor: linear-gradient(to top left, $silverDark, $silverLight);
$silverColor: #053e56;

$goldDark: #e6c300;
$goldMid: #efd64f;
$goldLight: #fff9da;
$goldBgColor: linear-gradient(to top left, $goldDark, $goldLight);
$goldColor: #4a3f00;

$darkFullPrice: #2a0000;

// Theme Case 2
$blueBgColor: #4297c1;
$blueColor: #e4f3fb;
$blueCostColor: #fff;

$greenBgColor: #35af49;
$greenColor: #f4ffef;
$greenCostColor: #fff;

$lightFullPrice: rgba(255, 255, 255, 0.64); // ffd3d3;

// Set variables
$premiumBgColor: $blueBgColor;
$premiumColor: $blueColor;
$premiumCostColor: $blueCostColor;

$extrasBgColor: $greenBgColor;
$extrasColor: $greenColor;
$extrasCostColor: $greenCostColor;

$fullPrice: $lightFullPrice;

#upgrade-form {
    .payment-plan {
        .btn-group {
            max-width: 260px;
            width: 100%;
            display: flex;
            .btn {
                transition: background-color 250ms, color 250ms, border-color 250ms;
                flex: 1 1 50%;
                font-size: 1.4em;
                &.active {
                    background: #35af49;
                    color: #fff;
                }
            }
        }
    }
    .upgrade-plans {
        max-width: 900px;
        margin: 0 auto;
        h2 {
            margin-bottom: 10px;
        }
        .flex-row {
            display: flex;
            width: 100%;
            .info {
                flex: 1 1 40%;
                padding: 15px;
                min-height: 52px;
            }
            .package {
                flex: 1 0 30%;
                position: relative;
                text-align: center;
                padding-top: 10px;
                margin: 0px 10px;
            }
            &.breakdown {
                color: #555;
                font-size: 0.95em;
            }
        }
        .upgrade-headers {
            display: flex;
            .info {
                padding: 15px 3px;
            }
            .package-title {
                min-width: 88px;
                height: 170px;
                border-radius: 3px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                display: flex;
                padding: 10px 0px;
                flex-direction: column;
                font-size: 1.5em;
                // current package fallback
                background: #f0f0f0;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.19);
                color: #777;
                .title {
                    font-size: 1.3em;
                    height: 25%;
                }
                .cost {
                    display: flex;
                    flex-direction: column;
                    height: 60%;
                    justify-content: space-evenly;
                    .full-price {
                        color: $fullPrice;
                        font-size: 0.9em;
                    }
                    .current-price {
                        color: #fff;
                        font-size: 2em;
                        small {
                            font-size: 54%;
                            position: relative;
                            bottom: 12px;
                            left: 2px;
                        }
                    }
                }
                .cost-info {
                    font-size: 0.9em;
                }
            }
            .premium .package-title {
                border: none;
                background: $silverMid;
                background: $premiumBgColor;
                color: $premiumColor;
                .current-price {
                    color: $premiumCostColor;
                }
            }
            .extras .package-title {
                background: $goldMid;
                background: $extrasBgColor;
                color: $extrasColor;
                border: none;
                box-shadow: 0 10px 10px rgba(0, 0, 0, 0.19);
                .current-price {
                    color: $extrasCostColor;
                }
            }
        }
        .upgrade-options {
            font-size: 15px;
            margin-bottom: 20px;
            .info {
                border-bottom: solid 1px #f0f0f0;
            }
            .package {
                line-height: 31px;
                border-bottom: solid 1px #f0f0f0;
                background: #fff;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.19);
                label.upgrade-now {
                    font-size: 1.4em;
                    border: none;
                    width: 100%;
                    height: 72px;
                    line-height: 52px;
                    input {
                        visibility: hidden;
                        position:absolute;
                        top:-20px;
                    }
                    &:hover {
                        border-radius: 0;
                        border-bottom-left-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
                    &:after {
                        display: none;
                    }
                }
                .current {
                    color: #777;
                    vertical-align: middle;
                }
                &.extras {
                    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.19);
                }
            }
            // upgrade buttons
            .flex-row:last-child {
                .info, .package {
                    border-bottom: none;
                }
                .package {
                    max-height: 72px;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                    display: flex;
                    padding: 0px;
                    background: #f0f0f0;
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.37);
                    .current {
                        margin: auto;
                    }
                    &.premium {
                        background: $premiumBgColor;
                        .upgrade-now {
                            border-color: #2a5063;
                            background: transparent;
                            &:hover {
                                background: #285a73;
                            }
                        }
                    }
                    &.extras {
                        border: none;
                        background: $extrasBgColor;
                        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.19), 0 2px 10px rgba(0, 0, 0, 0.23);
                    }
                }
            }
        }
    }
}

// mobile styles
.upgrade-body.mobile #upgrade-form {
    h2 {
        font-size: 21px;
        margin-top: 0;
    }
    .upgrade-plans {
        .flex-row {
            background: none;
            flex-wrap: wrap;
            border-bottom: none;
            .cell {
                padding: 4px;
                margin: 0px;
            }
            .info {
                flex: 1 0 100%;
                text-align: center;
            }
            &.breakdown {
                padding: 6px 0;
                font-size: 0.9em;
                .package {
                    line-height: normal;
                }
            }
            &:nth-child(even) {
                background: #f7f7f7;
            }
        }
        .payment-plan .btn-group {
            margin: 0 auto;
        }
        .upgrade-headers {
            border-bottom: none;
            .package-title {
                height: 100%;
                border-radius: 3px;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23);
            }
            .cell {
                flex: 1 0 95px;
            }
        }
        .upgrade-options {
            box-shadow: none;
            .breakdown {
                .info {
                    display: none;
                }
                .package {
                    margin: 0 !important;
                    padding: 4px 1px;
                    text-align: left;
                }
            }
            .info {
                border-bottom: none;
                max-width: calc(100% - 56px);
                margin: 0 auto;
            }
            .package.cell {
                box-shadow: none;
                border: none;
                margin-top: -46px;
                i, strong, small {
                    display: inline-block;
                    width: 50px;
                    text-align: center;
                }
                &.premium {
                    background: transparent;
                    text-align: left;
                }
                &.extras {
                    background: transparent;
                    text-align: right;
                }
            }
            .breakdown .info {
                padding: 0px;
                min-height: 25px;
            }
            #upgrade-buttons {
                background: none;
                .info {
                    padding: 0;
                    min-height: 14px;
                }
                .package {
                    border-radius: 0;
                    display: inline;
                    margin: 0;
                    background: transparent;
                    .current {
                        background: #f0f0f0;
                        border: solid 1px #dcdcdc;
                        width: 100%;
                        text-align: center;
                        height: 45px;
                        line-height: 45px;
                        display: inline-block;
                        border-radius: 3px;
                        overflow: hidden;
                    }
                    .upgrade-now {
                        font-size: 1.3em;
                        min-width: 135px;
                        height: 45px;
                        line-height: 22px;
                        padding: 10px 0px;
                        border-radius: 3px;
                    }
                    &.premium .upgrade-now {
                        background: #4297c1;
                    }
                    &.single-package {
                        text-align: center !important;
                    }
                }
                &:not(.fixed) {
                    animation: slide-out 500ms;
                }
                &.fixed {
                    animation: slide-into-fixed 500ms;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                    background: #fff;
                    border-top: solid 1px #ddd;
                    box-shadow: 0px -1px 3px #ddd;
                    .info {
                        display: none;
                    }
                    .package {
                        background: transparent;
                        text-align: right;
                        display: inline;
                        .current {
                            width: calc(100% - 14px);
                        }
                        .upgrade-now {
                            width: calc(100% - 14px);
                        }
                        &.extras {
                            text-align: left;
                        }
                    }
                }
            }
        }
        .upgrade-footer {
            text-align: center;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 2;
            background: #fff;
            width: 100%;
            margin: 0 auto;
            border-top: solid 1px #ddd;
            box-shadow: -1px -1px 5px #ddd;
        }
    }
}
@media (max-width: $screen-xs-max) {
    .upgrade-headers .package {
        font-size: 11px;
    }
}
@keyframes slide-into-fixed {
    from {
        bottom: -50px;
    }
    to {
        bottom: 0;
    }
}
@keyframes slide-out {
    from {
        margin-top: 50px;
        margin-bottom: -50px;
    }
    to {
        margin-top: 0;
        margin-bottom: 0;
    }
}
</style>
