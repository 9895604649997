<template>
    <nav class="pagination-row pagination-main">
        <a href="#" @click.prevent="pageChange('prev')" class="btn btn-sm btn-default pull-left incrementer"
            :class="[ currentPage < 2 ? 'disabled' : 'site-colour' ]">
            &laquo; {{ gt.prev }}
        </a>
        <span class="go-to site-colour">{{ gt.page }}<form @submit.prevent="checkUserInput(pageInput)" novalidate>
            <input v-model="pageInput" type="number" pattern="[0-9]*" :min="1" :max="total"
            name="go_to_page" @keyup.enter="checkUserInput(pageInput)" />
        </form>{{ numOfPagesTxt }} {{ total }}</span>
        <a href="#" @click.prevent="pageChange('next')" class="btn btn-sm btn-default pull-right incrementer"
            :class="[currentPage === total ? 'disabled' : 'site-colour']">
            {{ gt.next }} &raquo;
        </a>
    </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';

/**
 * pagination.phtml and Pagination.vue should reflect the same logic.
 * Please mirror any changes across both locations.
 */

export default {
    name: 'Pagination',
    data() {
        return {
            currentPage: this.page,
            pageInput: this.page
        };
    },
    computed: {
        ...mapState({
            gt: state => state.gt.assets.pagination
        }),
        numOfPagesTxt() {
            return this.gt.num_of_pages.replace('%pageNum', '').replace('%total', '');
        }
    },
    methods: {
        ...mapActions(['notify']),
        checkUserInput() {
            this.pageInput = parseInt(this.pageInput, 10);
            if (this.pageInput > 0 && this.pageInput <= this.total) {
                this.goToPage(this.pageInput);
            } else {
                this.pageInput = this.currentPage;
                this.notify({ msg: this.gt.page_select_error.replace('%total', this.total), type: 'error' });
            }
        },
        /** Triggered on page change
         * @event page-changed
         * @type {Object}
         */
        goToPage(page) {
            this.currentPage = page;
            this.$emit('page-changed', this.currentPage);
        },
        pageChange(trigger) {
            switch (trigger) {
                case 'next':
                    if (this.currentPage === this.total) {
                        return;
                    }
                    this.currentPage += 1;
                    this.scrollTop();
                    break;
                case 'prev':
                    if (this.currentPage < 2) {
                        return;
                    }
                    this.currentPage -= 1;
                    this.currentPage = this.currentPage === 0 ? 1 : this.currentPage;
                    this.scrollTop();
                    break;
                default:
                    break;
            }
            this.$emit('page-changed', this.currentPage);
        },
        scrollTop() {
            window.scroll(0, this.top);
        }
    },
    props: {
        page: {
            type: Number,
            required: true
        },
        top: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            required: true
        }
    },
    watch: {
        page: function watchPage(newVal) {
            this.pageInput = newVal;
            if (newVal !== this.currentPage) {
                this.currentPage = newVal;
                this.pageChange();
            }
        }
    }
};

</script>

<style lang="scss">
nav {
    .incrementer {
        min-width: 85px;
    }
    .go-to {
        color: #333;
        form {
            margin: 0px 3px;
            display: inline-block;
            .form-group {
                margin-bottom: 0;
            }
            input {
                font-size: 13px;
                width: 35px !important;
                height: 32px;
                border-radius: 4px;
                border: 1px solid #ccc;
                margin: 0;
                padding: 0 2px;
                text-align: center;
            }
            input[type='number'] {
                -moz-appearance:textfield;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            input:focus + label,
            input.has-value + label,
            select:focus + label,
            select.has-value + label {
                background-color: #fff;
                transform: translateY(-65%) scale(0.75);
            }
        }
    }
}
</style>
