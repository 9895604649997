<template>
<div class="dropdown-menu notifications-dropdown" :class="{ 'collapse navbar-collapse-js': isMobile }">
    <div class="topbar">
        <form class="dropdown">
            <span>{{ t.notifications }}</span>
            <a v-if="notifications.length" href="#" @click.stop.prevent="clearVisible" class="pull-right">{{ t.clear_all }}</a>
        </form>
    </div>
    <ul>
        <loading v-if="loading"></loading>
        <li v-if="!loading && !notifications.length" class="no-notifications"><div>{{ t.no_notifications }}</div></li>
        <notification v-if="!loading && notifications.length && index < 10" v-for="(notificationData, index) in notifications"
            :key="notificationData.id" :notification="notificationData" @mediaviewer="showMediaViewer"></notification>
    </ul>
</div>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';
import Loading from 'Site/component/Loading.vue';

import Notification from './Notification.vue';
import notificationUtil from '../js/util/notification';

export default {
    name: 'Notifications',
    components: {
        Loading,
        Notification
    },
    data() {
        return {
            initialLoad: true,
            loading: true,
            notifications: [],
            windowActive: true,
            isMobile: this.$store.state.isMobile
        };
    },
    methods: {
        add(notification) {
            const exists = this.notifications.filter(checkNotification => checkNotification.id === notification.id);
            if (exists.length) {
                return;
            }
            this.notifications.unshift(notification);
        },
        clear(notification) {
            notificationUtil.clear(notification);
        },
        clearVisible() {
            this.notifications.forEach((notification, index) => {
                if (index >= 10) {
                    return;
                }
                this.clear(notification);
            });
        },
        fetch() {
            this.loading = true;
            ajax.get('activity', { limit: 10, notifications: true }).then((notifications) => {
                notifications.items.reverse();
                notifications.items.forEach((notification) => {
                    this.add(notification);
                });
                this.loading = false;
            });
        },
        remove(notification) {
            const lengthBefore = this.notifications.length;
            this.notifications = this.notifications.filter(checkNotification => checkNotification.id !== notification.id);
            const lengthAfter = this.notifications.length;

            // Check we actually removed a notification
            if (lengthBefore > lengthAfter) {
                this.$root.notificationCount = this.$root.notificationCount - 1;
            }

            if (!this.notifications.length) {
                // Get some more notifications from server if available
                this.fetch();
            }
        },
        showMediaViewer(notification) {
            const media = notification.target;
            this.$store.dispatch('showMediaViewer', { media: [media], start: 0 });
        },
        showNotify(notification) {
            const message = notification.phrase.single.replace('[USERNAME]', notification.actor.userName);
            const buttons = notificationUtil.getButtons(notification);
            let callback = null;
            if (notification.action === 'photoComments' ||
                notification.action === 'videoComments' ||
                notification.action === 'mediaAuth' ||
                notification.action === 'videoLikes' ||
                notification.action === 'photoLikes') {
                callback = () => {
                    this.showMediaViewer(notification);
                };
            }

            this.$store.dispatch('notify', {
                msg: message,
                callback,
                url: notification.target.url,
                user: notification.actor,
                type: 'notice',
                buttons
            });

            // Native browser notification shows if you are on a different tab
            if (('Notification' in window) && Notification.permission === 'default') {
                const notify = () => {
                    if (('Notification' in window) && Notification.permission === 'granted' && !this.windowActive) {
                        const options = {
                            icon: notification.actor.avatar
                        };

                        try {
                            const n = new Notification(message, options);
                            setTimeout(n.close.bind(n), 5000);

                            n.addEventListener('click', () => {
                                this.clear(notification);
                                if (callback) {
                                    this.showMediaViewer(notification);
                                } else {
                                    window.location.href = notification.get('target').url;
                                }
                                n.close.bind(n)();
                                return false;
                            });
                        } catch (e) {
                            // console.log('catch exception thrown on Android for Notification exists but can only be used from service worker')
                        }
                    }
                };
                const request = Notification.requestPermission(notify);
                if (request && typeof request.then === 'function') {
                    request.then(notify);
                }
            }
        },
        windowActiveSetup() {
            window.onfocus = () => {
                this.windowActive = true;
            };
            window.onblur = () => {
                this.windowActive = false;
            };
        }
    },
    mounted() {
        this.windowActiveSetup();

        this.$on('toggle', () => {
            if (this.initialLoad) {
                this.initialLoad = false;
                this.fetch();
            }
        });

        if (this.$store.state.siteConfig.enableNotifications) {
            notificationUtil.on('notification', (method, data) => {
                switch (method) {
                    default:
                    case 'create':
                        this.$root.notificationCount = this.$root.notificationCount + 1;
                        this.add(data);

                        if (document.querySelector('body').getAttribute('id') !== 'activity') {
                            this.showNotify(data);
                        }
                        break;
                    case 'remove':
                        this.remove({ id: data });
                        break;
                }
            });
        }
    },
    props: {
        t: Object
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

#bs-menu-notifications {
    position: absolute;
}

.no-notifications {
    padding: 10px;
    padding-bottom: 5px;
}

.notifications-dropdown {
    width: 300px;
    ul {
        overflow-y: auto;
        max-height: 300px;
        width: 100%;
    }

    div.topbar {
        /*font-size: inherit;*/
        text-transform: none;
        padding: 5px 10px;
        border-bottom: 1px solid #eaeaea;
        color: #505050;
        font-weight: normal;

        ul {
            width: 100%;
        }

        li {
            border-bottom: 1px solid #eaeaea;

            .published {
                font-size: 0.9em;
                color: #999;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
.notifications-no-upgrades {
    top: 87px !important;
}

#bs-menu-notifications {
    width: 100%;
    top: 43px;
    border-radius: 0;
    position: fixed;
    ul li {
        a {
            color: #505050;
            display: block;
            font-weight: normal;
            padding: 10px !important;
            &:hover {
                background-color: #333;
                color: #fff;
            }
        }
        &.no-notifications {
            color: #333;
        }
        .notify-buttons button {
            margin-bottom: 5px;
        }
    }
}
}

@media (max-width: 330px) {
    #notifications {
        right: 5px;
    }
}
</style>
