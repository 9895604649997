<template>
<div>
    <geocomplete v-model="location" @geocomplete="geocomplete" :t="t" :label="t.label_location"></geocomplete>
    <div class="location-display">
        <p v-if="showStatus || statusType === 'danger'" :class="`text-${statusType}`" v-text="status"></p>
        <label v-if="type === 'user' && display" v-text="t.label_displayed"></label>
        <span v-if="type === 'user'">{{ display }}</span>
    </div>
</div>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';

import Formatter from '../js/formatter';
import Geocomplete from './Geocomplete.vue';
import Parser from '../js/parser';

export default {
    name: 'Location',
    components: {
        Geocomplete
    },
    data() {
        return {
            display: '',
            firstLoad: true,
            location: '',
            locationWhitelist: [
                'Croatia',
                'Latvia',
                'Isle of Man'
            ],
            status: `${this.t.location_loading}...`,
            statusType: 'default'
        };
    },
    methods: {
        geocomplete({ result }) {
            const parser = new Parser(result);
            this.location = result.formatted_address;

            this.processResponse(parser);
        },
        processResponse(parser) {
            const addressParts = parser.getAddressParts();

            if (!addressParts.administrative_area_level_1 && this.locationWhitelist.indexOf(addressParts.country) === -1) {
                this.status = this.t.location_more_needed;
                this.statusType = 'danger';
                return;
            }

            const formatter = new Formatter(addressParts);
            const formats = formatter.getAvailableFormats();
            const data = parser.find([
                'locality',
                'sublocality',
                'country',
                'postal_town',
                'administrative_area_level_1',
                'administrative_area_level_2'
            ]);

            /* eslint prefer-destructuring:0 */
            data.formatted = formats[0];
            data.latitude = parser.getLat();
            data.longitude = parser.getLong();

            this.$emit('raw', data);

            this.save(data).then(() => {
                this.save({ customFormatted: formats[0] });
                this.$emit('formatted', formats[0]);
            });

            this.display = formats[0];
        },
        select() {
            if (this.firstLoad || !this.display) {
                return;
            }
            this.save({ customFormatted: this.display });
        },
        save(data) {
            if (this.type !== 'user') {
                return Promise.resolve();
            }
            this.statusType = 'default';
            this.status = `${this.t.location_saving}...`;

            return ajax.create('location', data).then(() => {
                this.status = this.t.location_saved;
                this.statusType = 'success';
            }).catch(() => {
                this.status = this.t.location_error;
                this.statusType = 'danger';
            });
        },
        statusChange(status) {
            this.status = status;
        }
    },
    mounted() {
        if (this.type === 'user') {
            ajax.get('location').then((result) => {
                const current = result[0];
                this.status = '';
                this.statusType = 'default';

                if (current && current !== 'Not set') {
                    const formatter = new Formatter(current, current.formatted);
                    this.location = current.formatted;
                    this.display = current.custom_formatted;
                }

                this.$nextTick(() => {
                    this.firstLoad = false;
                });
            });
        } else {
            this.location = this.value;
        }

        this.$on('submit', () => {
            this.$parent.$emit('hideModal');
        });
    },
    props: {
        showStatus: {
            type: Boolean,
            default: true
        },
        t: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'user'
        },
        value: {
            type: String,
            default: ''
        }
    }
};

</script>

<style lang="scss" scoped>
.location-display {
    margin-left: 14px;
    label {
        margin: 0;
    }
}
</style>
