<template>
<div class="payment-success">
    <div id="page-header">
        <h1>{{ t.payment_successful }}</h1>
    </div>
    <div class="success">
        <i class="fa fa-check"></i>
    </div>
    <div class="details">
        <h2 style="text-align: center;" v-html="t.thank_you.replace('%username', username)"></h2>

        <div class="summary">
            <p class="header"><strong>{{ t.summary }}</strong></p>
            <span class="sum-label">{{ t.package }}</span><span>{{ response.name }}</span><br>
            <span class="sum-label">{{ t.total }}</span><span v-html="response.cost" /><br>
            <span v-if="response.createdAt" class="sum-label">
                {{ t.date }}</span><span>{{ response.createdAt }}
            </span><br>
            <span v-if="response.reference" class="sum-label">
                {{ t.reference }}</span><span class="ref">{{ response.reference }}
            </span>
        </div>

        <div class="footer-notes">
            <p>{{ t.discretion_notice }}</p>
            <p v-if="response.recurringCost" v-html="t.recurring_cost.replace('%amount', response.recurringCost)"></p>

            <div v-if="idVerificationSetting && !allowIdVerified" class="alert alert-success" v-html="t.verify_age.replace('%href', idVerificationUrl)">
            </div>

            <p class="refresh">
                <span>{{ t.refresh_notice }}</span><br>
                <button onclick="location.reload();" class="btn btn-success">{{ t.refresh_btn }}</button>
            </p>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import store from '../js/store';

export default {
    name: 'SitePaymentSuccess',
    store,
    computed: {
        ...mapState({
            username: state => state.siteConfig.user.name
        }),
        allowIdVerified() {
            return this.localConfig.data.permissions.idVerified;
        },
        idVerificationUrl() {
            return `href="${this.localConfig.pages.idVerification}"`;
        },
        t() {
            return this.localConfig.translate.payment_success;
        }
    },
    props: {
        idVerificationSetting: {
            type: Boolean,
            default: false
        },
        localConfig: {
            type: Object,
            required: true
        },
        response: {
            type: Object,
            requred: true
        }
    }
}
</script>

<style lang="scss" scoped>
.payment-success {
    h1, h2 {
        color: #35af49;
    }
}
</style>
