<template>
<span class="sh-editable">
    <button v-if="!edit && editable" class="btn btn-xs btn-default" @click="edit=true"><i class="fa fa-pencil"></i></button>
    <span v-if="!edit" v-html="displayValue"></span>
    <span :class="{'form-inline':inline}" v-if="edit">
        <input type="text" class="form-control" v-model="newValue" v-if="type === 'text'" ref="input">
        <textarea class="form-control" v-model="newValue" v-if="type === 'textarea'" ref="input"></textarea>
        <select class="form-control" v-model="newValue" v-if="type === 'select'">
            <option :value="option.value" v-for="option in options" :key="option.value">{{ option.text }}</option>
        </select>
        <check-boxes :options="options" v-model="updatedValue" v-if="type === 'checkboxes'"></check-boxes>
        <text-box v-model="newValue" v-if="type === 'textbox'"></text-box>
        <wysiwyg v-model="newValue" v-if="type === 'wysiwyg'" :buttons="['bold', 'italic', 'underline', 'lists']"
            :plugins="['emoticons', 'quote', 'fullscreen']"></wysiwyg>
        <geocomplete v-model="newValue" v-on:geocomplete="geocomplete" :t="t" v-if="type === 'geocomplete'"></geocomplete>
        <location v-model="newValue" :t="t" v-if="type === 'location'"></location>
        <slider v-model="updatedValue" :options="sliderOptions" :parseLabel="sliderParseLabel" v-if="isSlider"></slider>
        <div v-if="type === 'heightcm'">
            <input id="xcm" class="form-control input-sm" min="30" max="300" type="number" v-model.number="height.cm">
            <label for="xcm">{{ gt.cm }}</label>
        </div>
        <div v-if="type === 'heightfeet' || type === 'height'">
            <label for="xfeet">{{ gt.feet }}</label>
            <input id="xfeet" class="form-control input-sm" min="4" max="7" type="number" v-model.number="height.feet">
            <label for="xinches">{{ gt.inches }}</label>
            <input id="xinches" class="form-control input-sm" min="0" max="11" type="number" v-model.number="height.inches">
        </div>
        <div v-if="type === 'dob'">
            <select class="form-control input-sm" v-model="dob.day">
                <option v-for="day in dayOptions" :value="day.value" :key="day.value">{{ day.text }}</option>
            </select>
            <select class="form-control input-sm" v-model="dob.month">
                <option v-for="month in monthOptions" :value="month.value" :key="month.value">{{ month.text }}</option>
            </select>
            <select class="form-control input-sm" v-model="dob.year">
                <option v-for="year in yearOptions" :value="year.value" :key="year.value">{{ year.text }}</option>
            </select>
        </div>
        <date-picker v-if="type === 'date'" v-model="newValue"></date-picker>
        <date-picker v-if="type === 'datetime'" v-model="newValue" :time="true"></date-picker>
        <button class="btn btn-primary" @click="save">
            <template v-if="!loading">{{ gt.save }}</template>
            <loading v-if="loading"></loading>
        </button>
        <button class="btn btn-default" @click="edit=false">{{ gt.cancel }}</button>
    </span>
    <!-- @slot Fallback content, rendered if not in edit mode and value is null -->
    <slot v-if="!edit && !newValue"></slot>
</span>
</template>

<script>
import { mapState } from 'vuex';
import Geocomplete from 'Location/component/Geocomplete.vue';
import Location from 'Location/component/Location.vue';
import Parser from 'Location/js/parser';

import ajax from '../js/util/ajax.fetch';
import CheckBoxes from './CheckBoxes.vue';
import DatePicker from './DatePicker.vue';
import Loading from './Loading.vue';
import Slider from './Slider.vue';
import TextBox from './TextBox.vue';
import Wysiwyg from './Wysiwyg.vue';

export default {
    name: 'Editable',
    components: {
        CheckBoxes,
        DatePicker,
        Geocomplete,
        Loading,
        Location,
        Slider,
        TextBox,
        Wysiwyg
    },
    computed: {
        ...mapState({
            gt: state => state.gt.common,
            seekAttributeDistanceConfig: state => state.config.data.seekAttributeDistanceConfig
        }),
        displayValue() {
            if (this.type === 'select') {
                const options = this.options.filter(option => option.value === this.newValue);
                return options.length ? options[0].text : '';
            } else if (this.type === 'checkboxes') {
                const array = [];
                this.newValue.forEach((value) => {
                    array.push(this.options.filter(option => option.value === value)[0].text);
                });
                return array.join(', ');
            } else if (this.type === 'ageRange') {
                if (this.newValue) {
                    return this.newValue.join(' - ');
                }
            } else if (this.type === 'height' || this.type === 'heightfeet') {
                return `${this.height.feet}' ${this.height.inches}`;
            } else if (this.type === 'heightcm') {
                return `${Math.round(this.newValue * 2.54)}${this.gt.cm}`;
            } else if (this.type === 'heightRange') {
                return `${this.sliderParseLabel(this.newValue[0])} - ${this.sliderParseLabel(this.newValue[1])}`;
            } else if (this.type === 'dob') {
                const date = new Date(this.newValue);
                return date.toLocaleDateString();
            }
            return this.newValue;
        },
        ajaxParams() {
            let params = Object.assign({}, this.params);
            params[this.property] = this.newValue;

            if (this.extraParams) {
                params = Object.assign(params, this.extraParams);
            }
            if (this.property !== 'url') {
                delete params.url;
            }
            return params;
        },
        dayOptions() {
            if (this.type !== 'dob') {
                return [];
            }
            const options = [];
            for (let day = 1; day <= 31; day++) {
                options.push({ value: `${day}`, text: day });
            }
            return options;
        },
        monthOptions() {
            if (this.type !== 'dob') {
                return [];
            }
            return [
                { value: '1', text: this.$store.state.t.date.months.jan },
                { value: '2', text: this.$store.state.t.date.months.feb },
                { value: '3', text: this.$store.state.t.date.months.mar },
                { value: '4', text: this.$store.state.t.date.months.apr },
                { value: '5', text: this.$store.state.t.date.months.may },
                { value: '6', text: this.$store.state.t.date.months.jun },
                { value: '7', text: this.$store.state.t.date.months.jul },
                { value: '8', text: this.$store.state.t.date.months.aug },
                { value: '9', text: this.$store.state.t.date.months.sep },
                { value: '10', text: this.$store.state.t.date.months.oct },
                { value: '11', text: this.$store.state.t.date.months.nov },
                { value: '12', text: this.$store.state.t.date.months.dec }
            ];
        },
        yearOptions() {
            if (this.type !== 'dob') {
                return [];
            }
            const options = [];
            const date = new Date();
            for (let year = parseInt(date.getFullYear(), 10) - 17; year >= (parseInt(date.getFullYear(), 10) - 100); year--) {
                options.push({ value: `${year}`, text: year });
            }
            return options;
        },
        isSlider() {
            return this.type === 'distance' || this.type === 'ageRange' || this.type === 'heightRange';
        },
        sliderOptions() {
            if (this.type === 'distance') {
                if (!this.seekAttributeDistanceConfig) {
                    /* eslint no-console: 0 */
                    console.error('missing seekAttributeDistanceConfig value in JSON');
                }
                return {
                    start: this.newValue,
                    step: this.seekAttributeDistanceConfig.step,
                    range: {
                        min: this.seekAttributeDistanceConfig.min,
                        max: this.seekAttributeDistanceConfig.max
                    },
                    connect: 'lower'
                };
            } else if (this.type === 'ageRange') {
                return {
                    start: this.newValue,
                    step: 1,
                    range: {
                        min: 18,
                        max: 100
                    },
                    connect: true
                };
            } else if (this.type === 'heightRange') {
                return {
                    start: this.newValue,
                    step: 1,
                    range: {
                        min: 48,
                        max: 96
                    },
                    connect: true
                };
            }
            return {};
        },
        sliderParseLabel() {
            if (this.type !== 'heightRange') {
                return function parse(value) {
                    return parseInt(value, 10);
                };
            }

            return function parseHeight(value) {
                const feet = parseInt(value / 12, 10);
                const inches = value % 12;
                return `${feet}' ${inches}"`;
            };
        }
    },
    data() {
        let date = {};
        if (this.type === 'dob') {
            date = new Date(this.value);
        }
        return {
            dob: {
                day: this.type === 'dob' ? date.getDate() : null,
                month: this.type === 'dob' ? date.getMonth() + 1 : null,
                year: this.type === 'dob' ? date.getFullYear() : null
            },
            edit: false,
            extraParams: {},
            height: {
                cm: !Array.isArray(this.value) ? parseInt(this.value * 2.54, 10) : 0,
                feet: !Array.isArray(this.value) ? parseInt(this.value / 12, 10) : 0,
                inches: !Array.isArray(this.value) ? parseInt(this.value % 12, 10) : 0,
            },
            loading: false,
            newValue: this.value,
            updatedValue: this.value,
            url: this.params.url
        };
    },
    methods: {
        geocomplete({ result }) {
            this.newValue = result.formatted_address;
            const parser = new Parser(result);
            this.extraParams.latitude = parser.getLat();
            this.extraParams.longitude = parser.getLong();
        },
        save() {
            this.loading = true;
            if (this.type === 'dob') {
                this.newValue = this.updateDob();
            } else if (this.type === 'height' || this.type === 'heightfeet') {
                this.newValue = this.updateHeight();
            } else if (this.type === 'heightcm') {
                this.newValue = this.updateHeightCm();
            } else if (this.type === 'location') {
                this.newValue = this.updateLocation();
            } else if (this.type === 'checkboxes' || this.isSlider) {
                this.newValue = this.updatedValue;
            }
            if (!this.ajaxParams.id && this.params.id) {
                this.ajaxParams.id = this.params.id;
            }
            ajax.update(this.url, this.ajaxParams).then((response) => {
                this.loading = false;
                if (response.message) {
                    this.$store.dispatch('notify', { msg: response.message });
                }
                this.$emit('input', this.newValue);
                this.edit = false;
            });
        },
        updateDob() {
            let month = this.dob.month.toString();
            if (month.length === 1) {
                month = `0${month}`;
            }
            let day = this.dob.day.toString();
            if (day.length === 1) {
                day = `0${day}`;
            }
            const date = new Date(`${this.dob.year}-${month}-${day}`);
            return date.toISOString();
        },
        updateHeight() {
            return (parseInt(this.height.feet, 10) * 12) + parseInt(this.height.inches, 10);
        },
        updateHeightCm() {
            return Number(this.height.cm / 2.54).toFixed(4);
        },
        updateLocation() {
            ajax.get('location').then((result) => {
                const current = result[0];
                if (current && current !== 'Not set') {
                    this.newValue = current.custom_formatted;
                    return this.newValue;
                }
                return false;
            });
        }
    },
    props: {
        editable: {
            type: Boolean,
            default: true
        },
        inline: {
            type: Boolean,
            default: true
        },
        options: Array,
        params: Object,
        property: String,
        t: Object,
        type: {
            type: String,
            default: 'text'
        },
        value: [Array, Number, String]
    },
    watch: {
        edit(value) {
            if (value) {
                this.$nextTick(() => {
                    if (this.$refs.input) {
                        this.$refs.input.focus();
                    }
                });
            }
        },
        editable(value) {
            if (!value) {
                this.edit = false;
            }
        },
        value(value) {
            this.newValue = value;
        }
    }
};

</script>

<style lang="scss">
.sh-editable {
    .btn {
        margin-right: 5px;
    }
    select {
        margin-left: 0;
    }
    textarea, select, input, .redactor-box {
        margin-bottom: 10px;
    }
    .form-inline textarea, .form-inline select, .form-inline input {
        margin: 0;
    }
    .btn .loading {
        width: 26px;
    }
    .slider {
        margin-top: 5px;
        margin-bottom: 45px;
        margin-right: 5px;
    }
    .textbox {
        clear: none;
        margin: 5px 0;
        .message-textarea {
            min-height: 75px;
        }
    }
}
</style>
