<template>
<div class="slider" :class="{ 'site-bg': options.connect !== true }"></div>
</template>

<script>
import 'nouislider/src/nouislider.css';

export default {
    name: 'Slider',
    methods: {
        updateValue(newValues) {
            if (this.$el.noUiSlider) {
                this.$el.noUiSlider.set(newValues);
            }
        },
        /** Triggered when slider value is changed
         * @event slider-value-changed
         * @type number|Array
         */
        createSlider() {
            this.noUiSlider.create(this.$el, this.options);

            this.values = !Array.isArray(this.value) ? [this.value] : this.value;

            if (this.options.connect === true) {
                this.$el.getElementsByClassName('noUi-connect')[0].classList.add('site-bg');
            }

            const handles = this.$el.getElementsByClassName('noUi-handle');
            for (let i = 0; i < handles.length; i++) {
                handles[i].innerHTML = `<div class="slider-tooltip">${this.parselabel(this.values[i])}</div>`;
            }

            this.$el.noUiSlider.on('update', (newvalues, handle, unencoded) => {
                if (Array.isArray(unencoded)) {
                    unencoded = unencoded[handle];
                }
                handles[handle].getElementsByClassName('slider-tooltip')[0].textContent = this.parselabel(unencoded);
            });

            this.$el.noUiSlider.on('change', (newvalues, handle, unencoded) => {
                if (Array.isArray(unencoded)) {
                    unencoded = unencoded.map(item => parseInt(item, 10));
                    if (unencoded.length === 1) {
                        [unencoded] = unencoded;
                    }
                } else {
                    unencoded = parseInt(unencoded, 10);
                }
                this.$emit('input', unencoded);
                this.$emit('slider-value-change', unencoded);
            });
        }
    },
    mounted() {
        import(/* webpackChunkName: "nouislider" */'nouislider').then((noUiSlider) => {
            this.noUiSlider = noUiSlider;
            this.$nextTick(() => {
                this.createSlider();
            });
        });
    },
    props: {
        options: Object,
        parselabel: {
            type: Function,
            default(value) {
                if (value === null) {
                    return ' ';
                }
                return parseInt(value, 10);
            }
        },
        value: [Number, Array]
    },
    watch: {
        value(newValue) {
            newValue = !Array.isArray(newValue) ? [newValue] : newValue;
            this.updateValue(newValue);
        }
    }
};
</script>

<style lang="scss">
.noUi-horizontal .noUi-handle {
    width: 40px;
}

.noUi-handle {
    &:before {
        left: 17px;
    }
    &:after {
        left: 20px;
    }
}

.slider-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    top: 32px;
    padding: 5px;
    padding-top: 4px;
    left: -4px;
    text-align: center;
    width: 46px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.noUi-pips {
    color: #bbb;
}

.noUi-marker-large {
    background: #ccc;
}

.noUi-marker {
    background: #ddd;
}
</style>
