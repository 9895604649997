<template>
    <div class="textbox" :class="{'textbox-compact':compact}">
        <div class="textbox-wrapper">
            <div @change="update" @paste="sanitizePaste" @keyup="update" @click="checkPermissions" @keydown.enter="submit" ref="textbox"
                @blur="$emit('blur')" @focus="$emit('focus')"
                class="message-textarea" :placeholder="placeholder" contenteditable></div>
            <div class="textbox-buttons" ref="textboxButtons">
                <button v-if="value && showSend" class="btn btn-default pull-right" @click="submit"><i class="fa fa-share"></i></button>
                <div class="btn-group" :class="[dropPosition, {'pull-right': emojiPosition === 'right'}]">
                    <button v-if="emojiPicker" class="btn btn-default" @click.prevent="showEmoticons" v-dropdown>
                        <i class="fa fa-smile-o"></i>
                    </button>
                    <button v-if="!onMedia && mediaPicker && medias.length < maxMedia" class="btn btn-default"
                        @click.prevent="showMediaPicker(mediaPickerData)">
                        <i class="fa fa-camera"></i>
                    </button>
                    <div v-if="emojiPicker" class="dropdown-menu emoji-wrapper">
                        <span v-for="emote in emoji" :key="emote.name" v-if="emote.unicode" @click="insertEmoji(emote)">
                            {{ emote.unicode }}
                        </span>
                        <span v-for="emote in emoji" :key="emote.name" v-if="emote.type === 'sprite' && enableSpriteEmoji"
                            @click="insertEmoji(emote)"><i :class="`emote emote-${emote.name}`"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="mediaPicker" class="textbox-media">
            <media v-for="media in medias" :key="media.id" :media="media" :stats="false" :card="false" :adult-allowed="true"
                :private-allowed="privateMedia">
                <span class="fa-stack fa-stack-remove fa-lg" @click="removeMedia(media)">
                    <i class="fa fa-circle fa-stack-2x"></i>
                    <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
                </span>
            </media>
            <div v-if="medias.length && medias.length < maxMedia" class="textbox-media-add" @click="showMediaPicker(mediaPickerData)">
                <i class="fa fa-plus"></i>
                <div>{{ gt.common.add_media }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Media from 'Media/component/Media.vue';

import dropdownDirective from '../directive/dropdown';
import { list } from '../js/util/emoticons';

export default {
    name: 'TextBox',
    computed: {
        ...mapState(['gt']),
        dropPosition() {
            if (this.postType === 'profile') {
                return 'dropdown';
            }
            return 'dropup';
        }
    },
    components: {
        Media
    },
    data() {
        return {
            emojiPosition: 'right',
            enableSpriteEmoji: !/^((?!chrome|android|mobile).)*safari/i.test(navigator.userAgent),
            cursor: {
                start: null,
                end: null,
                node: null
            },
            emoji: list(this.$store.state.siteConfig.data.imagesUrl),
            medias: [],
            mediaPickerData: {
                adultAllowed: true,
                callback: this.addMedia,
                showPrivate: this.privateMedia,
                type: [0, 2]
            }
        };
    },
    directives: {
        dropdown: dropdownDirective
    },
    methods: {
        ...mapActions(['showMediaPicker']),
        /** Triggered when the input is clicked
         * @event check-permissions
         * @type {null}
         */
        checkPermissions() {
            this.$emit('check-permissions');
        },
        insertSprite(emote) {
            const wasEmpty = !this.value;
            const { range, selection } = this.resetSelection();
            const el = document.createElement('i');
            el.contentEditable = false;
            // IE doesn't support adding multiple classes at once
            el.classList.add('emote');
            el.classList.add(`emote-${emote.name}`);
            range.insertNode(el);
            range.setStartAfter(el);
            if (wasEmpty) {
                this.insertText(' ', range);
            }
            const baseNode = this.cursor.node || this.$refs.textbox;

            // Collapse the selection to the node after the node we have just inserted
            for (let i = 0; i < baseNode.parentNode.childNodes.length; i++) {
                if (baseNode.parentNode.childNodes[i] === el) {
                    selection.collapse(baseNode.parentNode.childNodes[i + 1]);
                }
            }
        },
        insertText(text, range) {
            if (document.queryCommandSupported('insertText')) {
                document.execCommand('insertText', false, text);
            } else {
                const textNode = document.createTextNode(text);
                range.insertNode(textNode);
            }
        },
        insertUnicode(emote) {
            const { range, selection } = this.resetSelection();
            this.insertText(`${emote.unicode} `, range);
            range.setStart(this.cursor.node || selection.anchorNode, this.cursor.end + 1 || 1);
        },
        insertEmoji(emote) {
            this.$refs.textbox.focus();
            if (emote.type === 'sprite') {
                this.insertSprite(emote);
            } else {
                this.insertUnicode(emote);
            }
            this.update();
        },
        resetMedia() {
            this.medias = [];
        },
        resetSelection() {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            range.setStart(this.cursor.node || this.$refs.textbox, this.cursor.start || 0);
            range.setEnd(this.cursor.node || this.$refs.textbox, this.cursor.end || 0);
            return { range, selection };
        },
        sanitizePaste(event) {
            event.preventDefault();
            let contents = '';
            if (window.clipboardData) {
                contents = window.clipboardData.getData('Text');
            } else {
                contents = event.clipboardData.getData('text/plain');
            }
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            this.insertText(contents, range);
            this.update();
        },
        /**
         * Force update html contents
         * 
         * @param {string} html
         * @public
         */
        setContent(html = '') {
            this.$refs.textbox.innerHTML = html;
        },
        /**
         * Insert text at cursor position.
         *
         * @param {string} text
         * @public
         */
        setEmojiPosition() {
            if (this.$refs.textboxButtons.offsetLeft === 0) {
                this.emojiPosition = 'left';
            } else {
                this.emojiPosition = 'right';
            }
        },
        showEmoticons() {
            const selection = window.getSelection();

            if (selection.anchorNode && (selection.anchorNode === this.$refs.textbox || selection.anchorNode.parentNode === this.$refs.textbox ||
                (selection.anchorNode.parentNode && selection.anchorNode.parentNode.parentNode === this.$refs.textbox))) {
                this.cursor.start = selection.anchorOffset;
                this.cursor.end = selection.focusOffset;
                this.cursor.node = selection.anchorNode;
            } else {
                this.cursor.start = null;
                this.cursor.end = null;
                this.cursor.node = null;
            }
        },
        /** Triggered when media is added
         * @event update-media
         * @type {Array}
         */
        addMedia(medias) {
            if (this.medias.length + medias.length <= this.maxMedia) {
                this.medias = this.medias.concat(medias);
            } else {
                const maxItemsToAdd = this.maxMedia - this.medias.length;
                this.medias = this.medias.concat(medias.slice(0, maxItemsToAdd));
                this.$store.dispatch('notify', {
                    msg: this.gt.common.media_limit.replace('%count', maxItemsToAdd).replace('%max_media', this.maxMedia)
                });
            }
            // De-dupe
            const mediaIds = this.medias.map(mediaItem => mediaItem.id);
            this.medias = this.medias.filter((mediaItem, index) => mediaIds.indexOf(mediaItem.id) === index);

            this.$emit('update-media', this.medias.map(media => media.id));
        },
        removeMedia(mediaItem) {
            const index = this.medias.indexOf(mediaItem);
            this.medias.splice(index, 1);
            this.$emit('update-media', this.medias.map(media => media.id));
        },
        /** Triggered when submit button is pressed
         * @event submit
         * @type {Array}
         */
        submit(event) {
            if (!event.shiftKey && this.compact) {
                event.preventDefault();
                if (this.value) {
                    this.$emit('submit', this.medias.map(media => media.id));
                    this.medias = [];
                    this.$refs.textbox.blur();
                }
            }
        },
        update() {
            if (this.$refs.textbox.innerHTML === '<br>') {
                this.setContent();
            }
            this.$emit('input', this.$refs.textbox.innerHTML);
            this.setEmojiPosition();
        }
    },
    mounted() {
        this.setContent(this.value);
    },
    inject: {
        onMedia: {
            default: false
        }
    },
    props: {
        compact: {
            type: Boolean,
            default: false
        },
        emojiPicker: {
            type: Boolean,
            default: true
        },
        mediaPicker: {
            type: Boolean,
            default: false
        },
        maxMedia: {
            type: Number,
            default: 6
        },
        placeholder: {
            type: String
        },
        privateMedia: {
            type: Boolean,
            default: false
        },
        postType: {
            type: String,
        },
        showSend: {
            type: Boolean,
            default: false
        },
        value: {
            type: String
        }
    }
};

</script>

<style lang="scss">
@import "../scss/variables";
@import "../scss/emoticons";

.textbox {
    background-color: #fff;
    clear: both;
    border: 1px solid #ccc;
    border-radius: 2px;
    .textbox-wrapper {
        .message-textarea {
            width: 100%;
        }
        .textbox-buttons {
            width: 100%;
        }
    }
}

.textbox-compact {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    overflow-y: unset;
    .textbox-wrapper {
        .message-textarea {
            min-height: 38px;
            max-height: 150px;
            padding: 6px 12px;
            width: auto;
        }
        .textbox-buttons {
            width: auto;
        }
    }
}

.textbox-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .emoji-wrapper {
        font-size: 0;
        width: 304px;
        height: 150px;
        padding: 0 2px;
        overflow-y: scroll;
        span {
            font-size: 28px;
            width: 40px;
            height: 40px;
            padding: 2px;
            text-align: center;
            display: inline-block;
            cursor: pointer;
        }
    }

    .message-textarea {
        min-height: 120px;
        padding: 16px;
        overflow-x: hidden;
        overflow-y: auto;
        flex-grow: 1;
        word-break: break-word;
        font-size: 16px;
        line-height: 1.6;
        outline: 0;
        border: 0;
        resize: none;
        border-radius: 0;
        &[placeholder]:empty:before {
            content: attr(placeholder);
            color: #666;
        }
    }
}

.profile-update {
    .textbox-wrapper {
        .message-textarea {
            // Using Min & Max Height due to other CSS uses, and a refusal to use !important
            max-height: 40px;
            min-height: 40px;
            transition: min-height 250ms linear, max-height 250ms linear;
            overflow: hidden;
            &:focus,
            &:not(:empty) {
                min-height: 120px;
                max-height: 120px;
                overflow-y: visible;
            }
        }
    }
}

.mediaviewer .textbox-wrapper .emoji-wrapper {
    width: 262px;
    height: 88px;
}
.textbox-buttons {
    align-self: flex-end;
    height: 38px;
    button {
        margin: 0 0 0 auto;
        border-radius: 0;
        height: 38px;
        background-color: transparent;
        border: 0;
        i {
            color: #999;
            font-size: 1.2em;
        }
    }
}

.textbox-media {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    .media-item, .textbox-media-add {
        width: 100px;
        height: 100px;
    }
    .textbox-media-add {
        border: 1px solid #ccc;
        color: #999;
        cursor: pointer;
        &:hover {
            color: #666;
            border-color: #666;
        }
        div {
            line-height: 1em;
            text-align: center;
            margin-top: -5px;
        }
        .fa-plus {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80px;
            font-size: 3em;
            margin: 0;
        }
    }
}
@media (max-width: $screen-xs-max) {
    .textbox {
        .dropdown-menu.emoji-wrapper {
            position: fixed;
            bottom: 0;
            width: 100%;
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
            -webkit-overflow-scrolling: touch;
        }
        .dropup .emoji-wrapper {
            height: 0;
            display: block;
            transition: height 250ms;
        }
        .dropup.open .emoji-wrapper {
            height: 250px;
            transition: height 750ms;
        }
    }
}
</style>
