<template>
<a :href="link" @click="clickHandler" :class="['share-card', {'share-card-nolink': !link}]">
    <h2><i v-if="icon" :class="['fa', `fa-${icon}`]"></i> {{ heading }}</h2>
    <slot></slot>
    <p v-if="text">{{ text }}</p>
</a>
</template>

<script>
import analytics from 'Site/js/util/analytics';

export default {
    name: 'ShareCard',
    methods: {
        clickHandler() {
            if (this.link) {
                analytics('share', 'item-click');
            }
            return !!this.link;
        }
    },
    props: {
        heading: {
            type: String,
            required: true
        },
        icon: {
            type: String
        },
        link: {
            type: String
        },
        text: {
            type: String
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.share-card {
    @include card;
    display: block;
    padding: 10px;
    position: relative;
    background-color: #fff;
    color: $text-color;
    margin: 0;
    transition: background-color 500ms;
    &:hover {
        background-color: #f6f6f6;
        text-decoration: none;
        h2 {
            text-decoration: underline;
        }
    }
    h2 {
        color: $text-color;
        margin: 0;
        i {
            margin-right: 5px;
        }
    }
    p {
        margin: 10px 0 0;
    }
    .fa-stack-remove {
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
        .fa-circle {
            color: $brand-danger;
        }
    }
}
.share-card-nolink {
    cursor: default;
    &:hover {
        background-color: #fff;
        h2 {
            text-decoration: none;
        }
    }
}
</style>
