<template>
<div class="wall-post">
    <div class="dropdown pull-right" v-if="!readonly">
        <button type="button" class="wall-post-btn btn btn-xs" v-dropdown>
            <i class="fa fa-chevron-down"></i>
        </button>
        <ul class="dropdown-menu">
            <template v-if="displayedOnWall && post.pin">
                <li v-if="!post.pinned"><a @click.prevent="pinPost(true)" href="#">{{ t.pin_post }}</a></li>
                <li v-if="post.pinned"><a @click.prevent="pinPost(false)" href="#">{{ t.unpin_post }}</a></li>
                <li class="divider"></li>
            </template>
            <li v-if="post.edit && !readonly"><a href="#" @click.prevent="enableEdit = !enableEdit">{{ gt.toggle_edit }}</a></li>
            <li v-if="!post.delete"><a href="#" v-report="reportData">{{ t.report_post }}</a></li>
            <li v-if="post.delete"><a @click.prevent="deletePost" href="#">{{ t.delete_post }}</a></li>
        </ul>
    </div>
    <i v-if="post.pinned" class="pull-right fa fa-thumb-tack wall-post-pinned" :title="t.pinned_post"></i>
    <div v-if="showUser" class="wall-post-user">
        <avatar size="medium" :user="post.user" v-miniprofile="post.user.id"  v-if="post.user" class="avatar-circle"></avatar>
        <div class="wall-post-user-details">
            <div class="wall-post-user-name" v-if="post.user">
                <i class="fa fa-check-square is-verified" v-if="post.user.isVerified"
                :title="gt.verified.replace('%username', post.user.userName)"></i>
                <a :href="post.user.links.profile" v-miniprofile="post.user.id">
                    {{ post.user.userName }}
                </a>
            </div>
            <div class="wall-post-user-date">{{ post.created }}</div>
        </div>
    </div>
    <div v-if="showUser" class="clearfix"></div>
    <div class="wall-post-content">
        <div v-if="mood" class="mood"><i :class="`emote emote-${mood.emoticon}`"></i> {{ t.selected_mood.replace('%mood', mood.title) }} </div>
        <p v-if="!enableEdit || readonly" v-html="post.text"></p>
        <editable v-if="enableEdit && !readonly" v-model="post.text" property="text" type="textbox" :inline="false" :params="ajaxParams"></editable>
        <div class="wall-post-media" v-if="!readonly">
            <media v-for="(media, index) in previewMedia" :key="index" :media="media" @click.native="mediaViewer(index)"
                :card="true" size="large-thumbnail" :private-allowed="true"
                :stats="true">
                <span v-if="enableEdit" class="fa-stack fa-stack-remove fa-lg" @click.stop.prevent="removeMedia(media)">
                    <i class="fa fa-circle fa-stack-2x"></i>
                    <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
                </span>
            </media>
            <div class="wall-post-media-more" v-if="post.medias.length > splitMediaIndex" @click="mediaViewer(splitMediaIndex)">
                <span>+ {{ post.medias.length - splitMediaIndex }} {{ t.more }}</span>
            </div>
        </div>
        <event-card class="wall-post-event" v-if="!readonly && post.event" :event="post.event">
            <span v-if="enableEdit" class="fa-stack fa-stack-remove fa-lg" @click="removeEvent">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
            </span>
        </event-card>
    </div>
    <div class="wall-post-counts" v-if="!readonly">
        {{ post.commentCount }} {{ t.comments }}
        <i class="sh-icon"></i> {{ post.likeCount }} {{ t.likes }}
    </div>
    <div class="wall-post-comments" v-if="!readonly">
        <comments :initial-comments="post.comments" :total-comments="post.commentCount" :show-like-button="true" :likeId="post.isLiked"
            :show-form="true" :form-at-top="true" :small="true" :type="post.commentType" :id="post.id" :upgrade-prompt="!post.canComment"
            ref="comments"></comments>
    </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Avatar from 'Profile/component/Avatar.vue';
import Comments from 'Feedback/component/Comments.vue';
import Editable from 'Site/component/Editable.vue';
import EventCard from 'Parties/component/EventCard.vue';
import Like from 'Feedback/component/Like.vue';
import Media from 'Media/component/Media.vue';

import dropdownDirective from 'Site/directive/dropdown';
import miniprofileDirective from 'Profile/directive/miniprofile';
import reportDirective from 'Abuse/directive/report';

import ajax from 'Site/js/util/ajax.fetch';

export default {
    name: 'Post',
    components: {
        Avatar,
        Comments,
        Editable,
        EventCard,
        Like,
        Media
    },
    computed: {
        previewMedia() {
            return this.post.medias.slice(0, this.splitMediaIndex);
        },
        splitMediaIndex() {
            if (window.innerWidth >= 600) {
                if (this.post.medias.length > 4) {
                    return 3;
                }
                return 4;
            } else if (window.innerWidth >= 470) {
                if (this.post.medias.length > 3) {
                    return 2;
                }
                return 3;
            }
            if (this.post.medias.length > 2) {
                return 1;
            }
            return 2;
        },
        ...mapState({
            ajaxParams(state) {
                return {
                    url: 'wall',
                    id: this.post.id,
                    foreign: state.config.data.id,
                    type: this.post.postType
                };
            },
            gt: state => state.gt.common,
            id: state => state.config.data.id,
            reportData(state) {
                return {
                    component: 'wallPost',
                    componentData: this.post,
                    id: this.post.id,
                    title: state.t.report_post,
                    type: this.post.reportType,
                    user: this.post.user ? this.post.user.id : null
                };
            },
            t: state => state.t
        }),
        mood() {
            return this.post.mood;
        }
    },
    data() {
        return {
            enableEdit: false,
            showAllCommentsButton: this.showAllComments
        };
    },
    directives: {
        dropdown: dropdownDirective,
        miniprofile: miniprofileDirective,
        report: reportDirective
    },
    methods: {
        ...mapActions(['notify', 'showConfirm', 'showMediaViewer']),
        deletePost() {
            this.showConfirm({
                text: this.t.delete_post_confirm,
                callback: () => {
                    ajax.delete('wall', {
                        id: this.post.id,
                        type: this.post.postType
                    }).then((response) => {
                        this.notify({ msg: response.message });
                        this.$emit('removePost', this.post);
                    });
                }
            });
        },
        mediaViewer(start) {
            this.showMediaViewer({ media: this.post.medias, start });
        },
        pinPost(pinned) {
            ajax.update('wall', {
                id: this.post.id,
                foreign: this.id,
                type: this.post.postType,
                pinned,
                text: this.post.text
            }).then((response) => {
                this.notify({ msg: response.message });
                this.post.pinned = pinned;
            });
        },
        removeEvent() {
            this.showConfirm({
                text: this.t.delete_event_confirm,
                callback: () => {
                    this.post.event = null;

                    ajax.update('wall', {
                        id: this.post.id,
                        foreign: this.id,
                        type: this.post.postType,
                        text: this.post.text,
                        media: this.post.medias.map(checkMedia => checkMedia.id),
                        event: null
                    }).then((response) => {
                        this.notify({ msg: response.message });
                    });
                }
            });
        },
        removeMedia(mediaItem) {
            this.showConfirm({
                text: this.t.delete_media_confirm,
                callback: () => {
                    const index = this.post.medias.indexOf(mediaItem);
                    this.post.medias.splice(index, 1);

                    ajax.update('wall', {
                        id: this.post.id,
                        foreign: this.id,
                        type: this.post.postType,
                        text: this.post.text,
                        media: this.post.medias.map(checkMedia => checkMedia.id),
                        event: this.post.event
                    }).then((response) => {
                        this.notify({ msg: response.message });
                    });
                }
            });
        }
    },
    props: {
        displayedOnWall: {
            type: Boolean,
            default: false
        },
        post: Object,
        readonly: {
            type: Boolean,
            default: false
        },
        showUser: {
            type: Boolean,
            default: true
        },
        showAllComments: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.wall-post {
    padding: 10px;
    background-color: #fff;
    @include card;
    .wall-post-actions button i, .wall-create-form button i {
        margin-right: 5px;
    }
    .wall-post-btn {
        @include button-variant(#999, white, white);
    }
    .btn-all-comments {
        margin-top: 5px;
    }
    .event-card {
        margin: 10px 0 0 0;
        .fa-stack-remove {
            position: absolute;
            right: 10px;
            bottom: 10px;
            cursor: pointer;
            .fa-circle {
                color: $brand-danger;
            }
        }
    }
    .media-item-large-thumbnail {
        margin: 5px 0;
    }
}

.wall-post-comments {
    width: 100%;
}

.wall-post-content {
    padding: 10px 0;
    position: relative;
    .mood {
        font-size: 1.2em;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .icon {
        position: absolute;
        left: 0;
        top: -5px;
        transform: rotate(-13deg);
        z-index: 0;
        opacity: 0.045;
    }
}

.wall-post-media {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 0 -10px;
    .gallery-result-details {
        .media-stats {
            font-size: 0.75em;
        }
    }
}

.wall-post-pinned {
    color: #999;
    padding: 5px;
}

.wall-post-media-more {
    width: 150px;
    height: 150px;
    background-color: #eee;
    text-align: center;
    margin: 5px 0;
    cursor: pointer;
    @include card;
    span {
        line-height: 150px;
        font-size: 1.5em;
    }
}

.wall-post-counts {
    &, a {
        color: #999;
    }
    i {
        vertical-align: text-bottom;
        margin-left: 5px;
    }
}

.wall-post-user {
    .profile-result-avatar {
        margin-right:10px;
        float: left;
    }
}
.wall-post-user-details {
    float: left;
}
.wall-post-user-name {
    font-size: 1.4em;
}
.wall-post-user-date {
    color: #999;
}

@media (max-width: $screen-xs-max) {
    .wall-post-counts {
        font-size: 0.9em;
        i {
            width: 13px;
            height: 13px;
        }
    }
}
</style>
