<template>
    <div class="mediaviewer-inner">
        <carousel v-if="show" :class="['mediaviewer-media', !fullWidth ? 'sidebar' : 'full-width']" :slides="media" :start-index="startIndex"
            @beforechange="showNext" @afterchange="autoPlayVideo" @loadmore="loadMore" ref="carousel">
            <div class="mediaviewer-media-wrapper" :class="{ video: slide.set === 'videos' }" slot-scope="{ slide, nextSlide, prevSlide }">
                <div v-if="upgradeMessage" :class="['mediaviewer-upgrade', {'mediaviewer-upgrade-top': upgradeMessageTop}]">
                    <span v-html="upgradeMessage"></span>
                    <a v-if="upgrade.button" href="#" @click.prevent="showUpgrade(upgrade.reason)" @click="pauseVideo" class="site-colour">
                        {{ gt.upgrade_now }}
                    </a>
                </div>
                <media v-show="(!upgradeMessage || (upgradeMessage && upgradeMessageTop))" size="mediaviewer" ref="mediaitems"
                    :lazy-load="current !== slide && current !== nextSlide && current !== prevSlide" :media="slide" :card="false"
                    :stats="false" :adult-allowed="adultAllowed"></media>
            </div>
        </carousel>
        <media-viewer-side v-if="show && !fullWidth" :current="current" :metrics="metrics" ref="sidebar"></media-viewer-side>
    </div>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';
import Carousel from 'Site/component/Carousel.vue';
import Media from 'Media/component/Media.vue';
import logError from 'Site/js/sentry';
import { findIndex } from 'underscore';
import { mapActions, mapState } from 'vuex';
import MediaViewerSide from './MediaViewerSide.vue';

export default {
    name: 'MediaViewer',
    components: {
        Carousel,
        Media,
        MediaViewerSide
    },
    computed: {
        ...mapState({
            gt: state => state.gt.mediaviewer,
            permissions: state => state.siteConfig.data.permissions,
            showVerifyAgePrompt: state => state.siteConfig.data.showVerifyAgePrompt
        }),
        upgradeMessageTop() {
            if (!this.current) {
                return false;
            }
            // Pin upgrade to top if message is an upgrade prompt and not the adult upgrade prompt
            return this.upgrade.button && !(this.current.adult && !this.permissions.adult && !this.adultAllowed);
        },
        upgradeMessage() {
            if (!this.current) {
                return '';
            }
            if (this.current.set === 'videos') {
                if (this.current.private && !this.current.media) {
                    return this.gt.private_video;
                }
                if (this.current.adult && this.showVerifyAgePrompt) {
                    return this.gt.verify_age_for_adult_videos;
                }
                if (this.current.adult && !this.permissions.adult && !this.adultAllowed) {
                    return this.gt.upgrade_for_adult_photos;
                }
                if (!this.permissions.verified) {
                    return this.gt.verify_email_for_videos;
                }
                if (!this.permissions.hdVideos) {
                    return this.gt.upgrade_for_hd_videos;
                }
            }
            if (this.current.private && !this.current.media) {
                return this.gt.private_photo;
            }
            if (this.current.adult && this.showVerifyAgePrompt) {
                return this.gt.verify_age_for_adult_photos;
            }
            if (this.current.adult && !this.permissions.adult && !this.adultAllowed) {
                return this.gt.upgrade_for_adult_photos;
            }
            if (!this.permissions.verified) {
                return this.gt.verify_for_large_photos;
            }
            if (!this.permissions.xxlargePhotos) {
                return this.gt.upgrade_for_extra_large_photos;
            }
            if (!this.permissions.xlargePhotos) {
                return this.gt.upgrade_for_large_photos;
            }
            return '';
        }
    },
    data() {
        return {
            current: {},
            currentPage: this.page,
            show: false,
            startIndex: 0,
            upgrade: {
                reason: '',
                button: false
            },
            xlarge: false
        };
    },
    methods: {
        ...mapActions(['showUpgrade']),
        autoPlayVideo() {
            this.$nextTick(() => {
                const $videoPlayer = document.querySelector('.active video');
                if ($videoPlayer && $videoPlayer.src.indexOf('.jpg') === -1) {
                    const result = $videoPlayer.play();
                    if (result) {
                        result.catch((error) => {
                            if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
                                error.collection = this.current.id;
                                error.mediaUrl = $videoPlayer.src;
                                logError('Could not play video', 'mediaviewer-video', error);
                            }
                        });
                    }
                }
            });
        },
        detectSizes() {
            if (window.innerHeight > 500 && window.innerWidth > 800) {
                this.media.forEach((item) => {
                    if (item.media['xlarge-watermarked'] || item.media['xxlarge-watermarked'] || item.media['video-720'] ||
                        item.media['video-1080']) {
                        this.xlarge = true;
                    }
                });
                if (this.xlarge) {
                    this.$parent.$emit('setclasses', 'mediaviewer xlarge');
                    if (this.$refs.mediaitems.length) {
                        this.$refs.mediaitems.forEach(mediaItem => mediaItem.$emit('reset'));
                    }
                }
            }
        },
        keypress(event) {
            const $videoPlayer = document.querySelector('.active video');
            switch (event.keyCode) {
                case 37: // Left
                    this.$refs.carousel.prev();
                    break;
                case 39: // Right
                    this.$refs.carousel.next();
                    break;
                case 27: // Esc
                    this.$parent.$emit('hideModal');
                    break;
                case 32: // Space
                    if ($videoPlayer) {
                        if ($videoPlayer.paused) {
                            const result = $videoPlayer.play();
                            if (result) {
                                result.catch((error) => {
                                    logError('Could not play video', 'mediaviewer-video', error);
                                });
                            }
                        } else {
                            $videoPlayer.pause();
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        loadMore() {
            if (typeof this.callback === 'function') {
                this.callback('loadmore');
            }
        },
        pauseVideo() {
            const $videoPlayer = document.querySelector('.active video');
            if ($videoPlayer) {
                $videoPlayer.pause();
            }
        },
        sendView(id) {
            if (typeof id !== 'undefined') {
                ajax.head('media', { id });
            }
        },
        setUpgradeMessages() {
            this.upgrade.button = false;
            this.upgrade.reason = '';
            if (!this.current) {
                return;
            }
            if (this.current.set === 'videos') {
                if (this.current.adult && !this.permissions.adult) {
                    this.upgrade.button = true;
                    this.upgrade.reason = 'view-explicit-video';
                }
                if (!this.permissions.hdVideos) {
                    this.upgrade.button = true;
                    this.upgrade.reason = 'view-hd-video';
                }
            }
            if (this.current.adult && !this.permissions.adult) {
                this.upgrade.button = true;
                this.upgrade.reason = 'view-explicit-photo';
            }
            if (!this.permissions.xxlargePhotos) {
                this.upgrade.button = true;
                this.upgrade.reason = 'view-large-photo';
            }
            if (!this.permissions.xlargePhotos) {
                this.upgrade.button = true;
                this.upgrade.reason = 'view-large-photo';
            }
        },
        showNext(nextSlide) {
            const $videoPlayer = document.querySelector('.active video');
            if ($videoPlayer) {
                $videoPlayer.pause();
            }
            this.current = this.media[nextSlide];
            this.sendView(this.current.id);
            this.setUpgradeMessages();
            this.$nextTick(() => {
                this.$refs.sidebar.$emit('changemedia');
            });
            if (nextSlide === (this.media.length - 1) && this.callback) {
                this.currentPage += 1;
                this.callback(this.currentPage);
            }
        }
    },
    mounted() {
        this.$on('show', () => {
            document.querySelector('body').addEventListener('keyup', this.keypress);
            this.$parent.$emit('setclasses', 'mediaviewer');
            this.startIndex = this.start;
            // !Number.isInteger(this.start) - not supported in IE
            if (this.start % 1 !== 0) {
                this.startIndex = findIndex(this.media, item => item.id === this.id);
            }
            this.current = this.media[this.startIndex];
            this.sendView(this.current.id);
            this.show = true;

            this.$nextTick(() => {
                this.detectSizes();
                // I'm not insane, we wait 1 tick to render the template, the carousel waits 2 ticks for its content to render in order
                // to detect the width, we then wait 1 extra tick so that the video tag is rendered and ready to play.
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.autoPlayVideo();
                        });
                    });
                }, 1000);
            });

            this.setUpgradeMessages();

            if (this.startIndex > (this.media.length - 1) && this.callback) {
                this.callback();
            }
        });
        this.$on('hide', () => {
            this.show = false;
            this.current = {};
        });
    },
    props: {
        adultAllowed: {
            type: Boolean,
            default: false
        },
        callback: {
            type: Function
        },
        id: {
            type: Number
        },
        media: {
            type: Array,
            required: true
        },
        metrics: {
            type: Object
        },
        page: {
            type: Number
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        start: {
            type: Number
        }
    }
};

</script>

<style lang="scss">
@import "../../../../../../module/application/frontend-src/scss/variables";

.mediaviewer .fa-lock {
    display: none;
}

.mediaviewer .close {
    position: absolute;
    right: 7px;
    z-index: 1;
}

.mediaviewer-inner {
    height: 100%;
}

.mediaviewer-media {
    background-color: #000;
    height: 500px;
    .mediaviewer-media-wrapper {
        top: 50%;
        transform: translateY(-50%);
        position: relative;
    }
    .media-item {
        margin: 0;
        background-color: #000;
        cursor: default;
    }
    &:hover .mediaviewer-prev, &:hover .mediaviewer-next {
        opacity: 1;
    }
    img, video {
        margin: 0 auto;
        max-height: unset;
    }
}
.mediaviewer-media-meta {
    border-bottom: 1px solid #eee;
    padding: 2px 10px;
}
.mediaviewer-prev, .mediaviewer-next {
    position: absolute;
    top: 20%;
    display: block;
    width: 50px;
    height: 55%;
    max-height: 500px;
    padding: 30% 20px 0 20px;
    cursor: pointer;
    color: #fff;
    border: none;
    outline: none;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s;
    text-shadow: 0 1px 3px #000;
}
.mediaviewer-next {
    right: 0;
}
.mediaviewer-upgrade {
    height: 100%;
    color: #fff;
    font-size: 2em;
    text-align: center;
    a {
        display: block;
        font-weight: 400;
        margin-left: 2px;
    }
}
.mediaviewer-upgrade-top {
    position: absolute;
    top: 0;
    z-index: 10;
    background-color: #000;
    width: 100%;
    color: #fff;
    font-size: 1.5em;
    text-align: center;
    line-height: 50px;
    margin-bottom: -50px;
    height: auto;
    padding: 0;
}
.mediaviewer-user {
    background-color: #eee;
    .profile-result-avatar {
        &.medium, &.medium div {
            height: 63px;
            width: 63px;
        }
        float: left;
    }
    .profile-result-details {
        float: left;
        padding: 8px 10px;
        line-height: 23px;
        border: 0;
    }
    .mediaviewer-stats {
        background-color: #fff;
        border-bottom: 1px solid #eee;
        width: 80px;
        padding: 8px 10px;
        line-height: 23px;
        height: 63px;
        float: right;
    }
}

.mediaviewer .modal-body {
    padding: 0;
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .modal.modal-no-header .mediaviewer .close {
        opacity: 1;
        color: #fff;
        font-size: 27px;
    }
}

@media (max-width: $screen-xs-max) {
    .mediaviewer-content {
        .comment-container {
            max-height: 500px;
        }
    }
    .mediaviewer.modal-dialog .modal-body {
        height: 100%;
    }
}
@media (min-width: $screen-md-min) {
    .mediaviewer.modal-dialog {
        width: 942px;
    }
    .mediaviewer-body {
        position: initial;
        width: 300px;
        height: 500px;
        float: left;
        padding: 0;
        .comment-form {
            width: 300px;
            .help-block {
                display: none;
            }
        }
        .comment .comment-form {
            max-width: 100%;
        }
        .comment-container {
            max-height: 10000px;
        }
    }
    .mediaviewer-content {
        height: 285px;
        overflow-y: auto;
    }
    .mediaviewer-inner {
        display: flex;
    }
    .mediaviewer-media {
        width: 640px;
        float: left;
        .media-item, .media-item-mediaviewer, .mediaviewer-media-wrapper {
            height: 100%;
        }
    }
    .mediaviewer.xlarge {
        width: 98% !important;
        max-width: 2300px;
        height: 96%;
        margin-top: 1%;
        margin-bottom: 1%;
        .modal-content {
            width: 100%;
            height: 100%;
        }
        .modal-body, .mediaviewer-inner {
            height: 100%;
        }
        .mediaviewer-media {
            height: 100%;
            &.sidebar {
                width: calc(100% - 300px);
            }
            &.full-width {
                width: 100%;
            }
        }
        .mediaviewer-body {
            height: calc(100% - 129px);
        }
        .mediaviewer-content {
            height: calc(100% - 85px);
        }
        .mediaviewer-prev, .mediaviewer-next {
            padding-top: 15%;
        }
        .mediaviewer-media-meta {
            width: 100%;
        }
        img {
            max-height: 100%;
        }
        video {
            max-height: 90%;
            max-width: 100%;
        }
    }
}
</style>
