/* eslint max-len: ["error", 1500] */
import Icon from 'vue-awesome/components/Icon.vue';

/**
 * Note: These icons don't require the @import method to be called. They're globally available
 * for use without it. We're going to want to keep this file as slim as possible, excessive SVG's
 * is just going to be messy.
 */
export default {
    import() {
        Icon.register({
            'regular/bullseye-arrow': {
                width: 496,
                height: 512,
                d: 'M305.05 98.74l16.57 49.7-90.59 90.59c-9.38 9.38-9.38 24.56 0 33.94 9.37 9.37 24.56 9.38 33.94 0l90.59-90.59 49.7 16.57c7.39 2.46 15.53.54 21.04-4.96l63.67-63.67c10.8-10.8 6.46-29.2-8.04-34.04l-55.66-18.55-18.55-55.65c-4.83-14.5-23.23-18.84-34.04-8.04L310.02 77.7a20.582 20.582 0 0 0-4.97 21.04zM248 152c7.66 0 15.08.96 22.27 2.54l14.74-14.74-10.32-30.95c-.24-.73-.2-1.47-.41-2.21-8.57-1.5-17.28-2.65-26.29-2.65-84.02 0-152 68-152 152 0 84.02 68 152 152 152 84.02 0 152-68 152-152 0-9.03-1.15-17.75-2.65-26.34-.72-.21-1.49-.12-2.2-.36l-30.94-10.31-14.74 14.74c1.58 7.19 2.53 14.61 2.53 22.27 0 57.35-46.65 104-104 104s-104-46.65-104-104S190.65 152 248 152zm236.43 29.1l-35.5 35.5c-1.34 1.34-2.87 2.38-4.32 3.55 2.12 11.65 3.39 23.59 3.39 35.84 0 110.28-89.72 200-200 200s-200-89.72-200-200 89.72-200 200-200c12.34 0 24.37 1.28 36.1 3.43 1.16-1.42 1.98-3.04 3.3-4.36l35.5-35.5A248.155 248.155 0 0 0 248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248c0-26.11-4.09-51.26-11.57-74.9z'
            },
            'comment-exclamation': {
                width: 512,
                height: 512,
                d: 'M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32zm0 336c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm25.4-110.4c-.8 8.2-7.7 14.4-15.9 14.4h-19c-8.2 0-15.1-6.2-15.9-14.4l-12.8-128c-.9-9.4 6.5-17.6 15.9-17.6h44.6c9.5 0 16.9 8.2 15.9 17.6l-12.8 128z'
            }
        });
    },
};
