<template>
    <div id="notifications">
        <notify v-for="notify in notifies" :key="notify.id" v-on:remove="remove(notify)" :buttons="notify.buttons" :id="notify.id" :msg="notify.msg"
        :type="notify.type" :url="notify.url" :user="notify.user" :callback="notify.callback" :fade="notify.fade"></notify>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Notify from './Notify.vue';

export default {
    name: 'Notifies',
    components: {
        Notify
    },
    computed: mapState(['notifies']),
    methods: {
        remove(notifyItem) {
            this.$store.commit('REMOVE_NOTIFY', notifyItem);
        }
    }
};

</script>
