<template>
    <div class="payvia-options">
        <div :class="['package', upgradeService.activeKey]">
            <span class="title">{{ upgradeService.title }}</span>
            <span class="cost" v-html="upgradeService.cost"></span>
            <span>{{ upgradeService.costInfo }}</span>
        </div>
        <div class="recurring-cost card-cost" v-if="upgradeService.recurringCost" v-html="recurringInfo" />
        <div class="payment-options">
            <span>{{ t.pay_with }}</span>
            <button class="cards" @click.prevent="submit('inline')">{{ t.card }} <img
                :alt="t.alt_mastercard_visa" :src="cardLogos" height="30"></button>
            <span>{{ t.or }}</span>
            <button class="paypal" @click.prevent="submit('form')"><img alt="PayPal" :src="payPalLogo" height="30"></button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SitePayViaOptions',
    computed: {
        cardLogos() {
            return this.localConfig ? this.localConfig.data.cardLogos : '';
        },
        payPalLogo() {
            return this.localConfig ? this.localConfig.data.payPalLogo : '';
        },
        recurringInfo() {
            if (!this.upgradeService.recurringCost) {
                return '';
            }

            if (this.upgradeService.recurringPaymentAmount && this.upgradeService.recurringPaymentAmount !== this.upgradeService.paymentAmount) {
                // recurring package price is different to initial payment
                return this.t.payment_form.recurring_title.replace('%cost', this.upgradeService.cost).replace('%period', this.upgradeService.period)
                    .replace('%recurringCost', this.upgradeService.recurringCost)
                    .replace('%duration', this.upgradeService.paymentServiceLengthText);
            }

            return `${this.upgradeService.cost} ${this.upgradeService.costInfo}`;
        },
        t() {
            return this.localConfig.translate;
        }
    },
    methods: {
        submit(method) {
            this.upgradeService.gateways.forEach((serviceGateway) => {
                if (serviceGateway.gateway.type === method) {
                    this.$emit('service-gateway', { upgradeService: this.upgradeService, serviceGateway });
                }
            });
        }
    },
    props: {
        localConfig: {
            type: Object,
            required: true
        },
        upgradeService: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss">
.payvia-options {
    max-width: 350px;
    margin: 0 auto;
    font-size: 1.4em;
    .package {
        margin-top: 20px;
        border-radius: 3px;
        display: flex;
        color: #fff;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        height: 200px;
        padding: 10px;
        .title {
            font-size: 1.4em;
        }
        .cost {
            font-size: 4.2em;
            small {
                font-size: 54%;
                position: relative;
                bottom: 12px;
                left: 2px;
            }
        }
        &.premium {
            background: #4297c1;
        }
        &.extras {
            background: #35af49;
        }
    }
    .recurring-cost {
        margin-top: 20px;
        font-size: 0.8em;
        text-align: center;
    }
    .payment-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-around;
        margin: 48px auto;
        button {
            height: 60px;
            line-height: 30px;
            border-radius: 3px;
            font-size: 1.2em;
            font-weight: 800;
            width: 100%;
            border: solid 1px #35af49;
            color: #333;
            background: transparent;
            margin: 15px auto;
            &.cards {
                img {
                    margin: -3px 0 0 10px;
                }
            }
            &:hover {
                box-shadow: 0px 0px 2px #35af49;
            }
        }
    }
}
</style>