<template>
    <form id="payment-form" autocomplete="on" @submit.prevent="submit">
        <div class="description">
            <span class="card-cost" v-html="selectedServiceName"></span>
        </div>
        <div class="payment-card row">
            <div class="col-sm-12 form-group" :class="{ 'has-error': validationFailed.number }">
                <label for="payment-card-number" class="payment-card-number-label">{{ t.card_number }}</label>
                <input v-model="paymentCard.number" @input="inputValidate('number')" type="text" class="form-control" placeholder="0000 0000 0000 0000" autocomplete="cc-number" required>
            </div>
            <div class="col-sm-7" :class="{ 'has-error': validationFailed.name }">
                <label for="payment-card-name">{{ t.card_name }}</label>
                <input v-model="paymentCard.name" @input="inputValidate('name')" type="text" class="form-control" :placeholder="t.name_on_card" autocomplete="cc-name" required>
            </div>
            <div class="col-sm-5 form-group" :class="{ 'has-error': validationFailed.expiry }">
                <label class="payment-card-expiry-label">{{ t.expiry_date }}</label>
                <input v-model="paymentCard.expiry" @input="inputValidate('expiry')" type="text" class="form-control" placeholder="mm/yy" autocomplete="cc-exp" required>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="payment-card-cvv2">
            <div class="payment-card-cvv2-stripe"></div>
            <div class="payment-card-cvv2-input-wrapper form-group" :class="{ 'has-error': validationFailed.cvv2 }">
                <label for="payment-card-cvv2" class="payment-card-cv2-label">{{ t.cvv }}</label>
                <input v-model="paymentCard.cvv2" type="text" class="form-control" placeholder="123" autocomplete="off">
            </div>
        </div>
        <div class="payment-form-button-wrapper">
            <span v-if="showValidationMessage" class="validation text-danger">{{ gt.check_details }}</span>
            <button type="submit" class="btn btn-success btn-lg paynow-btn" :disabled="processing === true">{{ payNowButton }}</button>
            <img :src="secureImage">
        </div>
        <div>
            <!-- Upgrade icons -->
            <div class="row upgrade-icons-set">
                <div class="upgrade-icons">
                    <div class="icon">
                        <i class="fa fa-lock fa-2x"></i> <span>{{ t.secure }}</span>
                        <p>{{ t.secure_encryption }}</p>
                    </div>
                </div>
                <div class="upgrade-icons">
                    <div class="icon">
                        <i class="fa fa-user-secret fa-2x"></i> <span>{{ t.discrete_billing }}</span>
                        <p>{{ t.billed_as_symbios }}</p>
                    </div>
                </div>
                <div class="upgrade-icons">
                    <div class="icon">
                        <i class="fa fa-ban fa-2x"></i> <span>{{ t.cancel_anytime }}</span>
                        <p>{{ t.no_contract }}</p>
                    </div>
                </div>
                <div class="upgrade-icons">
                    <div class="icon">
                        <i class="fa fa-users fa-2x"></i> <span>{{ t.trusted_loved }}</span>
                        <p>{{ t.millions_users_globally }}</p>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="row">
            <p class="text-center muted-text col-xs-12">{{ t.secure_card_detail_processing }}</p>
            <p class="text-center muted-text col-xs-12">{{ symbiosAddress }}</p>
        </div>
    </form>
</template>

<script>
import Payment from 'payment';
import { mapActions, mapState } from 'vuex';
import ajax from 'Site/js/util/ajax.fetch';
import Form from 'Site/js/util/form';
import { logAccountUpgrade } from '../js/util/metrics';
import logErrorMessage from '../js/sentry';
import store from '../js/store';

export default {
    name: 'SitePaymentForm',
    store,
    data() {
        return {
            failedAttempts: 0,
            paymentCard: new Form({
                number: '',
                name: '',
                expiry: '',
                cvv2: ''
            }, {
                number: ['required'],
                name: ['required'],
                expiry: ['required']
            }),
            processing: false,
            submitted: false,
            serviceGateway: this.selectedService.serviceGateway,
            statData: {
                stats_payload: {
                    type: 'upgradeCardView',
                    user: this.$store.state.siteConfig.user.id,
                    attributes: this.statAttributes
                }
            },
            supportedPaymentMethods: [
                {
                    supportedMethods: 'basic-card',
                    data: {
                        supportedNetworks: ['visa', 'mastercard'],
                        supportedTypes: ['credit', 'debit']
                    }
                }
            ],
            upgradeService: this.selectedService.upgradeService.service ? this.selectedService.upgradeService.service : this.selectedService.upgradeService,
            validationFailed: {
                number: false,
                name: false,
                expiry: false,
                cvv2: false
            },
            showValidationMessage: false
        };
    },
    computed: {
        ...mapState({
            gt: state => state.gt.upgrade,
            threedFormUrl: state => state.siteConfig.pages.threeDSecure
        }),
        payNowButton() {
            return !this.processing ? this.t.pay_now : this.gt.processing;
        },
        secureImage() {
            return this.localConfig.data.secureImage;
        },
        selectedServiceName() {
            if (this.upgradeService.recurringPaymentAmount && this.upgradeService.recurringPaymentAmount !== this.upgradeService.paymentAmount) {
                // recurring package price is different to initial payment
                return this.t.recurring_title.replace('%cost', this.upgradeService.cost).replace('%period', this.upgradeService.period)
                    .replace('%recurringCost', this.upgradeService.recurringCost)
                    .replace('%duration', this.upgradeService.paymentServiceLengthText);
            }
            return `${this.upgradeService.cost} ${this.upgradeService.costInfo}`;
        },
        symbiosAddress() {
            return this.localConfig.data.symbiosAddress;
        },
        t() {
            return this.localConfig.translate.payment_form;
        }
    },
    methods: {
        ...mapActions(['notify']),
        clearForm() {
            this.paymentCard = {
                number: '',
                name: '',
                expiry: '',
                cvv2: ''
            };
        },
        paymentFailed() {
            this.failedAttempts++;
            if (this.failedAttempts >= 2) {
                this.clearForm();
                this.failedAttempts = 0;
            }
            this.processing = false;
        },
        submit() {
            this.submitted = true;
            this.statData.stats_payload.attributes.upgrade_reason = this.userUpgradeReason;
            this.statData.stats_payload.attributes.activity_action = this.activityAction;
            this.sendStats(this.statData);
            if (this.inputValidate()) {
                const cardType = Payment.fns.cardType(this.paymentCard.number);
                this.toggleError('number', Payment.fns.validateCardNumber(this.paymentCard.number));
                this.toggleError('expiry', Payment.fns.validateCardExpiry(Payment.fns.cardExpiryVal(this.paymentCard.expiry)));
                if (!this.$store.state.siteConfig.cv2Optional) {
                    this.toggleError('cvv2', Payment.fns.validateCardCVC(this.paymentCard.cvv2, cardType));
                }

                this.showValidationMessage = false;
                Object.keys(this.validationFailed).forEach((input) => {
                    // if any validationFailed remains true show message
                    if (this.validationFailed[input]) {
                        this.showValidationMessage = true;
                    }
                });

                if (this.showValidationMessage === true) {
                    return;
                }

                // hide previous payment failed message if shown
                this.$emit('payment-failed', { status: false });

                const expiry = Payment.fns.cardExpiryVal(this.paymentCard.expiry);
                this.makePayment(
                    this.serviceGateway.id,
                    this.paymentCard.number,
                    this.paymentCard.name,
                    expiry,
                    this.paymentCard.cvv2,
                    cardType,
                    null, // billingAddress
                    null, // billingPostcode
                    this.activityAction,
                    this.userUpgradeReason,
                    this.actor
                );
            } else {
                this.notify({ msg: this.t.all_fields_required, type: 'error' });
            }
        },
        paymentForm(reason, action, actor, amount, currency, detailText, text, serviceGateway) {
            // show payment input form regardless of stored card
            this.fallbackMethod(reason, action, actor, serviceGateway);
            // Check browser supports PaymentRequest and that the user has a card setup
            if (window.PaymentRequest) {
                let paymentRequest = {};
                try {
                    paymentRequest = new window.PaymentRequest(this.supportedPaymentMethods, {
                        total: {
                            label: 'check',
                            amount: { currency, value: amount }
                        }
                    });
                } catch (e) {
                    logErrorMessage('payment-request', 'payment', {
                        e,
                        data: {
                            amount: { currency, value: amount }
                        }
                    })
                }
                let canMakePaymentPromise = Promise.resolve();
                if (paymentRequest.canMakePayment) {
                    canMakePaymentPromise = paymentRequest.canMakePayment();
                }
                canMakePaymentPromise.then((result) => {
                    if (result) {
                        this.paymentRequestMethod(reason, action, actor, amount, currency, detailText, serviceGateway);
                    }
                });
            }
        },
        paymentRequestMethod(reason, action, actor, amount, currency, detailText, serviceGateway) {
            const displayItems = [];
            if (detailText) {
                displayItems.push({
                    label: detailText,
                    amount: {
                        currency,
                        value: 0
                    }
                });
            }
            const paymentDetails = {
                total: {
                    label: this.t.total,
                    amount: {
                        currency,
                        value: amount
                    }
                },
                displayItems
            };

            const options = {};

            /* eslint no-new: 0 */
            const request = new window.PaymentRequest(
                this.supportedPaymentMethods,
                paymentDetails,
                options
            );

            // Process card payment from card set within browser
            request.show().then(paymentResponse => paymentResponse.complete().then(() => {
                const { details } = paymentResponse;
                const cardType = Payment.fns.cardType(details.cardNumber);
                const expiry = { month: details.expiryMonth, year: details.expiryYear };
                this.makePayment(
                    serviceGateway,
                    details.cardNumber,
                    details.cardholderName,
                    expiry,
                    details.cardSecurityCode,
                    cardType,
                    details.billingAddress.addressLine[0],
                    details.billingAddress.postalCode,
                    action,
                    reason,
                    actor
                );
            }));
        },
        makePayment(serviceGateway, number, name, expiry, cvv2, type, address, postcode, action, reason, actor) {
            this.statData.stats_payload.type = 'upgradeCardSubmit';
            this.sendStats(this.statData);

            // set buy button status
            this.processing = true;

            ajax.create('gatewayPayment', {
                serviceGateway,
                number,
                name,
                expiry,
                cvv2,
                type,
                address,
                postcode,
                statAttributes: this.statAttributes
            }).then((data) => {
                if (data.gateway_result) {
                    const gatewayData = data.gateway_result.data;
                    switch (data.gateway_result.result) {
                        default:
                        case 'OK':
                            if (action) {
                                logAccountUpgrade(reason, action, actor);
                            }
                            this.paymentSuccess(data.payment);
                            break;
                        case 'INVALID':
                        case 'REJECTED':
                            this.$emit('payment-failed', { status: true, reference: data.payment.reference });
                            this.paymentFailed();
                            break;
                        case '3DAUTH':
                            const secureForm = document.querySelector('iframe#payment-secure').contentWindow.document.body;

                            // set values for 3d secure iframe
                            secureForm.querySelector('#payment-form-secure').setAttribute('action', gatewayData.issuerUrl);
                            secureForm.querySelector('#payment-form-PaReq').setAttribute('value', gatewayData.paReq);
                            secureForm.querySelector('#payment-form-md').setAttribute('value', gatewayData.md);
                            secureForm.querySelector('#payment-form-secure').submit();

                            // show 3d secure iframe
                            this.$emit('payment-3dsecure');

                            // CustomEvent set via threed-secure.phtml
                            document.addEventListener('payment3DSecureRes', (e) => {
                                const data = JSON.parse(e.detail);
                                switch (data.payment.status) {
                                    case 1:
                                        if (action) {
                                            logAccountUpgrade(reason, action, actor);
                                        }
                                        this.paymentSuccess(data.payment);
                                        break;
                                    default:
                                        this.$emit('payment-failed', { status: true, reference: data.payment.reference });
                                        this.paymentFailed();
                                        // refresh 3dSecure iframe incase of retry
                                        const iframeRefresh = document.createElement('iframe');
                                        iframeRefresh.setAttribute('src', this.threedFormUrl);
                                        iframeRefresh.setAttribute('id', 'payment-secure');
                                        document.querySelector('#payment-secure').replaceWith(iframeRefresh);
                                }
                            });
                            break;
                    }
                }
            }).catch((error) => {
                if (error.response) {
                    error.response.then((response) => {
                        if (response.payment && response.payment.callbackValue === 'Duplicate payment.') {
                            this.paymentSuccess();
                            return;
                        }
                    });
                }
                this.paymentFailed();
                this.$emit('payment-failed', { status: true, reference: '' });
            })
        },
        fallbackMethod(reason, action, actor, serviceGateway) {
            Payment.formatCardNumber(this.paymentCard.number);
            Payment.formatCardExpiry(this.paymentCard.expiry);
            Payment.formatCardCVC(this.paymentCard.cvv2);

            this.submit;
        },
        paymentSuccess(response = null) {
            const successInfo = {
                cost: this.upgradeService.cost,
                createdAt: response ? response.createdAt : null,
                name: response ? response.service.name : this.upgradeService.name,
                recurringCost: this.upgradeService.recurringCost,
                reference: response ? response.reference : null
            };
            this.$emit('payment-success', successInfo);
        },
        toggleError(element, boolean) {
            if (boolean) {
                // do not show error highlight
                this.validationFailed[element] = false;
            } else {
                this.validationFailed[element] = true;
            }
        },
        sendStats(data) {
            ajax.create('statserviceApi', data);
        },
        inputValidate(field) {
            if (this.submitted) {
                return this.paymentCard.validate(field);
            }
            return true;
        }
    },
    mounted() {
        const paymentRequestText = '';
        const text = '';

        this.paymentForm(
            this.userUpgradeReason,
            this.activityAction,
            this.actor,
            this.upgradeService.paymentAmount,
            this.upgradeService.currencyCode,
            paymentRequestText,
            text,
            this.serviceGateway.id
        );
    },
    props: {
        activityAction: {
            type: String,
            default: ''
        },
        forceGateway: {
            type: Number,
            default: null
        },
        localConfig: {
            type: Object,
            requred: true
        },
        selectedService: {
            type: Object,
            required: true
        },
        statAttributes: {
            type: Object,
            default: {}
        },
        actor: {
            type: String,
            default: ''
        },
        userUpgradeReason: {
            type: String,
            default: ''
        }
    }
};
</script>
