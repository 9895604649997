import { throttle } from 'underscore';
import Vue from 'vue';
import { mapState } from 'vuex';
import Notifications from 'Activity/component/Notifications.vue';

import { getStorage, setStorage } from '../js/util/storage';
import collapseDirective from '../directive/collapse';
import dropdownDirective from '../directive/dropdown';
import { registerLogoutClickHandler } from '../js/util/authenticator';
import store from '../js/store';
import analytics from '../js/util/analytics';

export default function () {

    new Vue({ // eslint-disable-line no-new
        el: '#siteHeader',
        name: 'SiteHeader',
        store,
        components: {
            Notifications
        },
        computed: {
            ...mapState(['isMobile']),
            ...mapState({
                t: state => state.gt.notifications
            })
        },
        data() {
            return {
                a2hs: {
                    show: false,
                    event: null
                },
                accountMenuPosition: 'mobile',
                classes: '',
                notificationCount: this.$store.state.siteConfig.data.notificationCount
            };
        },
        directives: {
            collapse: collapseDirective,
            dropdown: dropdownDirective
        },
        methods: {
            setHeightOnScroll() {
                if ((typeof window.scrollY === 'undefined' ? window.pageYOffset : window.scrollY) > 1 && !this.isMobile) {
                    this.classes = 'navbar-small';
                } else {
                    this.classes = '';
                }
            },
            showJoin() {
                analytics('header-join-button', 'clicked by user', null, null);
                this.$store.dispatch('showJoin');
            },
            moveAccountMenu() {
                const $desktopAccountMenu = document.querySelector('#desktop-account-menu');
                const $mobileAccountMenu = document.querySelector('#bs-navbar-account');
                const $accountMenu = document.querySelector('#account-menu');

                if (!$accountMenu) {
                    return;
                }

                // Move from mobile to desktop
                if (this.accountMenuPosition === 'mobile' && !this.isMobile) {
                    $desktopAccountMenu.innerHTML = $accountMenu.outerHTML;
                    $mobileAccountMenu.innerHTML = '';
                    this.accountMenuPosition = 'desktop';
                    registerLogoutClickHandler();
                }
                // Move from desktop to mobile
                if (this.accountMenuPosition === 'desktop' && this.isMobile) {
                    $desktopAccountMenu.innerHTML = '';
                    $mobileAccountMenu.innerHTML = $accountMenu.outerHTML;
                    this.accountMenuPosition = 'mobile';
                    registerLogoutClickHandler();
                }
            },
            showA2hs() {
                this.a2hs.show = false;
                this.a2hs.event.prompt();
                this.a2hs.event.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'dismissed') {
                        setStorage('homescreenprompt', 'dismissed', true);
                    }
                });
                this.a2hs.event = null;
            },
            toggleNotifications() {
                this.$refs.notifications.$emit('toggle');
            }
        },
        mounted() {
            const throttledScrollHandler = throttle(this.setHeightOnScroll, 250);
            window.addEventListener('scroll', throttledScrollHandler);
            throttledScrollHandler();

            const throttledMoveAccountMenu = throttle(this.moveAccountMenu, 250);
            window.addEventListener('resize', throttledMoveAccountMenu);
            throttledMoveAccountMenu();

            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();

                if (getStorage('homescreenprompt', true) !== 'dismissed') {
                    this.a2hs.show = true;
                    this.a2hs.event = e;
                }
            });
        }
    });
}
