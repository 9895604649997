import $ from 'jquery';
import Vue from 'vue';
import ES6Promise from 'es6-promise';
import Comments from 'Feedback/component/Comments.vue';
import MessengerInit from 'Messenger/js/init';

import banner from './util/banner';
import { initErrorLogging } from './sentry';
import { login } from './util/authenticator';
import store from './store';
import SiteHeader from '../component/SiteHeader';

export default function () {

    /**
     * @deprecated use showJoin Vuex action directly
     */
    $('#joinUpgrade').on('click', () => {
        store.dispatch('showJoin');

        return false;
    });

    /**
     * Used for Adverts on Lush Stories.
     * Needs refactor from jQuery to native.
     */
    $('body').on('click', '.show-join', () => {
        store.dispatch('showJoin');

        return false;
    });

    login('silent');

    if ($('#mainNavBar').length) {
        SiteHeader();
    }

    if (store.state.siteConfig.enableMessenger) {
        MessengerInit();
    }
    initErrorLogging();

    /**
     * @deprecated use wysiwyg component directly
     */
    if (typeof MutationObserver !== 'undefined') {
        $('.wysibb-add').each(function wysiwyg() {
            /* eslint no-console: 0 */
            console.error('use Wysiwyg component', this);
        });
    }

    /**
     * @deprecated use datePicker component directly
     */
    const dateTypeSupport = document.createElement('input');
    dateTypeSupport.setAttribute('type', 'date');
    if (dateTypeSupport.type === 'text') {
        ES6Promise.polyfill();
        import(/* webpackChunkName: "daterangepicker" */'bootstrap-daterangepicker').then(() => {
            $('input[type="date"]').each((index, element) => {
                const $el = $(element);
                $el.daterangepicker({
                    singleDatePicker: true,
                    startDate: $el.val() || new Date().toISOString().slice(0, 10),
                    showDropdowns: true,
                    minDate: '1910-01-01',
                    locale: {
                        format: 'YYYY-MM-DD'
                    }
                });
            });
        });
    }

    if (document.querySelector('#page-comments') && !document.querySelector('#page-comments.page-comments-skip')) {
        new Vue({ // eslint-disable-line no-new
            el: '#page-comments',
            components: {
                Comments
            },
            store
        });
    }

    /**
     * @deprecated use sendMessage Vuex action directly
     */
    $('body').on('click', '.send-message-modal', (event) => {
        const attributes = $(event.currentTarget).data();
        store.dispatch('sendMessage', attributes.username);
        return false;
    });

    /**
     * @deprecated use showUpgrade Vuex action directly
     * @todo convert to Vue
     */
    $('body').on('click', '.upgrade-notice-modal', function upgradeClick() {
        const attributes = $(this).data();
        store.dispatch('showUpgrade', { reason: attributes.upgradeReason });

        return false;
    });

    const $upgradeButtons = document.querySelectorAll('.show-upgrade');
    /* eslint no-loop-func: 0 */
    for (let i = 0; i < $upgradeButtons.length; i += 1) {
        $upgradeButtons[i].addEventListener('click', (e) => {
            const $el = e.currentTarget;
            store.dispatch('showUpgrade', { reason: $el.dataset.reason });
            return false;
        });
    }

    /**
     * @deprecated use miniprofile directive
     */
    $('.mini-profile-trigger').each(function miniProfile() {
        const el = this;

        if (window.innerWidth < 768) {
            return;
        }

        el.addEventListener('mouseover', (e) => {
            /* eslint no-underscore-dangle: 0 */
            document.querySelector('#vue-wrapper').__vue__.$refs.miniprofile.show(e, $(el).data('id'));
        });
        el.addEventListener('mouseleave', (e) => {
            document.querySelector('#vue-wrapper').__vue__.$refs.miniprofile.hide(e);
        });
    });

    banner('#upgradeBanner.early-renewal');

    /**
     * @deprecated use showSiteInvite Vuex action directly
     */
    $('body').on('click', '#show-site-invite-modal', (e) => {
        e.preventDefault();
        store.dispatch('showSiteInvite');
    });

    const $loginButtons = document.querySelectorAll('.login-button');
    for (let i = 0; i < $loginButtons.length; i += 1) {
        $loginButtons[i].addEventListener('click', (e) => {
            e.preventDefault();
            login();
        });
    }

    /* Used by avatar.phtml, use openChat directive for Vue */
    $(document).on('click', 'a.openChat', function openMessengerHandler(event) {
        event.preventDefault();
        window.location.href = `${store.state.siteConfig.pages.chat}?room=${$(this).attr('data-room')}`;
    });

    /**
     *
     */

    if (store.state.siteConfig.siteType === 'story') {

        const ads = document.querySelectorAll('.ad');
        const backups = document.querySelectorAll('.support-us');
        let i;
        let x;

        for (i = 0; i < ads.length; i += 1) {
            for (x = 0; x < backups.length; x += 1) {
                if (ads[i].offsetHeight === 0) {
                    backups[x].style.display = 'block';
                }
            }
        }
    }
}
