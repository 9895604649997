import { find } from 'underscore';

function Parser(response) {
    this.response = response;
}

Parser.prototype = {
    setResponse(response) {
        this.response = response;
        return this;
    },

    find(key, raw = false) {
        if (Array.isArray(key)) {
            return this.findMultiple(key);
        }

        const result = find(this.response.address_components, component => component.types.indexOf(key) > -1);

        if (result) {
            if (raw) {
                return result;
            }
            return result.long_name;
        }

        return '';
    },

    findMultiple(keys) {
        const result = {};

        keys.forEach((k) => {
            const found = this.find(k);
            if (found) {
                result[k] = found;
            }
        });

        return result;
    },

    getCounty() {
        let county = this.find('administrative_area_level_2');

        if (!county) {
            county = this.find('administrative_area_level_1');
        }

        return county;
    },

    getCountry() {
        return this.find('country');
    },

    getCity() {
        return this.find('postal_town');
    },

    getFormatted() {
        return this.response.formatted_address;
    },

    getLat() {
        return this.response.geometry.location.lat();
    },

    getLong() {
        return this.response.geometry.location.lng();
    },

    getAddressParts() {
        return this.findMultiple(['postal_code',
            'locality',
            'political',
            'postal_town',
            'administrative_area_level_1',
            'administrative_area_level_2',
            'administrative_area_level_3',
            'county',
            'country'
        ]);
    }
};

export default Parser;
