export function list(imagesUrl) {

    const replaceMe = image => imagesUrl.replace('REPLACE_ME', `emoticons/${image}`);

    /* eslint object-curly-newline: 0 */
    return [
        { name: 'smile', src: replaceMe('emote_smile.gif'), unicode: '😊', shortcode: ':)', width: '32', height: '32' },
        { name: 'biggrin', src: replaceMe('emote_grin.gif'), unicode: '😀', shortcode: ':D', width: '32', height: '32' },
        { name: 'cool', src: replaceMe('emote_cool.gif'), unicode: '😎', shortcode: '8-)', width: '32', height: '32' },
        { name: 'wink', src: replaceMe('emote_wink.gif'), unicode: '😉', shortcode: ':wink:', width: '32', height: '32' },
        { name: 'silly', src: replaceMe('emote_silly.gif'), unicode: '😋', shortcode: ':p', width: '32', height: '32' },
        { name: 'lol', src: replaceMe('emote_lol.gif'), unicode: '🤣', shortcode: ':lol:', width: '32', height: '32' },
        { name: 'wave', src: replaceMe('emote_wave.gif'), shortcode: ':wave:', width: '32', height: '32' },
        { name: 'loon', src: replaceMe('loon.gif'), unicode: '😜', shortcode: ':loon:', width: '23', height: '15' },
        { name: 'kiss', src: replaceMe('emote_kiss.gif'), unicode: '😘', shortcode: ':kiss:', width: '32', height: '32' },
        { name: 'redface', src: replaceMe('emote_redface.gif'), unicode: '😳', shortcode: ':oops:', width: '32', height: '32' },
        { name: 'worship', src: replaceMe('emote_worship.gif'), shortcode: ':worship:', width: '32', height: '32' },
        { name: 'sad', src: replaceMe('emote_sad.gif'), unicode: '🙁', shortcode: ':(', width: '32', height: '32' },
        { name: 'cry', src: replaceMe('emote_cry.gif'), unicode: '😢', shortcode: ':\'(', width: '32', height: '32' },
        { name: 'confused', src: replaceMe('emote_confused.gif'), unicode: '😕', shortcode: ':?', width: '32', height: '32' },
        { name: 'surprised', src: replaceMe('emote_surprised.gif'), unicode: '😲', shortcode: ':o', width: '32', height: '32' },
        { name: 'horror', src: replaceMe('emote_horror.gif'), unicode: '😨', shortcode: 'D:&lt;', width: '32', height: '32' },
        { name: 'mad', src: replaceMe('emote_mad.gif'), unicode: '😠', shortcode: ':x', width: '32', height: '32' },
        // { name: 'handup', src: replaceMe('emote_hand.gif'), shortcode: ':hand:', width: '32', height: '32' },
        { name: 'downthere', src: replaceMe('emote_downthere.gif'), shortcode: ':downthere:', width: '32', height: '32' },
        { name: 'blink', src: replaceMe('emote_blink.gif'), unicode: '😒', shortcode: ':blink:', width: '32', height: '32' },
        { name: 'gag', src: replaceMe('emote_gag.gif'), unicode: '🤐', shortcode: ':gag:', width: '32', height: '32' },
        { name: 'banghead', src: replaceMe('banghead.gif'), shortcode: ':banghead:', width: '25', height: '20' },
        { name: 'dunno', src: replaceMe('dunno.gif'), unicode: '😕', shortcode: ':dunno:', width: '37', height: '15' },
        { name: 'innocent', src: replaceMe('whistling.gif'), unicode: '😇', shortcode: ':whistling:', width: '20', height: '20' },
        { name: 'rolleyes', src: replaceMe('icon_rolleyes.gif'), unicode: '🙄', shortcode: ':roll:', width: '15', height: '15' },
        { name: 'neutral', src: replaceMe('icon_neutral.gif'), unicode: '😐', shortcode: ':|', width: '15', height: '15' },
        { name: 'sleep', src: replaceMe('emote_sleep.gif'), unicode: '😴', shortcode: ':sleep:', width: '32', height: '32' },
        { name: 'evil', src: replaceMe('icon_evil.gif'), unicode: '😈', shortcode: ':evil:', width: '15', height: '15' },
        { name: 'yes', src: replaceMe('emote_yes.gif'), shortcode: ':yes:', width: '32', height: '32' },
        { name: 'heart', src: replaceMe('emote_heart.gif'), unicode: '❤', shortcode: '&lt;3', width: '32', height: '32' },
        { name: 'brokenheart', src: replaceMe('emote_brokenheart.gif'), unicode: '💔', shortcode: ':broken:', width: '32', height: '32' },
        { name: 'present', src: replaceMe('emote_present.gif'), unicode: '🎁', shortcode: ':present:', width: '32', height: '32' },
        { name: 'wine', src: replaceMe('emote_wine.gif'), unicode: '🍷', shortcode: ':wine:', width: '32', height: '32' },
        { name: 'drinkies', src: replaceMe('emote_drink.gif'), unicode: '🍺', shortcode: ':drinkies:', width: '32', height: '32' },
        { name: 'coffee', src: replaceMe('emote_coffee.gif'), unicode: '☕', shortcode: ':coffee:', width: '32', height: '32' },
        { name: 'cake', src: replaceMe('emote_cake.gif'), unicode: '🎂', shortcode: ':cake:', width: '32', height: '32' },
        { name: 'flipa', src: replaceMe('flipa.gif'), shortcode: ':flipa:', width: '37', height: '15' },
        { name: 'poke', src: replaceMe('poke.gif'), shortcode: ':poke:', width: '60', height: '15' },
        { name: 'rotflmao', src: replaceMe('rotflmao.gif'), shortcode: ':rotflmao:', width: '39', height: '15' },
        { name: '69position', src: replaceMe('69position.gif'), shortcode: ':69:', width: '29', height: '25' },
        { name: 'boink', src: replaceMe('boink.gif'), shortcode: ':boink:', width: '19', height: '25' },
        { name: 'hump', src: replaceMe('hump.gif'), shortcode: ':hump:', width: '24', height: '20' },
        { name: 'passionkiss', src: replaceMe('passionkiss.gif'), shortcode: ':passionkiss:', width: '37', height: '15' },
        { name: 'blast', src: replaceMe('blast.gif'), shortcode: ':blast:', width: '72', height: '22' },
        { name: 'whip', src: replaceMe('whip.gif'), shortcode: ':whip:', width: '50', height: '15' },
        { name: 'smackbottom', src: replaceMe('smackbottom.gif'), shortcode: ':smackbottom:', width: '49', height: '37' },
        { name: 'duel', src: replaceMe('duel.gif'), shortcode: ':duel:', width: '100', height: '34' },
        { name: 'ok', src: replaceMe('emote_ok.gif'), unicode: '👌', shortcode: ':ok:', width: '32', height: '32' },
        { name: 'bolt', src: replaceMe('emote_bolt.gif'), unicode: '💨', shortcode: ':bolt:', width: '32', height: '32' },

        { name: 'anal_beads', type: 'sprite', unicode: '' },
        { name: 'ass_to_ass', type: 'sprite', unicode: '' },
        { name: 'assless_chaps', type: 'sprite', unicode: '' },
        { name: 'ball_gag', type: 'sprite', unicode: '' },
        { name: 'banana_in_condom', type: 'sprite', unicode: '' },
        { name: 'ben_wa_balls', type: 'sprite', unicode: '' },
        { name: 'black_vibrator', type: 'sprite', unicode: '' },
        { name: 'blindfold', type: 'sprite', unicode: '' },
        { name: 'bound_hands', type: 'sprite', unicode: '' },
        { name: 'butt_plug', type: 'sprite', unicode: '' },
        { name: 'butthole', type: 'sprite', unicode: '' },
        { name: 'chocolate_strawberry', type: 'sprite', unicode: '' },
        { name: 'chocolate_syrup', type: 'sprite', unicode: '' },
        { name: 'cucumber_condom', type: 'sprite', unicode: '' },
        { name: 'double_strap_on', type: 'sprite', unicode: '' },
        { name: 'dream_bed', type: 'sprite', unicode: '' },
        { name: 'feather', type: 'sprite', unicode: '' },
        { name: 'flickin', type: 'sprite', unicode: '' },
        { name: 'four_poster', type: 'sprite', unicode: '' },
        { name: 'fuzzy_handcuffs', type: 'sprite', unicode: '' },
        { name: 'gimp_mask', type: 'sprite', unicode: '' },
        { name: 'glass_dildo', type: 'sprite', unicode: '' },
        { name: 'gushing', type: 'sprite', unicode: '' },
        { name: 'gymshorts_boner', type: 'sprite', unicode: '' },
        { name: 'handcuffs', type: 'sprite', unicode: '' },
        { name: 'heart_boxers', type: 'sprite', unicode: '' },
        { name: 'hot_tub', type: 'sprite', unicode: '' },
        { name: 'hotel', type: 'sprite', unicode: '' },
        { name: 'ice_cube', type: 'sprite', unicode: '' },
        { name: 'jerkin', type: 'sprite', unicode: '' },
        { name: 'lavendar_lingerie', type: 'sprite', unicode: '' },
        { name: 'leather_boots', type: 'sprite', unicode: '' },
        { name: 'leather_daddy', type: 'sprite', unicode: '' },
        { name: 'leather_lingerie', type: 'sprite', unicode: '' },
        { name: 'licking_lips', type: 'sprite', unicode: '' },
        { name: 'nipple_clamp', type: 'sprite', unicode: '' },
        { name: 'one_piece', type: 'sprite', unicode: '' },
        { name: 'open_condom', type: 'sprite', unicode: '' },
        { name: 'paddle', type: 'sprite', unicode: '' },
        { name: 'parking', type: 'sprite', unicode: '' },
        { name: 'pink_dildo', type: 'sprite', unicode: '' },
        { name: 'pinkie', type: 'sprite', unicode: '' },
        { name: 'pussy_platter', type: 'sprite', unicode: '' },
        { name: 'rabbit_vibrator', type: 'sprite', unicode: '' },
        { name: 'spanker', type: 'sprite', unicode: '' },
        { name: 'strap_on', type: 'sprite', unicode: '' },
        { name: 'swing', type: 'sprite', unicode: '' },
        { name: 'tail', type: 'sprite', unicode: '' },
        { name: 'taped_nipples', type: 'sprite', unicode: '' },
        { name: 'threesome', type: 'sprite', unicode: '' },
        { name: 'twerking', type: 'sprite', unicode: '' },
        { name: 'wax_drip', type: 'sprite', unicode: '' },
        { name: 'whip', type: 'sprite', unicode: '' }
    ];
}

export function replace(string, smilies = list) {
    const patterns = [];
    const metachars = /[[\]{}()*+?.\\|^$\-,&#\s]/g;

    smilies.forEach((smiley) => {
        if (smiley.shortcode) {
            patterns.push(`(${smiley.shortcode.replace(metachars, '\\$&')})`);
        }
    });

    // Sort by length prevents things like :p catching :poke:
    patterns.sort((a, b) => b.length - a.length);

    return string.replace(new RegExp(patterns.join('|'), 'gi'), (match) => {
        if (!match) {
            return '';
        }
        const filtered = smilies.filter(emoticon => emoticon.shortcode && emoticon.shortcode.toLowerCase() === match.toLowerCase());
        return `<img height="${filtered[0].height}" width="${filtered[0].width}" title="${filtered[0].name}" src="${filtered[0].src}">`;
    });
}

export function replaceInHtml(html, smilies) {
    if (html) {
        smilies.forEach((emoticon) => {
            if (emoticon.shortcode) {
                html = html.replace(emoticon.shortcode, `<img src="${emoticon.src}" alt="${emoticon.name}">`);
            }
        });
        return html;
    }
    return '';
}
