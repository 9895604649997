import $ from 'jquery';
import store from '../../store';

/* global cdnUrl:true, redactorConfig:true */
const smilePrefix = `${(typeof cdnUrl !== 'undefined' ? cdnUrl : '/assets/images')}/emoticons/`;
const emoticons = [
    { name: 'angry', src: `${smilePrefix}angry.png`, shortcode: '&gt;(' }, // , '&gt;:(', '&gt;[', '&gt;:[', ':angry:'
    { name: 'aw', src: `${smilePrefix}aw.png`, shortcode: ':aw:' }, //
    { name: 'cool', src: `${smilePrefix}cool.png`, shortcode: '8)' }, // , '8]', ':cool:'
    { name: 'ecstatic', src: `${smilePrefix}ecstatic.png`, shortcode: ':D' }, // , '8D', ':ecstatic:'
    { name: 'furious', src: `${smilePrefix}furious.png`, shortcode: '&gt;:D' }, // , '&gt;&lt;', ':furious:'
    { name: 'gah', src: `${smilePrefix}gah.png`, shortcode: 'D:' }, // , ':O', ':gah:'
    { name: 'happy', src: `${smilePrefix}icon_smile.gif`, shortcode: ':)' }, // , ':]', ':happy:'
    { name: 'heart', src: `${smilePrefix}heart.png`, shortcode: '&lt;3' }, // , ':heart:'
    { name: 'hm', src: `${smilePrefix}hm.png`, shortcode: ':hm:' }, //
    { name: 'kiss', src: `${smilePrefix}kiss.gif`, shortcode: ':kiss:' }, //
    { name: 'meh', src: `${smilePrefix}meh.png`, shortcode: ':|' }, // , '-.-', '&lt;_&lt;', '&gt;_&gt;', ':meh:'
    { name: 'mmf', src: `${smilePrefix}mmf.png`, shortcode: ':x' }, // , ':X', ':mmf:'
    { name: 'sad', src: `${smilePrefix}sad.gif`, shortcode: ':(' }, // , ':[', ';(', ';[', ':\'(', ':\'[', ';\'(', ';\'[', ':sad:'
    { name: 'tongue', src: `${smilePrefix}tongue.png`, shortcode: ':P' }, // , ':p', ':tongue:'
    { name: 'what', src: `${smilePrefix}what.png`, shortcode: ':o' }, // , ':?', ':what:'
    { name: 'wink', src: `${smilePrefix}wink.gif`, shortcode: ';)' }, // , ';]', ';D', ':wink:'
    { name: 'icon_biggrin', src: `${smilePrefix}icon_biggrin.gif`, shortcode: ':D' }, // , ':-D'
    { name: 'icon_smile', src: `${smilePrefix}icon_smile.gif`, shortcode: ':smile:' }, // , ':-)', ':)'
    { name: 'icon_sad', src: `${smilePrefix}icon_sad.gif`, shortcode: ':sad:' }, // , ':-(', ':('
    { name: 'icon_surprised', src: `${smilePrefix}icon_surprised.gif`, shortcode: ':o' }, // , ':-o', ':eek:'
    { name: 'icon_eek', src: `${smilePrefix}icon_eek.gif`, shortcode: ':shock:' }, //
    { name: 'icon_confused', src: `${smilePrefix}icon_confused.gif`, shortcode: ':?' }, // , ':-?', ':???:'
    { name: 'icon_cool', src: `${smilePrefix}icon_cool.gif`, shortcode: '8-)' }, // , ':cool:'
    { name: 'icon_lol', src: `${smilePrefix}icon_lol.gif`, shortcode: ':lol:' }, //
    { name: 'icon_mad', src: `${smilePrefix}icon_mad.gif`, shortcode: ':x' }, // , ':-x', ':mad:'
    { name: 'icon_razz', src: `${smilePrefix}icon_razz.gif`, shortcode: ':P' }, // , ':-P', ':razz:'
    { name: 'icon_redface', src: `${smilePrefix}icon_redface.gif`, shortcode: ':oops:' }, //
    { name: 'icon_cry', src: `${smilePrefix}icon_cry.gif`, shortcode: ':cry:' }, //
    { name: 'icon_evil', src: `${smilePrefix}icon_evil.gif`, shortcode: ':evil:' }, //
    { name: 'icon_twisted', src: `${smilePrefix}icon_twisted.gif`, shortcode: ':twisted:' }, //
    { name: 'icon_rolleyes', src: `${smilePrefix}icon_rolleyes.gif`, shortcode: ':roll:' }, //
    { name: 'icon_wink', src: `${smilePrefix}icon_wink.gif`, shortcode: ':wink:' }, // , ';)', ';-)'
    { name: 'icon_exclaim', src: `${smilePrefix}icon_exclaim.gif`, shortcode: ':!:' }, //
    { name: 'icon_question', src: `${smilePrefix}icon_question.gif`, shortcode: ':?:' }, //
    { name: 'icon_idea', src: `${smilePrefix}icon_idea.gif`, shortcode: ':idea:' }, //
    { name: 'icon_arrow', src: `${smilePrefix}icon_arrow.gif`, shortcode: ':arrow:' }, //
    { name: 'icon_neutral', src: `${smilePrefix}icon_neutral.gif`, shortcode: ':|' }, // , ':-|', ':neutral:'
    { name: 'icon_mrgreen', src: `${smilePrefix}icon_mrgreen.gif`, shortcode: ':mrgreen:' }, //
    { name: '69position', src: `${smilePrefix}69position.gif`, shortcode: ':69:' }, //
    { name: 'banghead', src: `${smilePrefix}banghead.gif`, shortcode: ':banghead:' }, //
    { name: 'blink', src: `${smilePrefix}blink.gif`, shortcode: ':blink:' }, //
    { name: 'boink', src: `${smilePrefix}boink.gif`, shortcode: ':boink:' }, //
    { name: 'borg', src: `${smilePrefix}borg.gif`, shortcode: ':borg:' }, //
    { name: 'bounce', src: `${smilePrefix}bounce.gif`, shortcode: ':bounce:' }, //
    { name: 'boxing', src: `${smilePrefix}boxing.gif`, shortcode: ':boxing:' }, //
    { name: 'bs', src: `${smilePrefix}bs.gif`, shortcode: ':bs:' }, //
    { name: 'censored', src: `${smilePrefix}censored.gif`, shortcode: ':censored:' }, //
    { name: 'color', src: `${smilePrefix}color.gif`, shortcode: ':color:' }, //
    { name: 'doh', src: `${smilePrefix}doh.gif`, shortcode: ':doh:' }, //
    { name: 'dry', src: `${smilePrefix}dry.gif`, shortcode: ':dry:' }, //
    { name: 'dunno', src: `${smilePrefix}dunno.gif`, shortcode: ':dunno:' }, //
    { name: 'eeek', src: `${smilePrefix}eeek.gif`, shortcode: ':eeek:' }, //
    { name: 'flipa', src: `${smilePrefix}flipa.gif`, shortcode: ':flipa:' }, //
    { name: 'haha', src: `${smilePrefix}haha.gif`, shortcode: ':haha:' }, //
    { name: 'lickface', src: `${smilePrefix}lickface.gif`, shortcode: ':lickface:' }, //
    { name: 'rotflmao', src: `${smilePrefix}rotflmao.gif`, shortcode: ':rotflmao:' }, //
    { name: 'silly', src: `${smilePrefix}silly.gif`, shortcode: ':silly:' }, //
    { name: 'small-print', src: `${smilePrefix}small-print.gif`, shortcode: ':small-print:' }, //
    { name: 'uhoh', src: `${smilePrefix}uhoh.gif`, shortcode: ':uhoh:' }, //
    { name: 'wave', src: `${smilePrefix}wave.gif`, shortcode: ':wave:' }, //
    { name: 'worship', src: `${smilePrefix}worship.gif`, shortcode: ':worship:' }, //
    { name: '2fingers', src: `${smilePrefix}2fingers.gif`, shortcode: ':2fingers:' }, //
    { name: 'confused', src: `${smilePrefix}confused.gif`, shortcode: ':confused:' }, //
    { name: 'embarrased', src: `${smilePrefix}embarrased.gif`, shortcode: ':embarrased:' }, //
    { name: 'grin', src: `${smilePrefix}grin.gif`, shortcode: ':grin:' }, //
    { name: 'high-smile', src: `${smilePrefix}high-smile.gif`, shortcode: ':high-smile:' }, //
    { name: 'hump', src: `${smilePrefix}hump.gif`, shortcode: ':hump:' }, //
    { name: 'inlove', src: `${smilePrefix}inlove.gif`, shortcode: ':inlove:' }, //
    { name: 'love', src: `${smilePrefix}love.gif`, shortcode: ':love:' }, //
    { name: 'sleeping', src: `${smilePrefix}sleeping.gif`, shortcode: ':sleeping:' }, //
    { name: 'smug', src: `${smilePrefix}smug.gif`, shortcode: ':smug:' }, //
    { name: 'taz', src: `${smilePrefix}taz.gif`, shortcode: ':taz:' }, //
    { name: 'wary', src: `${smilePrefix}wary.gif`, shortcode: ':wary:' }, //
    { name: 'angel', src: `${smilePrefix}angel.gif`, shortcode: ':angel:' }, //
    { name: 'beer', src: `${smilePrefix}beer.gif`, shortcode: ':beer:' }, //
    { name: 'boo', src: `${smilePrefix}boo.gif`, shortcode: ':boo:' }, //
    { name: 'confused2', src: `${smilePrefix}confused2.gif`, shortcode: ':confused2:' }, //
    { name: 'crazy', src: `${smilePrefix}crazy.gif`, shortcode: ':crazy:' }, //
    { name: 'embarrased2', src: `${smilePrefix}embarrased2.gif`, shortcode: ':embarrased2:' }, //
    { name: 'evil', src: `${smilePrefix}evil.gif`, shortcode: ':evil2:' }, //
    { name: 'giggle', src: `${smilePrefix}giggle.gif`, shortcode: ':giggle:' }, //
    { name: 'karaoke', src: `${smilePrefix}karaoke.gif`, shortcode: ':karaoke:' }, //
    { name: 'notes', src: `${smilePrefix}notes.gif`, shortcode: ':notes:' }, //
    { name: 'ouch', src: `${smilePrefix}ouch.gif`, shortcode: ':ouch:' }, //
    { name: 'rose', src: `${smilePrefix}rose.gif`, shortcode: ':rose:' }, //
    { name: 'smile2', src: `${smilePrefix}smile2.gif`, shortcode: ':smile2:' }, //
    { name: 'smoke', src: `${smilePrefix}smoke.gif`, shortcode: ':smoke:' }, //
    { name: 'thrilled', src: `${smilePrefix}thrilled.gif`, shortcode: ':thrilled:' }, //
    { name: 'wave2', src: `${smilePrefix}wave2.gif`, shortcode: ':wave2:' }, //
    { name: 'yawn', src: `${smilePrefix}yawn.gif`, shortcode: ':yawn:' }, //
    { name: 'rude', src: `${smilePrefix}rude.gif`, shortcode: ':rude:' }, //
    { name: 'undecided', src: `${smilePrefix}undecided.gif`, shortcode: ':undecided:' }, //
    { name: 'blast', src: `${smilePrefix}blast.gif`, shortcode: ':blast:' }, //
    { name: 'sparring', src: `${smilePrefix}sparring.gif`, shortcode: ':sparring:' }, //
    { name: 'cheers', src: `${smilePrefix}cheers.gif`, shortcode: ':cheers:' }, //
    { name: 'coffee', src: `${smilePrefix}coffee.gif`, shortcode: ':coffee:' }, //
    { name: 'duel', src: `${smilePrefix}duel.gif`, shortcode: ':duel:' }, //
    { name: 'gagged', src: `${smilePrefix}gagged.gif`, shortcode: ':gagged:' }, //
    { name: 'hunk', src: `${smilePrefix}hunk.gif`, shortcode: ':hunk:' }, //
    { name: 'jagsatwork', src: `${smilePrefix}jagsatwork.gif`, shortcode: ':jagsatwork:' }, //
    { name: 'kick', src: `${smilePrefix}kick.gif`, shortcode: ':kick:' }, //
    { name: 'kissmyarse', src: `${smilePrefix}kissmyarse.gif`, shortcode: ':kissmyarse:' }, //
    { name: 'lol2', src: `${smilePrefix}lol2.gif`, shortcode: ':lol2:' }, //
    { name: 'moon', src: `${smilePrefix}moon.gif`, shortcode: ':moon:' }, //
    { name: 'passionkiss', src: `${smilePrefix}passionkiss.gif`, shortcode: ':passionkiss:' }, //
    { name: 'phwoar', src: `${smilePrefix}phwoar.gif`, shortcode: ':phwoar:' }, //
    { name: 'rascal', src: `${smilePrefix}rascal.gif`, shortcode: ':rascal:' }, //
    { name: 'smackbottom', src: `${smilePrefix}smackbottom.gif`, shortcode: ':smackbottom:' }, //
    { name: 'smitten', src: `${smilePrefix}smitten.gif`, shortcode: ':smitten:' }, //
    { name: 'spit', src: `${smilePrefix}spit.gif`, shortcode: ':spit:' }, //
    { name: 'sticky', src: `${smilePrefix}sticky.gif`, shortcode: ':sticky:' }, //
    { name: 'swingingchair', src: `${smilePrefix}swingingchair.gif`, shortcode: ':swingingchair:' }, //
    { name: 'therethere', src: `${smilePrefix}therethere.gif`, shortcode: ':therethere:' }, //
    { name: 'upset', src: `${smilePrefix}upset.gif`, shortcode: ':upset:' }, //
    { name: 'violin', src: `${smilePrefix}violin.gif`, shortcode: ':violin:' }, //
    { name: 'wanker', src: `${smilePrefix}wanker.gif`, shortcode: ':wanker:' }, //
    { name: 'welcome', src: `${smilePrefix}welcome.gif`, shortcode: ':welcome:' }, //
    { name: 'whip', src: `${smilePrefix}whip.gif`, shortcode: ':whip:' }, //
    { name: 'yinyang', src: `${smilePrefix}yinyang.gif`, shortcode: ':yinyang:' }, //
    { name: 'poke', src: `${smilePrefix}poke.gif`, shortcode: ':poke:' }, //
    { name: 'bolt', src: `${smilePrefix}bolt.gif`, shortcode: ':bolt:' }, //
    { name: 'loon', src: `${smilePrefix}loon.gif`, shortcode: ':loon:' }, //
    { name: 'fuckinghell', src: `${smilePrefix}fuckinghell.gif`, shortcode: ':fuckinghell:' }, //
    { name: 'giveup', src: `${smilePrefix}giveup.gif`, shortcode: ':giveup:' }, //
    { name: 'drinkies', src: `${smilePrefix}drinkies.gif`, shortcode: ':drinkies:' }, //
    { name: 'scared', src: `${smilePrefix}scared.gif`, shortcode: ':scared:' }, //
    { name: 'laughabove', src: `${smilePrefix}laughabove.gif`, shortcode: ':laughabove:' }, //
    { name: 'huh', src: `${smilePrefix}huh.gif`, shortcode: ':huh:' }, //
    { name: 'thumbup', src: `${smilePrefix}thumbup.gif`, shortcode: ':thumbup:' }, //
    { name: 'whistling', src: `${smilePrefix}whistling.gif`, shortcode: ':whistling:' } //
];

export { emoticons };

/* eslint no-unused-vars:0 */
export default {
    buttons: ['bold', 'italic', 'underline', 'lists', 'link'],
    plugins: ['emoticons', 'imagemanager', 'quote', 'fullscreen'],
    emoticons,
    toolbarFixed: false,
    linkify: false,
    callbacks: {
        init() {
            let html = this.code.get();
            if (html) {
                const patterns = [];
                const metachars = /[[\]{}()*+?.\\|^$\-,&#\s]/g;

                emoticons.forEach((emoticon) => {
                    patterns.push(`(${emoticon.shortcode.replace(metachars, '\\$&')})`);
                });

                html = html.replace(new RegExp(patterns.join('|'), 'g'), (match) => {
                    const filtered = emoticons.filter(emoticon => emoticon.shortcode === match);
                    return `<img src="${filtered[0].src}"/>`;
                });

                this.insert.set(html);
            }
        },
        syncBefore(html) {
            emoticons.forEach((emoticon) => {
                const regex = new RegExp(`<img class="smile" src="(${emoticon.src})" alt="([a-zA-Z]*)"(>)?( data-img-redactor="([0-9]*)">)?`, 'gi');
                html = html.replace(regex, emoticon.shortcode);
            });
            return html;
        },
        insertedLink(link) {
            if (store.state.siteConfig.data.permissions.canSendLinks) {
                return;
            }
            const $el = $(link[0]);
            const href = $el.attr('href');

            if (href.indexOf(window.location.host) === -1) {
                $el.attr('href', '#');
                $el.attr('title', this.lang.get('link_message'));
                $el.html(this.lang.get('link_removed'));
            }
        }
    }
};
