export default function () {
    const scheme = [111, 116, 108, 105, 97, 109];
    const local = [111, 102, 110, 105];
    const domain = window.location.hostname.replace('www.', '');

    document.querySelector('a[href="#email"]').addEventListener('click', (event) => {
        event.preventDefault();
        window.location.href = String.fromCharCode.apply(null, scheme.reverse())
            + String.fromCharCode(58)
            + String.fromCharCode.apply(null, local.reverse())
            + String.fromCharCode(64)
            + domain;
    });
}
