<template>
    <div :class="['mediapicker', { 'mediapicker-no-items': !items.length }]">
        <loading v-if="loading"></loading>
        <template v-if="!loading">
            <media v-for="item in items" :key="item.id" :media="item" :card="false" :stats="false" :adult-allowed="adultAllowed"
                v-on:mediaSelected="selected" v-on:mediaUnselected="unselected" :private-allowed="true" :selectable="true"></media>
            <span v-if="!items.length">{{ gt.messaging.no_media }}</span>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import ajax from 'Site/js/util/ajax.fetch';
import Loading from 'Site/component/Loading.vue';
import Media from './Media.vue';

export default {
    name: 'MediaPicker',
    components: {
        Loading,
        Media
    },
    computed: {
        ...mapState(['siteConfig', 'gt']),
        ...mapState('media', {
            allMedia: state => state.cache
        }),
        storeKey() {
            return JSON.stringify(this.type) + this.showPrivate;
        }
    },
    data() {
        return {
            items: [],
            loading: false,
            selectedMedia: []
        };
    },
    methods: {
        load() {
            this.selectedMedia = [];
            this.$children.forEach((child) => {
                child.$emit('reset');
            });
            if (this.allMedia[this.storeKey]) {
                this.items = this.allMedia[this.storeKey];
                return;
            }
            this.loading = true;
            const params = {
                user: this.siteConfig.user.id,
                set: this.set,
                type: this.type,
                approved: 1,
                deleted: 0
            };
            if (!this.showPrivate) {
                params.public = 1;
            }

            ajax.get(
                'media',
                params
            ).then((response) => {
                this.$store.commit('media/ADD_MEDIA', {
                    key: this.storeKey,
                    media: response.items
                });
                this.items = this.allMedia[this.storeKey];
                this.loading = false;
            });
        },
        selected(image) {
            this.selectedMedia.push(image);
            if (this.emitSelectedOnClick) {
                this.$emit('selected-media', this.selectedMedia);
            }
        },
        unselected(image) {
            this.selectedMedia = this.selectedMedia.filter(selected => selected.id !== image.id);
            if (this.emitSelectedOnClick) {
                this.$emit('selected-media', this.selectedMedia);
            }
        },
        submit() {
            this.$emit('selected-media', this.selectedMedia);
            this.selectedMedia = [];
            this.$children.forEach((child) => {
                child.$emit('reset');
            });
            this.$parent.$emit('hideModal');
        }
    },
    mounted() {
        if (this.loadOnMount) {
            this.load();
        } else {
            this.$on('show', this.load);
        }
        this.$on('reset', () => {
            this.selectedMedia = [];
            this.$children.forEach((child) => {
                child.$emit('reset');
            });
        });
        this.$on('submit', this.submit);
    },
    props: {
        adultAllowed: {
            type: Boolean,
            default: false
        },
        emitSelectedOnClick: {
            type: Boolean,
            default: true
        },
        loadOnMount: {
            type: Boolean,
            default: true
        },
        set: {
            type: [Array, Number],
            default() {
                return [1, 5];
            }
        },
        showPrivate: {
            type: Boolean,
            default: false
        },
        type: {
            type: [Number, Array]
        }
    }
};

</script>

<style lang="scss">
.mediapicker {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: -15px;
    min-height: 100px;
}
.mediapicker-no-items {
    align-items: center;
}
#redactor-modal-imagemanager .mediapicker {
    margin: 0;
}
</style>
