<template>
<div class="carousel">
    <div v-show="!isMobile && currentSlide > 0" class="carousel-arrow carousel-prev" @click="prev()">
        <i class="fa fa-chevron-left"></i>
    </div>
    <div class="slides" ref="slider" @touchstart.stop="touchstartHandler" @touchmove.stop="touchmoveHandler" @touchend.stop="touchendHandler"
        @mousedown.stop.prevent="mousedownHandler" @mouseup.stop="mouseupHandler" @mouseleave="mouseleaveHandler"
        @mousemove.prevent="mousemoveHandler" :style="{ width: (width * slides.length) + 'px'}">
        <div v-for="(slide, index) in slides" :key="index" :style="{ width: width + 'px' }" :class="['slide', {'active': index === currentSlide}]">
            <!-- @slot Use this slot to pass the slide template -->
            <slot :style="{width: width + 'px'}" :slide="slide" :prevSlide="slides[index-1]" :nextSlide="slides[index+1]"></slot>
        </div>
    </div>
    <div v-show="!isMobile && currentSlide < slides.length - 1" class="carousel-arrow carousel-next" @click="next()">
        <i class="fa fa-chevron-right"></i>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import { throttle } from 'underscore';

export default {
    name: 'Carousel',
    data() {
        return {
            addCounter: 0,
            currentSlide: 0,
            drag: {
                startX: 0,
                endX: 0,
                startY: 0,
                letItGo: null,
                preventClick: false
            },
            width: 0,
            innerElements: null,
            perPage: 1,
            pointerDown: false,
            throttledResize: throttle(this.handleResize, 100),
            transformProperty: 'transform'
        };
    },
    computed: {
        ...mapState(['isMobile']),
        threshold() {
            return this.width / 4;
        }
    },
    /** Triggered if we are less than 1 position from the end
     * @event loadmore
     * @type {null}
     */
    mounted() {
        this.$nextTick(() => {
            this.currentSlide = Math.max(
                0,
                Math.min(this.startIndex, this.slides.length - this.perPage)
            );
            this.$nextTick(() => {
                this.width = this.$el.clientWidth;
                this.slideToCurrent();
                if (this.currentSlide >= this.slides.length - 1) {
                    this.$emit('loadmore');
                }
            });
        });
        this.transformProperty = this.webkitOrNot();
        window.addEventListener('resize', this.throttledResize);
    },
    methods: {
        webkitOrNot() {
            const { style } = document.documentElement;
            if (typeof style.transform === 'string') {
                return 'transform';
            }
            return 'WebkitTransform';
        },
        clearDrag() {
            this.drag = {
                startX: 0,
                endX: 0,
                startY: 0,
                letItGo: null,
                preventClick: this.drag.preventClick
            };
        },
        slideToCurrent(enableTransition) {
            const offset = -1 * this.currentSlide * (this.width / this.perPage);

            if (enableTransition) {
                // This one is tricky, I know but this is a perfect explanation:
                // https://youtu.be/cCOL7MC4Pl0
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        this.enableTransition();
                        this.$refs.slider.style[this.transformProperty] = `translate3d(${offset}px, 0, 0)`;
                    });
                });
            } else {
                this.$refs.slider.style[this.transformProperty] = `translate3d(${offset}px, 0, 0)`;
            }
        },
        mousedownHandler(e) {
            this.pointerDown = true;
            this.drag.startX = e.pageX;
        },
        mouseupHandler() {
            this.pointerDown = false;
            this.enableTransition();
            if (this.drag.endX) {
                this.updateAfterDrag();
            }
            this.clearDrag();
        },
        mousemoveHandler(e) {
            if (this.pointerDown && this.slides.length > 1) {
                this.drag.endX = e.pageX;
                this.$refs.slider.style.webkitTransition = 'all 0ms ease-out';
                this.$refs.slider.style.transition = 'all 0ms ease-out';

                const currentOffset = this.currentSlide * (this.width / this.perPage);
                const dragOffset = (this.drag.endX - this.drag.startX);
                const offset = currentOffset - dragOffset;
                this.$refs.slider.style[this.transformProperty] = `translate3d(${-1 * offset}px, 0, 0)`;
            }
        },
        mouseleaveHandler(e) {
            if (this.pointerDown) {
                this.pointerDown = false;
                this.drag.endX = e.pageX;
                this.drag.preventClick = false;
                this.enableTransition();
                this.updateAfterDrag();
                this.clearDrag();
            }
        },
        touchstartHandler(e) {
            this.pointerDown = true;
            this.drag.startX = e.touches[0].pageX;
            this.drag.startY = e.touches[0].pageY;
        },
        touchmoveHandler(e) {
            if (this.drag.letItGo === null) {
                this.drag.letItGo = Math.abs(this.drag.startY - e.touches[0].pageY) < Math.abs(this.drag.startX - e.touches[0].pageX);
            }

            if (this.pointerDown && this.drag.letItGo && this.slides.length > 1) {
                this.drag.endX = e.touches[0].pageX;
                this.$refs.slider.style.webkitTransition = 'all 0ms ease-out';
                this.$refs.slider.style.transition = 'all 0ms ease-out';

                const currentOffset = this.currentSlide * (this.width / this.perPage);
                const dragOffset = this.drag.endX - this.drag.startX;
                const offset = currentOffset - dragOffset;
                this.$refs.slider.style[this.transformProperty] = `translate3d(${-1 * offset}px, 0, 0)`;
            }
        },
        touchendHandler() {
            this.pointerDown = false;
            this.enableTransition();
            if (this.drag.endX) {
                this.updateAfterDrag();
            }
            this.clearDrag();
        },
        enableTransition() {
            this.$refs.slider.style.webkitTransition = 'all 200ms ease-out';
            this.$refs.slider.style.transition = 'all 200ms ease-out';
        },
        /**
         * Recalculate drag /swipe event and reposition the frame of a slider
         */
        updateAfterDrag() {
            const movement = 1 * (this.drag.endX - this.drag.startX);
            const movementDistance = Math.abs(movement);
            const howManySliderToSlide = Math.ceil(movementDistance / (this.width / this.perPage));

            const slideToNegativeClone = movement > 0 && this.currentSlide - howManySliderToSlide < 0;
            const slideToPositiveClone = movement < 0 && this.currentSlide + howManySliderToSlide > this.slides.length - this.perPage;

            if (movement > 0 && movementDistance > this.threshold && this.slides.length > this.perPage) {
                this.prev(howManySliderToSlide);
            } else if (movement < 0 && movementDistance > this.threshold && this.slides.length > this.perPage) {
                this.next(howManySliderToSlide);
            }
            this.slideToCurrent(slideToNegativeClone || slideToPositiveClone);
        },
        /**
         * Go to previous slide.
         * @param {number} [howManySlides=1] - How many items to slide backward.
         */
        /** Triggered when moving slide before the action
         * @event beforechange
         * @type {Object}
         */
        /** Triggered when moving slide after the action
         * @event afterchange
         * @type {null}
         */
        prev(howManySlides = 1) {
            // early return when there is nothing to slide
            if (this.slides.length < this.perPage) {
                return;
            }

            const beforeChange = this.currentSlide;
            this.currentSlide = Math.max(this.currentSlide - howManySlides, 0);
            if (beforeChange !== this.currentSlide) {
                this.$emit('beforechange', this.currentSlide);
                this.slideToCurrent(false);
                this.$emit('afterchange');
            }
        },
        /**
         * Go to next slide.
         * @param {number} [howManySlides=1] - How many items to slide forward.
         */
        next(howManySlides = 1) {
            // early return when there is nothing to slide
            if (this.slides.length <= this.perPage) {
                return;
            }

            const beforeChange = this.currentSlide;
            this.currentSlide = Math.min(this.currentSlide + howManySlides, this.slides.length - this.perPage);
            if (beforeChange !== this.currentSlide) {
                this.$emit('beforechange', this.currentSlide);
                this.slideToCurrent(false);
                this.$emit('afterchange');
                if (this.currentSlide >= this.slides.length - 2) {
                    this.$emit('loadmore');
                }
            }
        },
        handleResize() {
            this.width = this.$el.clientWidth;
            this.$nextTick(() => {
                this.slideToCurrent(false);
            });
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.throttledResize);
    },
    props: {
        slides: {
            type: Array,
            required: true
        },
        startIndex: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.carousel {
    overflow: hidden;
    position: relative;
    &:hover {
        .carousel-arrow {
            opacity: 1;
        }
    }
}
.carousel-arrow {
    position: absolute;
    top: 0;
    height: 85%;
    width: 75px;
    font-size: 1.2em;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 500ms;
    color: #ddd;
    cursor: pointer;
}
.carousel-next {
    right: 0;
}
.carousel-prev {
    left: 0;
}
.slides {
    height: 100%;
    outline: 1px solid transparent;
    transition: all 200ms ease-out;
}
.slide {
    float: left;
    height: 100%;
}
</style>
