<template>
    <div :class="['media-item', 'impress-image', `media-item-${size}`,
        { 'media-item-card': card, 'has-like': showLike, 'selected': selectable && selected, 'selectable': selectable }]"
        :data-id="media.id" :data-owner="media.user ? media.user.id : null" :data-type="media.set" @click="clickHandler"
        @mouseover="startPreview" @mouseout="stopPreview" :style="previewImage || image">
        <span class="fa-stack fa-lg" v-if="selected">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-check fa-stack-1x fa-inverse"></i>
        </span>
        <i v-if="media.set === 'videos' && size!=='mediaviewer'" class="fa fa-play"></i>
        <i v-if="media.private" class="fa fa-lock" title="Image is in a private album"></i>
        <span v-if="privateMedia || maskAdult" :class="`text-overlay adult-only-${colour}`" v-html="privateText"></span>
        <slot></slot>
        <img v-if="(media.set!=='videos' && size==='mediaviewer') || (size==='fill' || size==='large')" :src="previewUrl || url">
        <video v-if="media.set==='videos' && size==='mediaviewer'" :src="url" preload="metadata" controls
            controlsList="nodownload"></video>
        <div class="gallery-result-details" v-if="stats">
            <div class="media-stats">
                <div class="like-count">
                    <i class="sh-icon"></i> {{ media.likes }}
                </div>
                <div><i class="fa fa-eye"></i> <span class="media-view-count">{{ media.views }}</span></div>
                <div><i class="fa fa-comment"></i> <span class="media-view-count">{{ media.comments }}</span></div>
            </div>
        </div>
        <like v-if="showLike" :foreign="media.id" :id="likeId" type="collection_comment"></like>
    </div>
</template>

<script>
import Like from 'Feedback/component/Like.vue';
import { mapState } from 'vuex';
import random from 'Site/js/util/random';

export default {
    name: 'Media',
    components: {
        Like
    },
    computed: {
        ...mapState({
            gt: state => state.gt.common
        }),
        image() {
            if (this.size === 'fill' || this.size === 'large' || this.size === 'mediaviewer'
                || (this.media.set === 'videos' && this.size === 'fill')) {
                return '';
            }
            return `background-image: url(${this.url})`;
        },
        maskAdult() {
            if (this.userActivitySettings) {
                return this.maskableMedia
                    && this.userActivitySettings.maskAdultAvailableInSection
                    && this.userActivitySettings.maskAdultUserPreference;
            }
            return false;
        },
        maskableMedia() {
            /*
                * media( isAdult || isMisc )
                * && component(!QuickView || !MediaPicker)
                * @return boolean
                */
            return (this.media.adult || this.media.albumType === 2)
                && !this.componentAllowAdult;
        },
        privateMedia() {
            const adultAllowed = this.$store.state.siteConfig.data.permissions.adult || false;
            return (!(this.adultAllowed || adultAllowed) && this.media.adult) || !this.media.media ||
                (this.media.album && this.media.album.private && !this.privateAllowed);
        },
        privateText() {
            if (this.privateMedia) {
                return this.media.adult ? this.gt.eighteen_over : this.gt.private;
            } else if (this.maskAdult) {
                return this.gt.view_media;
            }
            return '';
        },
        url() {
            if (this.lazyLoad) {
                return false;
            }
            let url = '';

            if (this.privateMedia || this.maskAdult) {
                let genderPath = this.media.user && this.media.user.avatartype ? this.media.user.avatartype : 'male';
                if (genderPath === 'couple') {
                    genderPath = random(1, 2) === 1 ? 'male' : 'female';
                }

                url = this.$store.state.siteConfig.data.censoredUrl
                    .replace('1.jpg', `${genderPath}/150px/${this.imageNum}-150px-${this.colour.substr(0, 1)}.jpg`);
            } else if (this.media.media) {
                if (this.media.set === 'videos') {
                    if (this.size === 'mediaviewer') {
                        /* eslint prefer-destructuring: 0 */
                        if (this.media.media['video-1080'] && this.width > 700) {
                            url = this.media.media['video-1080'].url;
                        } else if (this.media.media['video-720']) {
                            url = this.media.media['video-720'].url;
                        } else {
                            url = this.media.media['video-480'].url;
                        }
                    } else if (this.size === 'fill' || this.size === 'large') {
                        if (this.media.media.large_video_thumbnail) {
                            url = this.media.media.large_video_thumbnail.url;
                        } else {
                            url = this.media.media['video-thumbnail'].url;
                        }
                    } else {
                        url = this.media.media['video-thumbnail'].url;
                    }
                } else if (this.size === 'fill' || this.size === 'mediaviewer' && this.media.media['large-watermarked']) {
                    if (this.media.media['xxlarge-watermarked'] && this.width > 1200) {
                        url = this.media.media['xxlarge-watermarked'].url;
                    } else if (this.media.media['xlarge-watermarked'] && this.width > 500) {
                        url = this.media.media['xlarge-watermarked'].url;
                    } else if (this.media.media['large-watermarked']) {
                        url = this.media.media['large-watermarked'].url;
                    } else {
                        url = this.media.media.medium.url;
                    }
                } else if ( this.media.media['large-watermarked'] && this.size === 'large' ) {
                    url = this.media.media['large-watermarked'].url;
                } else if (this.media.media['large-thumbnail'] && this.size === 'large-thumbnail') {
                    url = this.media.media['large-thumbnail'].url;
                } else if (this.media.media.medium) {
                    url = this.media.media.medium.url;
                } else if (this.media.media.smallcover) {
                    url = this.media.media.smallcover.url;
                }
            }
            return url;
        }
    },
    data() {
        return {
            colour: random(0, 1) ? 'white' : 'black',
            imageNum: random(1, 25),
            previewImage: null,
            previewUrl: null,
            selected: false,
            width: this.$el ? this.$el.offsetWidth : 0
        };
    },
    methods: {
        clickHandler() {
            if (this.selectable) {
                if (this.selected) {
                    this.$emit('mediaUnselected', this.media);
                } else {
                    this.$emit('mediaSelected', this.media);
                }
                this.selected = !this.selected;
            }
        },
        setPreview(image) {
            const largeVideo = this.size === 'fill' || this.size === 'large' || this.size === 'mediaviewer';
            if (largeVideo) {
                this.previewUrl = image;
            } else {
                this.previewImage = `background-image: url(${image})`;
            }
        },
        startPreview() {
            if (this.media.set !== 'videos' || this.size === 'mediaviewer' || !this.media.media) {
                return;
            }
            let previewArray = this.media.media['video-thumbnail-preview'];
            if ((this.size === 'fill' || this.size === 'large') && this.media.media['video-large-preview']) {
                previewArray = this.media.media['video-large-preview'];
            }

            if (!previewArray || !previewArray.length || !previewArray[1]) {
                return;
            }
            this.setPreview(previewArray[1].url);

            let index = 2;
            this.previewInterval = setInterval(() => {
                if (previewArray && previewArray.length >= 3) {
                    this.setPreview(previewArray[index].url);
                    index += 1;
                    if (index >= previewArray.length) {
                        index = 0;
                    }
                }
            }, 500);
        },
        stopPreview() {
            if (this.media.set !== 'videos' || this.size === 'mediaviewer') {
                return;
            }
            this.previewImage = null;
            this.previewUrl = null;
            clearInterval(this.previewInterval);
        }
    },
    mounted() {
        this.$on('reset', () => {
            this.selected = false;
            this.$forceUpdate();
        });

        // I'm not insane, this is to handle media inside carousels, e.g. the media viewer
        // We wait 1 tick to render the media viewer, the carousel waits 2 ticks for its content to render in order
        // to detect the width, we then wait 1 extra tick and then check the slide width to set the media size.
        this.$nextTick(() => {
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.width = this.$el.offsetWidth;
                    });
                });
            });
        });
    },
    inject: {
        userActivitySettings: {
            default: null
        },
        componentAllowAdult: {
            /**
             * Allow for adult media to be visible in quick view wallPost && mediaPicker, but not on feed.
             */
            default: false
        }
    },
    props: {
        adultAllowed: {
            type: Boolean,
            default: false
        },
        card: {
            type: Boolean,
            default: true
        },
        likeId: {
            type: [Boolean, Number]
        },
        lazyLoad: {
            type: Boolean,
            default: false
        },
        media: {
            type: Object,
            required: true
        },
        privateAllowed: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        showLike: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'large-thumbnail'
        },
        stats: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../../module/application/frontend-src/scss/variables";

.media-item {
    height: 150px;
    width: 150px;
    display: inline-block;
    background-size: cover;
    background-color: #ddd;
    margin: 5px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.selected {
        border: 5px solid #eee;
    }
    .fa-stack {
        position: absolute;
        z-index: 1;
    }
    .fa-circle {
        color: $brand-primary;
    }
    .adult-only-white,
    .adult-only-black {
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 1.4em;
        font-weight: 100;
        line-height: 30px;
        padding-top: 60px;
        z-index: 1;
        sup {
            font-size: 65%;
        }
    }
    .fa-play {
        position: absolute;
        color: #fff;
        left: 60px;
        font-size: 50px;
        top: 50px;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
        opacity: 0.5;
    }
    .fa-stack-remove {
        .fa-circle {
            color: $brand-danger;
        }
        right: 2px;
        top: 2px;
        z-index: 2;
    }
    .fa-lock {
        position: absolute;
        right: 10px;
        top: 8px;
        color: #fff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
        font-size: 1.5em;
    }
    .btn.like {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 0;
        border-radius: 0;
    }
    &.has-like {
        height: 180px;
        .gallery-result-details {
            bottom: 30px;
            left: 0;
        }
        img {
            padding-bottom: 30px;
        }
    }
    .btn-dark-ghost {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 1;
        border: none;
    }
    .dropdown {
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .gallery-result-details {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .media-stats {
            font-size: 0.9em;
            margin: 0 auto;
            line-height: 20px;
            .like-count {
                filter: grayscale(0.7);
                .sh-icon {
                    width: 14px;
                    height: 14px;
                    background-position-y: 1px;
                }
            }
        }
    }
}

.gallery-result-details {
    color: #666;
    .media-stats {
        margin-left: 0;
        line-height: 27px;
        display: table;
        div {
            display: table-cell;
            width: 50px;
            text-align: center;
        }
    }
    .like-count {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }
    .like {
        border: 0;
        border-top: 1px solid #eee;
        border-radius: 0;
        display: block;
        width: 100%;
        padding: 6px 0;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        &.liked {
            cursor: pointer;
        }
    }
}

.media-item-card {
    @include card;
}

.media-item-large, .media-item.media-item-mediaviewer {
    width: 100%;
    height: auto;
    margin: 5px 0;
    img, video {
        max-height: 400px;
        max-width: 100%;
    }
    &.has-like {
        height: auto;
    }
    &.media-item .fa-play {
        left: auto;
        top: auto;
    }
    .text-overlay {
        position: absolute;
    }
}

@media (min-width: $screen-sm-min) {
    .media-item-large {
        width: 500px;
        max-width: 100%;
    }
}

.media-item.media-item-small {
    height: 75px;
    width: 75px;
    .adult-only-white,
    .adult-only-black {
        font-size: 0.9em;
        font-weight: 400;
        padding-top: 25px;
    }
    .fa-lock {
        font-size: 1em;
        right: 3px;
        top: 3px;
    }
    .fa-play {
        font-size: 20px;
        left: 35px;
        top: 30px;
    }
}
</style>
