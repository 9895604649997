<template>
    <div class="wall">
        <wall-form v-if="showForm" v-on:create="addPost" :show-private-images="showPrivateImages" :post-type="postType"
            :can-add-events="canAddEvents" :can-add-moods="canAddMoods"></wall-form>
        <wall-post v-if="showPosts" v-for="post in allPosts" :post="post" :readonly="readonly" :key="post.id" v-on:removePost="removePost" 
            :displayed-on-wall="true" :show-user="showUser"></wall-post>
        <infinite v-if="showMore" :loading="loading" :handle-on-mount="false" :handler="fetch"></infinite>
    </div>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';
import Infinite from 'Site/component/Infinite.vue';
import { mapState } from 'vuex';
import WallForm from './Form.vue';
import WallPost from './Post.vue';

export default {
    name: 'Wall',
    components: {
        Infinite,
        WallForm,
        WallPost
    },
    computed: mapState({
        id: state => state.config.data.id
    }),
    data() {
        return {
            allPosts: this.posts !== null ? this.posts : [],
            canLoadMore: true,
            loading: false,
            offset: 10
        };
    },
    methods: {
        addPost(post) {
            this.allPosts.unshift(post);
        },
        fetch() {
            if (!this.canLoadMore) {
                return;
            }
            this.loading = true;
            ajax.get('wall', {
                foreign: this.id,
                type: this.postType,
                'start-index': this.offset,
                'max-results': 10
            }).then((response) => {
                this.loading = false;
                this.offset += 10;
                if (response.items && response.items.length) {
                    response.items.forEach((item) => {
                        this.allPosts.push(item);
                    });
                } else {
                    this.canLoadMore = false;
                }
            });
        },
        removePost(post) {
            this.allPosts = this.allPosts.filter(checkPost => checkPost.id !== post.id);
        }
    },
    props: {
        canAddEvents: {
            type: Boolean,
            default: false
        },
        canAddMoods: {
            type: Boolean,
            default: false
        },
        postType: {
            type: String,
            required: true
        },
        posts: Array,
        readonly: {
            type: Boolean,
            default: false
        },
        showForm: {
            type: Boolean,
            default: true
        },
        showPosts: {
            type: Boolean,
            default: true
        },
        showUser: {
            type: Boolean,
            default: true
        },
        showMore: {
            type: Boolean,
            default: true
        },
        showPrivateImages: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

@media (max-width: $screen-xs-max) {
    .wall {
        margin-left: -15px;
        margin-right: -15px;
        .comment-form-expand .button-area {
            margin-top: 5px;
            button.like {
                float: left !important;
            }
        }
    }
}

/****
*
* - Language Specific SCSS
*
****/

.de_DE, .es_ES, .nl_NL, .pl_PL, .pt_BR {
    @media (max-width: 500px) {
        .wall .wall-post-comments {
            .button-area {
                margin-top: 5px;
                button {
                    display: inline-block;
                    margin-top: 0;
                    min-width: 108px;
                    &.like {
                        float: left !important;
                    }
                }
            }
            .comment-textarea-wrapper {
                margin-right: 0;
            }
        }
    }
}
</style>
