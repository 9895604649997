<template>
<button class="btn btn-default" @click.prevent="share">
    <i class="fa fa-share"></i> {{ gt.share_button.share }}
</button>
</template>

<script>
import { mapState } from 'vuex';

import analytics from 'Site/js/util/analytics';

export default {
    name: 'ShareButton',
    computed: {
        ...mapState(['gt']),
        item() {
            return {
                heading: this.heading,
                icon: this.icon,
                id: this.id,
                type: this.type,
                text: this.text
            };
        }
    },
    methods: {
        share() {
            analytics('share', 'button-click', this.type);
            this.$store.dispatch('sendMessage', { title: this.gt.share_button.share, share: this.item });
        }
    },
    props: {
        heading: {
            type: String,
            required: true
        },
        icon: {
            type: String
        },
        id: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        text: {
            type: String
        }
    }
};

</script>
