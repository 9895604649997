<template>
    <div class="eventpicker">
        <loading v-if="loading"></loading>
        <event v-if="!loading && events.length" v-for="event in events" :key="event.id" :event="event" @click.native="select(event)">
            <span slot="body" class="fa-stack fa-lg pull-right" v-if="selectedEvent === event">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-check fa-stack-1x fa-inverse"></i>
            </span>
        </event>
        <template v-if="!loading && !events.length">{{ t.no_parties }}</template>
    </div>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';
import Loading from 'Site/component/Loading.vue';
import { mapState } from 'vuex';
import Event from './EventSmall.vue';

export default {
    name: 'EventPicker',
    components: {
        Event,
        Loading
    },
    computed: mapState(['t']),
    data() {
        return {
            events: [],
            loading: false,
            selectedEvent: {}
        };
    },
    methods: {
        load() {
            if (this.events.length) {
                return;
            }
            this.loading = true;

            ajax.get('event').then((response) => {
                this.loading = false;
                this.events = this.events.concat(response.items);
            });
        },
        select(event) {
            this.$emit('select-event', event);
            this.$parent.$emit('hideModal');
            if (this.selectedEvent === event) {
                this.selectedEvent = {};
            } else {
                this.selectedEvent = event;
            }
        }
    },
    mounted() {
        this.$on('show', this.load);
    },
};

</script>

<style lang="scss">
@import "~Site/scss/variables";
.eventpicker .fa-circle {
    color: $brand-success;
}
.eventpicker .event-small {
    cursor: pointer;
    margin-bottom: 10px;
    &:hover {
        background-color: #eee;
    }
}
</style>
