<template>
    <div class="flex-row">
        <div class="info">{{ reason.reason }}</div>
        <div v-if="showPremium" class="package cell premium">
            <i v-if="reason.level <= 1 && (!reason.configKey || useFallback)" class="fa fa-check text-success"></i>
            <strong v-else-if="reason.configKey">{{ configReason('Premium') }}</strong>
            <small v-else>-</small>
        </div>
        <div class="package cell extras">
            <i v-if="!reason.configKey || useFallback" class="fa fa-check text-success"></i>
            <strong v-else-if="reason.configKey">{{ configReason('Extras') }}</strong>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SiteUpgradeReasons',
    data() {
        return {
            useFallback: false
        };
    },
    methods: {
        configReason(type) {
            const configKey = this.reason.configKey;
            if (!this.restrictions[configKey][type]) {
                // if no restriction overrides - fallback to use the reason.level
                this.useFallback = true;
                return '-';
            }
            return this.restrictions[configKey][type];
        }
    },
    props: {
        reason: {
            type: Object
        },
        restrictions: {
            type: Object
        },
        showPremium: {
            type: Boolean,
            default: true
        },
        t: {
            type: Object,
            required: true
        }
    }
};
</script>
