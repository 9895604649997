<template>
    <div v-if="verifyId === null" class="alert alert-info">
        <h2>{{ t.not_verified_title }}</h2>
        <p>{{ t.email_error_join_again }}</p>
    </div>
    <div v-else class="verify-email-alert alert">
        <i class="fa fa-exclamation-triangle"></i>
        <h1>{{ t.not_verified_title }}</h1>
        <p>{{ t.verify_to_access_features }}</p>
        <div class="verify-reasons">
            <strong>{{ t.simply_verify_header }}</strong>
            <ul>
                <li><i class="fa fa-check"></i> {{ t.user_profiles }}</li>
                <li><i class="fa fa-check"></i> {{ t.media_viewer }}</li>
                <li><i class="fa fa-check"></i> {{ t.watch_videos}}</li>
                <li v-if="!faces"><i class="fa fa-check"></i> {{ t.access_chat }}</li>
                <li><i class="fa fa-check"></i> {{ t.see_matches }}</li>
                <li><i class="fa fa-check"></i> {{ t.access_mailbox }}</li>
                <li><i class="fa fa-check"></i> {{ t.upload_media }}</li>
            </ul>
        </div>
        <h2 v-text="t.check_emails_for.replace('%emailaddress', userEmail)"></h2>
        <a class="btn btn-primary" href="#" @click.prevent="resendVerify">{{ t.resend_verification }}</a>
        <a class="btn btn-primary" href="#" v-collapse="collapseId">{{ t.change_email }}</a>
        <a class="btn btn-primary" :href="contactUrl">{{ t.still_having_issues }}</a>
        <div :id="uniqueId" class="verify-change-email" v-cloak>
            <form class="form-inline" @submit.prevent="changeEmail">
                <SiteInput v-model="form.email" @input="validate('email')" type="email" :form="form" :label="t.email_label"
                    name="email" autocomplete="email" />
                <input type="submit" name="change" id="change" :value="t.change" class="btn btn-primary btn-lg btn-site-input" />
            </form>
            <loading v-if="loading"></loading>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ajax from 'Site/js/util/ajax.fetch';
import collapseDirective from 'Site/directive/collapse';
import Form from 'Site/js/util/form';
import Loading from 'Site/component/Loading.vue';
import SiteInput from 'Site/component/SiteInput.vue';

export default {
    name: 'EmailVerify',
    components: {
        Loading,
        SiteInput
    },
    computed: {
        ...mapState({
            contactUrl: state => (state.config.pages.contactUrl ? state.config.pages.contactUrl : ''),
            faces: state => state.siteConfig.siteShortCode === 'face',
            t: state => state.t.email_verify,
            verifyId: state => (state.config.data.verifyId ? state.config.data.verifyId : null)
        }),
        collapseId() {
            return `#${this.uniqueId}`;
        }
    },
    directives: {
        collapse: collapseDirective
    },
    data() {
        return {
            loading: false,
            form: new Form({
                email: '',
                id: this.$store.state.config.data.verifyId ? this.$store.state.config.data.verifyId : null,
            }, {
                email: [{ type: 'required', message: this.$store.state.t.email_verify.missing_email }, { type: 'email' }]
            }),
            userEmail: this.$store.state.config.data.userEmail
        };
    },
    methods: {
        ...mapActions(['notify']),
        changeEmail() {
            const form = this.form.update('verify');
            if (form) {
                this.loading = true;
                form.then((data) => {
                    this.notify({ msg: data.message });
                    this.userEmail = data.newEmail;
                    this.$parent.$emit('hideModal');
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            }
        },
        resendVerify() {
            ajax.create('verify', { id: this.verifyId }).then((data) => {
                this.notify({ msg: data.message });
            }).catch(({ response }) => {
                response.then((data) => {
                    this.notify({ msg: data.message, type: 'error' });
                });
            });
            this.$parent.$emit('hideModal');
        },
        validate(field) {
            this.form.validate(field);
        }
    },
    mounted() {
        if (this.superBlur !== '') {
            const el = document.getElementById(this.superBlur);
            el.classList.add('blur');
        }
    },
    props: {
        superBlur: {
            type: String,
            default: ''
        },
        uniqueId: {
            type: String,
            default: 'changeEmailForm'
        }
    }
};

</script>

<style lang="scss" scoped>
@import "~Site/scss/variables";
    /**
    * Email Verification
    *
    * When a user isn't email verified we need to make sure we're pushing them as fast as we can to
    * do so, so we have to make the form stylised, easy and eye catching.
    */

.modal .verify-email-alert {
    box-shadow: none;
}


.verify-email-alert {
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background-color: #fff;
    .btn {
        display: inline-block;
    }
    > i.fa {
        position: absolute;
        opacity: 0.35;
        font-size: 40px;
        right: 15px;
    }
    h1 {
        font-size: 2em;
    }
    ul {
        list-style: none;
        padding-left: 20px;
        margin-top: 9px;
    }
    p {
        margin-bottom: 0.5em;
    }
    .verify-reasons {
        background: #eee;
        padding: 10px;
        margin-bottom: 10px;
        box-shadow: 2px 2px 3px #ddd;
        ul {
            display: flex;
            flex-wrap: wrap;
        }
        li {
            width: 50%;
        }
    }
    .verify-change-email {
        margin-top: 10px;
    }
    @media (max-width: $screen-sm-max) {
        a {
            width: 100%;
            margin-bottom: 5px;
        }
        .form-control {
            width: 100%
        }
        .btn {
            margin-top: 5px;
        }
        .verify-reasons li {
            width: 100%;
        }
    }
}
</style>
