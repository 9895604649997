import { setStorage, getStorage } from './storage';
import store from '../store';

export default {
    add(page) {
        const levels = [
            'notVerified',
            'Member',
            'Premium'
        ];

        if (levels.indexOf(store.state.siteConfig.userLevel) === -1) {
            return;
        }

        let path = getStorage('upgradePath', true);
        if (!Array.isArray(path)) {
            path = [];
        }

        if (path.length === 5) {
            path.shift();
        }
        path.push(page || document.body.id);

        setStorage('upgradePath', path, true);
    },
    get() {
        return getStorage('upgradePath', true);
    }
};
