<template>
    <div v-if="display" @mouseleave="hide" @mouseover="cancelHide" class="mini-profile popover" :style="{ top: posTop, left: posLeft }">
        <div class="popover-content">
            <loading v-if="loading"></loading>
            <div v-if="!loading" class="mini-profile-body">
                <avatar v-if="!loading" :user="profile" size="medium" :showIcons="false">
                    <i class="fa fa-check-square is-verified" v-if="profile.isVerified"
                        :title="gt.common.verified.replace('%username', profile.userName)"></i>
                </avatar>
                <header>
                    <a v-if="profile.chat" href="#" :title="gt.mini_profile.join_in_chat" class="online" v-open-chat="profile.chat">
                        <i class="fa fa-comment text-success"></i>
                    </a>
                    <a v-else-if="profile.messenger" href="#" :title="gt.mini_profile.send_im" class="online" v-open-messenger="profile.id">
                        <i class="fa fa-wechat text-success"></i>
                    </a>
                    <a v-else-if="profile.links" :href="profile.links.message" @click.prevent="sendMessage">
                        <i class="fa fa-envelope text-muted"></i>
                    </a>
                    <a class="mini-profile-username" :href="profile.links ? profile.links.profile : '#'">{{ profile.userName }}</a>
                    <i v-for="(person, index) in profile.persons" :key="index" v-if="!profile.club" :class="['fa', 'fa-user', person.genderClass]"></i>
                    <div class="btn-group user-menu pull-right">
                        <button type="button" class="btn btn-sm btn-default" v-dropdown>
                            <span class="caret"></span>
                            <span class="sr-only">{{ gt.mini_profile.toggle_dropdown }}</span>
                        </button>
                        <user-menu :user="profile" :metrics="metrics"></user-menu>
                    </div>
                </header>
                <div class="mini-profile-people">
                    <div v-for="(person, index) in profile.persons" v-if="!profile.isClub" :key="index"
                        :class="profile.persons.length > 1 ? 'col-sm-6' : 'single-user'">
                        <h4>
                            <template v-if="person.name"><span class="username">{{person.name}}</span></template>
                        </h4>
                        <p>{{ gt.mini_profile.user_age.replace('%age', person.age) }}</p>
                        <p>{{ person.orientation }}</p>
                        <p>{{ person.height }}<template v-if="person.height">, </template>{{ person.build }}</p>
                    </div>
                </div>
                <div class="mini-profile-location" v-if="profile.location">
                    <div class="location" :title="profile.location"><i class="fa fa-map-marker"></i> {{ profile.location }}</div>
                    <span v-if="profile.distanceUnitShort" class="distance">({{ profile.distance }}{{ profile.distanceUnitShort }})</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ajax from 'Site/js/util/ajax.fetch';
import dropdownDirective from 'Site/directive/dropdown';
import openChatDirective from 'Site/directive/openChat';
import openMessengerDirective from 'Messenger/directive/openMessenger';
import Loading from 'Site/component/Loading.vue';
import popoverPosition from 'Site/js/mixin/popover.position';

import Avatar from './Avatar.vue';
import UserMenu from './UserMenu.vue';

export default {
    name: 'MiniProfile',
    mixins: [popoverPosition],
    components: {
        Avatar,
        Loading,
        UserMenu
    },
    computed: mapState({
        gt: state => state.gt,
        profiles: state => state.profiles.cache,
        currentUserId: state => (state.siteConfig.user.id !== '' ? parseInt(state.siteConfig.user.id, 10) : null)
    }),
    directives: {
        dropdown: dropdownDirective,
        openChat: openChatDirective,
        openMessenger: openMessengerDirective
    },
    data() {
        return {
            display: false,
            emptyProfile: {
                avatartype: '',
                chat: false,
                distance: '',
                genders: '',
                id: 1,
                imageVerified: false,
                isFriend: false,
                isFavourite: false,
                links: {
                    message: '#',
                    photos: '#',
                    profile: '#',
                    wink: '#'
                },
                messenger: false,
                location: '',
                online: false,
                people: [],
                photos: 0,
                username: ''
            },
            hideTimeout: null,
            loading: true,
            metrics: null,
            profile: Object.assign({}, this.emptyProfile),
            showTimeout: null
        };
    },
    methods: {
        cancelHide() {
            clearTimeout(this.hideTimeout);
        },
        lowerCaseGender(gender) {
            return gender.toLowerCase();
        },
        sendMessage() {
            this.$store.dispatch('sendMessage', { recipient: this.profile.userName, metrics: this.metrics });
        },
        show(e, id, metrics = null) {
            if (!id || id === this.currentUserId) {
                return;
            }
            this.metrics = metrics;
            if (this.display && id === this.profile.id) {
                clearTimeout(this.hideTimeout);
                return;
            }

            this.display = false;
            this.setPosition(e, 300, 162);
            clearTimeout(this.showTimeout);
            this.showTimeout = setTimeout(() => {
                if (typeof this.profiles[id] === 'object') {
                    this.profile = this.profiles[id];
                    this.loading = false;
                    this.display = true;
                    return;
                }
                this.loading = true;
                this.display = true;
                ajax.get('profile', { id, dataset: 'miniprofile' }).then((data) => {
                    data.id = id;
                    this.$store.commit('profiles/ADD_PROFILE', data);
                    this.profile = data;
                    this.loading = false;
                }).catch((xhr) => {
                    // User navigated away before we got a response from the server
                    if ((xhr.status === 0 && xhr.readyState === 0) || xhr.status === 403) {
                        this.display = false;
                        return;
                    }
                    this.display = false;
                });
            }, 150);
        },
        hide(e) {
            clearTimeout(this.hideTimeout);
            clearTimeout(this.showTimeout);
            this.hideTimeout = setTimeout(() => {
                if (this.display && e.toElement !== this.$el && !this.$el.contains(e.toElement)) {
                    this.display = false;
                    this.loading = true;
                    this.profile = Object.assign({}, this.emptyProfile);
                    this.metrics = null;
                }
            }, 150);
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.mini-profile.popover {
    max-width: 300px;
    min-height: 162px;
    z-index: 1100;
    width: 300px;
    display: block;
    .popover-content {
        padding: 0;
    }
    .loading {
        margin-top: 35px;
    }
}

.mini-profile .profile-result-avatar {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: -34px 0 -47px 10px;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0 0 2px #666;
    z-index: 2;
    &.online-halo {
        border: none;
        box-shadow: inset 0 0 0 2px #fff, 0 0 0 3px #35af49;
        .profile-image-background div {
            border: 2px solid #fff;
        }
    }
    .profile-image-background {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        z-index: 1;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        div {
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            position: relative;
            border-radius: 50%;
        }
    }
    .profile-image-photos {
        left: 24px;
    }
    .is-verified {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 16px;
        line-height: 0.9;
    }
}

.mini-profile-body {
    width: 100%;
    header {
        background-color: #eee;
        font-size: 16px;
        line-height: 37px;
        padding-left: 100px;
        margin-bottom: 15px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        i {
            vertical-align: text-bottom;
        }
        .mini-profile-username {
            max-width: 115px;
            @include text-overflow();
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }
        .user-menu {
            padding-top: 3px;
            padding-right: 2px;
            button {
                border-top-right-radius: 3px !important;
                border-bottom-right-radius: 3px !important;
            }
        }
    }
    .icon-bar {
        position: relative;
        width: 247px;
        height: 20px;
        background: #ddd;
        top: -15px;
        left: 49px;
        border-top: 1px solid #ddd;
        i {
            margin-left: 50px;
            line-height: 1;
            font-size: 16px;
        }
    }
}

.mini-profile-people {
    height: 71px;
    margin: 15px 0 10px;
    overflow: hidden;
    .col-sm-6, .single-user {
        h4 {
            font-size: 16px;
            font-weight: normal;
            margin: 0;
            min-height: 7px;
            .username {
                @include text-overflow();
                max-width: 100%;
                display: inline-block;
                margin: 0;
                line-height: 12px;
                height: 15px;
                margin-bottom: -3px;
            }
        }
    }
    .single-user {
        margin-left: 100px;
    }
    p {
        margin: 0;
        font-weight: 300;
    }
}

.mini-profile-location {
    border-top: 1px solid #ddd;
    padding: 0px 10px;
    font-weight: 300;
    line-height: 1.9;
    height: 25px;
    .location {
        @include text-overflow();
        max-width: 205px;
        display: inline-block;
        float: left;
        padding-right: 5px;
    }
    .distance {
        float: right;
        padding-right: 10px;
    }
}

/****
*
* - Language Specific SCSS
*
****/

.de_DE {
    .mini-profile-username {
        max-width: 100px;
    }
}

</style>
