import { pick, sortBy, uniq } from 'underscore';
import store from 'Site/js/store';

function Formatter(parts, formatted) {
    this.formatted = false;
    this.parts = [];

    // rank address parts from most granular to least granular
    // anything else will be stripped
    this.ranking = [
        'sublocality',
        'political',
        'locality',
        'postal_town',
        'country',
    ];

    if (parts) {
        this.setAddressParts(parts);
    }

    if (formatted) {
        this.setFormatted(formatted);
    }
}

Formatter.prototype = {
    setFormatted(formatted) {
        this.formatted = formatted;
    },

    setAddressParts(parts) {
        // only use first half of postcode, if full postcode is present
        /* eslint camelcase:0 */
        if (typeof parts.postal_code !== 'undefined') {
            if (parts.postal_code.indexOf(' ') !== -1) {
                const postCodeParts = parts.postal_code.split(' ');
                /* eslint prefer-destructuring: 0 */
                parts.postal_code = postCodeParts[0];
            }
        }

        const stripped = pick(parts, this.ranking);

        if (stripped.postal_town) {
            delete stripped.political;
        }

        if (stripped.country && stripped.country === store.state.siteConfig.data.country) {
            delete stripped.country;
        }

        const sorted = sortBy(stripped, (value, key) => this.ranking.indexOf(key));

        this.parts = uniq(sorted);

        return this;
    },

    getAddressParts() {
        return this.parts;
    },

    getAvailableFormats() {
        const formats = [];

        this.parts.reverse().forEach((part, i) => {
            let format = part;

            if (typeof formats[i - 1] !== 'undefined') {
                format = `${format}, ${formats[i - 1]}`;
            }

            formats.push(format);
        });

        if (this.formatted) {
            formats.push(this.formatted);
        }

        return uniq(formats.reverse());
    }
};

export default Formatter;
