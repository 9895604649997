<template>
<button type="button" :class="['btn', 'btn-default', 'like', { 'liked': likeId, 'site-bg': likeId, 'btn-xs': small }]"
    @click.stop.prevent="clickHandler">
    <i :class="['sh-icon', iconClass]"></i> {{ likeStatus }}
</button>
</template>

<script>
import { mapState } from 'vuex';
import ajax from 'Site/js/util/ajax.fetch';

export default {
    name: 'Like',
    computed: {
        ...mapState(['gt']),
        likeStatus() {
            return this.likeId ? this.gt.common.liked : this.gt.common.like;
        }
    },
    data() {
        return {
            likeId: this.id,
            iconClass: '',
            sending: false
        };
    },
    methods: {
        clickHandler() {
            if (this.sending) {
                return false;
            }
            if (!this.likeId && this.unlikeable) {
                this.like();
                this.likeId = 1;
                this.iconClass = 'animate-unlike';
            } else if (this.likeId) {
                this.unlike();
                this.likeId = null;
                this.iconClass = 'animate-like';
            }
            setTimeout(() => {
                this.iconClass = '';
            }, 750);
            this.$el.blur();

            return false;
        },
        like() {
            this.sending = true;
            ajax.create('feedback', {
                type: this.type,
                foreign: this.foreign,
                rating: 5
            }).then((response) => {
                this.sending = false;
                if (this.foreign === response.item.foreign) {
                    this.likeId = response.id;
                    this.$emit('liked', this.likeId);
                }
            });
        },
        unlike() {
            this.sending = true;
            ajax.update('feedback', {
                id: this.likeId,
                type: this.type,
                foreign: this.foreign,
                like: 'delete'
            }).then(() => {
                this.sending = false;
            });
            this.$emit('liked', null);
        }
    },
    watch: {
        id(val) {
            this.likeId = val;
        },
        foreign() {
            this.likeId = this.id;
        }
    },
    props: {
        foreign: Number,
        id: [Number, Boolean],
        type: String,
        small: {
            type: Boolean,
            default: false
        },
        unlikeable: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss">
@import "~Site/scss/variables";

@keyframes like {
  from {
    transform: scale3d(1, 1, 1)  rotate(0deg);
  }

  12.5% {
    transform: scale3d(2.25,2.25,2.25) rotate(45deg);
  }

  25% {
    transform: scale3d(3.5,3.5,3.5) rotate(90deg);
  }

  37.5% {
    transform: scale3d(4.75,4.75,4.75) rotate(135deg);
  }

  50% {
    transform: scale3d(6, 6, 6) rotate(180deg);
  }

  62.5% {
    transform: scale3d(4.75,4.75,4.75) rotate(225deg);
  }

  75% {
    transform: scale3d(3.5,3.5,3.5) rotate(270deg);
  }

  87.5% {
    transform: scale3d(2.25,2.25,2.25) rotate(315deg);
  }

  to {
    transform: scale3d(1, 1, 1) rotate(360deg);
  }
}

.animate-like {
  animation-name: like;
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-direction: reverse;
  width: 88px;
}

.animate-unlike {
  animation-name: like;
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-direction: normal;
  width: 80px;
}

.sh-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-size: 100%;
  background-image: url('../images/spiral.png');
}

.sh-icon-lg {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}

.like-count .sh-icon {
  vertical-align: text-bottom;
}

.like .sh-icon {
  width: 24px;
  height: 24px;
  background-image: url('../images/spiral@2x.png');
}

.like i {
  margin: -3px 3px -8px -3px;
}

.btn-xs.like {
  .sh-icon {
    width: 18px;
    height: 18px;
    margin: 0 3px -6px -3px;
    vertical-align: top;
  }
  width: 68px;
  &.liked {
    width: 68px;
  }
}

.like {
  transition: background-color 400ms, color 400ms, width 200ms;
  transition-delay: 250ms;
  outline: 0 !important;
  overflow: hidden;
  width: 88px;
  &.liked {
    color: #fff;
    width: 88px;
  }
  &.liked:hover {
      color: #FFF;
  }
}

/****
*
* - Language Specific SCSS
*
****/

.es_ES, .it_IT {
  .like {
    width: 100px;
    &.liked {
      width: 100px;
    }
  }
}

</style>
