import { mapState } from 'vuex';
import '../../scss/coverimage.header.scss';
import CoverImagePicker from '../../component/CoverImagePicker.vue';
import Modal from '../../component/Modal.vue';

/**
 * @mixin
 */
export default {
    components: {
        CoverImagePicker,
        Modal
    },
    computed: {
        ...mapState({
            coverImages: state => state.config.data.themes,
            headerBackground(state) {
                if (this.coverImage) {
                    return this.coverImage;
                }
                if (window.innerWidth > 1100 && state.config.data.coverLarge) {
                    return state.config.data.coverLarge;
                }
                if (window.innerWidth > 600 && state.config.data.coverMedium) {
                    return state.config.data.coverMedium;
                }
                return state.config.data.cover;
            },
            id: state => state.config.data.id,
            t: 't'
        }),
        headerStyle() {
            return `background-image: url(${this.headerBackground})`;
        }
    },
    created() {
        // Start loading image as early as possible
        const image = new Image();
        image.src = this.headerBackground;
    },
    data: {
        coverImage: '',
        showCoverImageModal: false
    },
    methods: {
        changeCover() {
            this.showCoverImageModal = true;
        }
    }
};
