<template>
    <div class="coverimage-picker">
        <ul class="nav nav-tabs modal-nav">
            <li v-for="page in pages" :key="page.name" :class="{ 'active': currentPage === page.name }">
                <a class="site-bg" href="#" @click.prevent="changePage(page.name)"><i :class="['fa', 'fa-' + page.icon]"></i> {{ page.text }}</a>
            </li>
        </ul>
        <div v-if="currentPage === 'cover' && mycoverimages.length">
            <div v-for="coverimage in mycoverimages" :key="coverimage.id" class="avatar-modal-site-image"
            :style="`background-image: url('${coverimage.media.mediumcover ? coverimage.media.mediumcover.url : coverimage.media.smallcover.url}')`"
                @click="select(coverimage)">
            </div>
        </div>
        <div v-else-if="currentPage === 'photos' && myphotos.length" class="coverimage-picker-photos">
            <media v-for="photo in myphotos" :key="photo.id" :media="photo" @click.native="select(photo)"></media>
        </div>
        <div v-else-if="currentPage === 'site'">
            <input type="text" class="form-control" :placeholder="t.search_for_a_site_image" v-model="searchTerm">
            <div v-for="coverimage in filteredCoverImages" :key="coverimage.name"
                :class="['avatar-modal-site-image', {'extras': !coverimage.allowed}]"
                :style="`background-image: url('${coverUrl.replace('REPLACE', coverimage.name)}/small.jpg')`" @click="select(coverimage)">
                <extras-logo v-if="!coverimage.allowed"></extras-logo>
                <h2>{{ coverimage.title }}</h2>
            </div>
        </div>
        <mediaUpload v-else-if="currentPage === 'upload'" :album="coverAlbum" :set="7" mediaType="photo" :cover="{ id, type }"
            :maxFiles="1" :maxSetFiles="1" @media-upload-complete="uploadComplete"></mediaUpload>
        <span v-else-if="currentPage === 'cover' && !loading">{{ t.cover_image_no_images }}</span>
        <span v-else-if="currentPage === 'photos' && !loading">{{ t.cover_image_no_photos }}</span>
        <loading v-if="loading"></loading>
    </div>
</template>

<script>
import ExtrasLogo from 'Site/component/ExtrasLogo.vue';
import Media from 'Media/component/Media.vue';
import MediaUpload from 'Media/component/MediaUpload.vue';
import { mapActions, mapState } from 'vuex';
import ajax from '../js/util/ajax.fetch';
import Loading from './Loading.vue';

export default {
    name: 'CoverImagePicker',
    components: {
        ExtrasLogo,
        Loading,
        Media,
        MediaUpload
    },
    computed: {
        ...mapState(['config', 't', 'siteConfig']),
        coverUrl() {
            return this.$store.state.config.data.coverUrl;
        },
        filteredCoverImages() {
            if (!this.searchTerm) {
                return this.coverimages;
            }
            return this.coverimages.filter(coverImage => coverImage.name.indexOf(this.searchTerm) !== -1);
        },
        pages() {
            const pageArray = [
                { name: 'site', text: this.t.site_images, icon: 'image' },
                { name: 'cover', text: this.t.cover_images, icon: 'user' },
                { name: 'photos', text: this.t.my_photos, icon: 'user' }
            ];
            if (this.showUpload) {
                pageArray.push({ name: 'upload', text: this.t.upload, icon: 'upload' });
            }
            return pageArray;
        },
        userId() {
            return this.user || this.siteConfig.user.id;
        },
    },
    data() {
        return {
            coverAlbum: null,
            currentPage: 'site',
            loading: false,
            mycoverimages: [],
            myphotos: [],
            searchTerm: ''
        };
    },
    methods: {
        ...mapActions(['showUpgrade']),
        changePage(page) {
            this.currentPage = page;

            if (page === 'photos' && !this.myphotos.length) {
                this.fetch('photos');
            } else if (page === 'cover' && !this.mycoverimages.length) {
                this.fetch('cover');
            } else if (page === 'upload' && !this.coverAlbum) {
                ajax.get('album', { type: 1, user: this.userId }).then((albums) => {
                    const coverAlbum = albums.filter(album => album.type === 1)[0];
                    this.coverAlbum = coverAlbum.id;
                });
            }
        },
        fetch(type) {
            this.loading = true;
            ajax.get('media', {
                user: this.userId,
                set: type === 'cover' ? 7 : 1,
                approved: 1,
                deleted: 0,
                adult: 0,
                public: 1,
                type: type === 'cover' ? 1 : 0
            }).then(({ items }) => {
                this.loading = false;
                if (type === 'photos') {
                    this.myphotos = items;
                } else {
                    this.mycoverimages = items;
                }
            });
        },
        /** Triggered after a new image is selected or uploaded
         * @event changecoverimage
         * @type {Object}
         */
        select(coverimage) {
            if (this.currentPage === 'site' && !coverimage.allowed) {
                this.showUpgrade({ reason: 'upgrade-coverimage' });
            } else {
                if (this.currentPage === 'site') {
                    coverimage.url = `${this.coverUrl.replace('REPLACE', coverimage.name)}/large.jpg`;
                    coverimage.site = true;
                } else if (this.currentPage === 'cover') {
                    coverimage.url = coverimage.media.mediumcover ? coverimage.media.mediumcover.url : coverimage.media.smallcover.url;
                    coverimage.site = false;
                } else {
                    coverimage.url = coverimage.media.large.url;
                    coverimage.site = false;
                }
                this.$emit('changecoverimage', coverimage);
            }
        },
        uploadComplete(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const coverimage = {
                    upload: true,
                    url: e.target.result
                };
                this.$emit('changecoverimage', coverimage);
            };
            reader.readAsDataURL(file);
        }
    },
    props: {
        coverimages: {
            type: Array,
            required: true
        },
        showUpload: {
            type: Boolean,
            default: true
        },
        id: {
            type: Number,
        },
        type: {
            type: String
        },
        user: Number
    }
};

</script>

<style lang="scss">
.coverimage-picker input {
    margin-bottom: 10px;
}
.coverimage-picker-photos {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}
.avatar-modal-site-image {
    height: 150px;
    margin-bottom: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .extras-logo {
        position: absolute;
        right: -48px;
        top: 13px;
        font-weight: bold;
        background: #fff;
        width: auto;
        text-align: center;
        transform: rotate(45deg);
        font-size: 1.5em;
        padding: 0px 42px;
    }
    h2 {
        color: #fff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
        font-size: 2em;
        font-weight: 300;
        padding: 10px;
    }
}
</style>
