<template>
    <div class="age-verification-modal">
        <div class="center-block text-center">
            <span class="icon-18">18+</span>
        </div>
        <p v-html="gt.introduction"></p>
        <p v-html="gt.statement"></p>
        <ul>
            <li v-for="(reason, index) in reasons" :key="index">{{ reason }}</li>
        </ul>
        <button class="btn btn-success btn-block btn-xl" @click.prevent="agree">{{ gt.agree }}</button>
        <a href="https://www.google.com" class="btn btn-default btn-block btn-sm">{{ gt.disagree }}</a>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { setCookie } from 'Site/js/util/cookie';

export default {
    name: 'AgeVerification',
    computed: {
        ...mapState({
            gt: state => state.gt.site.age_verify,
        }),
        reasons() {
            return this.gt.reasons;
        }
    },
    methods: {
        agree() {
            setCookie('ageVerification', true);
            this.$store.state.ageVerify.show = false;
        }
    }
};
</script>

<style lang="scss">
@import "../scss/variables";

.age-verification-modal {
    padding: 30px;
    background-image: url(../images/verification-background.jpg);
    background-size: cover;
    background-position: center;
    background-color: #2e3335; 
    color: #fff;
    height: 100%;
    p, ul {
        font-size: 1.2em;
        margin: 10px 0;
    }
    .btn-xl {
        padding: 10px;
        font-size: 1.8em;
        margin-bottom: 10px;
        margin-top: 35px;
    }
    .icon-18 {
        border: 4px solid #b13131;
        border-radius: 50%;
        padding: 4px 2px;
        color: #000;
        background-color: #fff;
        margin-right: 10px;
        font-weight: 700;
        font-size: 1.6em;
        display: inline-block;
    }
}
</style>
