<template>
<div class="event-small">
    <div class="event-time">
        <div class="month site-bg-light">{{ event.date.month }}</div>
        <div class="date site-bg">{{ event.date.day }}</div>
    </div>
    <span class="event-title">{{ event.title }}</span>
    <slot name="body"></slot>
    <div class="event-icons pull-right">
        <slot></slot>
        <i :class="['fa', event.typeIcon]" :title="event.type"></i>
        <i class="fa fa-users" :title="event.attendees"></i> {{ event.attendeeCount }}
    </div>
</div>
</template>

<script>
export default {
    name: 'EventSmall',
    props: {
        event: {
            type: Object,
            required: true
        }
    }
};

</script>

<style lang="scss">
.event-small {
    clear: both;
    border: 1px solid #eee;
    .event-time {
        width: 40px;
        float: left;
    }
    .event-title, .event-icons {
        line-height: 43px;
        padding: 0 10px;
    }
    .event-title {
        font-size: 1.2em;
    }
}
</style>
