export default {
    bind(el) {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            [...document.getElementsByClassName('dropdown'), ...document.getElementsByClassName('btn-group')].forEach((element) => {
                if (element !== el.parentElement) {
                    element.classList.remove('open');
                }
            });
            el.parentElement.classList.toggle('open');
        });
        document.querySelector('html').addEventListener('click', (e) => {
            if (el.contains(e.target)) {
                return;
            }
            if (el.parentElement) {
                el.parentElement.classList.remove('open');
            }
        });
    }
};
