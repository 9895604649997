import Icon from 'vue-awesome/components/Icon.vue';
import JoinForm from 'User/component/JoinForm.vue';
import LoginForm from 'User/component/LoginForm.vue';
import MediaPicker from 'Media/component/MediaPicker.vue';
import MediaViewer from 'Media/component/MediaViewer.vue';
import Message from 'Messaging/component/Message.vue';
import MiniProfile from 'Profile/component/MiniProfile.vue';
import Report from 'Abuse/component/Report.vue';
import Vue from 'vue';
import miniprofileDirective from 'Profile/directive/miniprofile';
import { mapActions, mapState } from 'vuex';

import AgeVerification from './component/AgeVerification.vue';
import Modal from './component/Modal.vue';
import Notifies from './component/Notifies.vue';
import SiteInvite from './component/SiteInvite.vue';
import SiteUpgrade from './component/SiteUpgrade.vue';

import layout from './js/layout';
import legal from './js/page/legal';
import pleaseVerify from './js/page/pleaseVerify';
import proIcons from './js/lib/icons';
import registerServiceWorker from './js/registerServiceWorker';
import socket from './js/util/socket';
import store from './js/store';
import upgrade from './js/page/upgrade';
import upgradePath from './js/util/upgradePath';
import { getCookie } from './js/util/cookie';


import collapseDirective from './directive/collapse';
import dropdownDirective from './directive/dropdown';

Vue.config.productionTip = false;

export default function () {

    if (store.state.siteConfig.enableNotifications) {
        socket.init();
    }
    layout();

    // Upgrade page entrypoint
    const currentPage = document.querySelector('body').getAttribute('id');
    const sitePages = {
        'join-please-verify': pleaseVerify,
        'legal-privacy': legal,
        'legal-tac': legal,
        upgrade,
    };
    if (typeof sitePages[currentPage] === 'function') {
        sitePages[currentPage]();
    }

    // Add current page to upgrade path
    upgradePath.add();

    // Add Font Awesome 5 support
    Vue.component('site-icon', Icon);
    proIcons.import();

    if (document.querySelector('#vue-wrapper')) {
        new Vue({ // eslint-disable-line no-new
            components: {
                AgeVerification,
                JoinForm,
                LoginForm,
                MediaPicker,
                MediaViewer,
                Message,
                MiniProfile,
                Modal,
                Notifies,
                Report,
                SiteInvite,
                SiteUpgrade
            },
            directives: {
                collapse: collapseDirective,
                dropdown: dropdownDirective,
                miniprofile: miniprofileDirective
            },
            el: '#vue-wrapper',
            computed: mapState([
                'confirm', 'join', 'login', 'ageVerify', 'mediaPicker', 'mediaviewer', 'message', 'miniprofile', 'report', 'siteInvite', 'upgrade'
            ]),
            methods: mapActions(['showJoin']),
            mounted() {
                if (!getCookie('ageVerification') && store.state.siteConfig.siteType === 'story') {
                    this.$store.dispatch('showAgeConfirmation');
                }
            },
            store
        });
    }

    registerServiceWorker();
}
