import { mapState } from 'vuex';

import ajax from 'Site/js/util/ajax.fetch';

export default {
    computed: mapState({
        friendRequestUrl: state => state.siteConfig.endpoints.request
    }),
    data() {
        return {
            loading: false,
            dataUser: this.user
        };
    },
    methods: {
        error({ response }) {
            response.then((error) => {
                this.$store.dispatch('notify', { msg: error.message, type: 'error' });
                this.loading = false;
            });
        },
        favourite(action) {
            if (this.loading) {
                return;
            }
            this.loading = true;
            ajax[action](this.user.links.favourite, { id: this.user.id }).then(this.success).then(() => {
                this.dataUser.isFavourite = !this.dataUser.isFavourite;
                this.loading = false;
            }).catch(this.error);
        },
        friend(action, user = null) {
            if (this.loading) {
                return false;
            }

            let url = this.friendRequestUrl;
            if (user) {
                this.dataUser = user;
            }
            this.loading = true;

            switch (action) {
                default:
                case 'accept':
                    return ajax.update(url, { id: this.dataUser.id }).then(this.success).then(() => {
                        this.dataUser.isFriend = true;
                        this.loading = false;
                    }).catch(this.error);
                case 'add':
                    return ajax.create(url, { id: this.dataUser.id }).then(this.success).then(() => {
                        this.dataUser.isFriend = 'pendingFromMe';
                        this.loading = false;
                    }).catch(this.error)
                        .catch((response) => {
                            const upgrade = { reason: 'more-friend-requests' };
                            switch (response.status) {
                                case 402:
                                    if (this.metrics) {
                                        upgrade.metrics = this.metrics;
                                    }
                                    this.$store.dispatch('showUpgrade', upgrade);
                                    return true;
                                default:
                                    return false;
                            }
                        });
                case 'cancel':
                    url += `/${this.dataUser.id}?type=0`;
                    return ajax.delete(url).then(this.success).then(() => {
                        this.dataUser.isFriend = false;
                        this.loading = false;
                    }).catch(this.error);
                case 'deny':
                    url += `/${this.dataUser.id}?type=1`;
                    return ajax.delete(url).then(this.success).then(() => {
                        this.dataUser.isFriend = false;
                        this.loading = false;
                    }).catch(this.error);
            }
        },
        success(response) {
            this.$store.dispatch('notify', { msg: response.message });
        },
        wink() {
            ajax.create(this.user.links.wink, { id: this.user.id }).then(this.success).catch(({ response }) => {
                const upgrade = { reason: 'more-winks' };
                if (this.metrics) {
                    upgrade.metrics = this.metrics;
                }
                response.then(() => {
                    switch (response.status) {
                        case 402:
                            this.$store.dispatch('showUpgrade', upgrade);
                            break;
                        default:
                            this.$store.dispatch('notify', { msg: response.message, type: 'error' });
                            break;
                    }
                });
            });
        }
    }
};
