<template>
<ul class="dropdown-menu">
    <loading v-if="loading"></loading>
    <template v-if="!loading">
        <li v-if="viewProfile && user.links.profile">
            <a :href="user.links.profile">{{ gt.view_profile }}</a>
        </li>
        <li v-if="user.id !== currentUserId">
            <a href="#" @click.prevent="sendMessage({recipient: user.userName, metrics})">{{ gt.send_message }}</a>
        </li>
    </template>
    <template v-if="!loading && user.links && user.id !== currentUserId">
        <li>
            <a href="#" @click.prevent="wink" @click="logClick('wink')">{{ gt.usermenu.send_wink }}</a>
        </li>
        <li v-if="dataUser.isFriend === 'pendingFromMe'">
            <a href="#" @click.prevent="friend('cancel')">{{ gt.usermenu.cancel_friend_request }}</a>
        </li>
        <li v-if="dataUser.isFriend === 'pendingToMe'">
            <a href="#" @click.prevent="friend('accept')">{{ gt.usermenu.accept_friend_request }}</a>
        </li>
        <li v-if="dataUser.isFriend === 'pendingToMe'">
            <a href="#" @click.prevent="friend('deny')">{{ gt.usermenu.deny_friend_request }}</a>
        </li>
        <li v-if="!dataUser.isFriend">
            <a href="#" @click.prevent="friend('add')" @click="logClick('friend')">{{ gt.usermenu.friend_request }}</a>
        </li>
        <li v-if="dataUser.isFavourite">
            <a href="#" @click.prevent="favourite('delete')" @click="logClick('follow')">{{ gt.usermenu.unfollow }}</a>
        </li>
        <li v-if="!dataUser.isFavourite">
            <a href="#" @click.prevent="favourite('create')" @click="logClick('follow')">{{ gt.usermenu.follow }}</a>
        </li>
    </template>
</ul>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Loading from 'Site/component/Loading.vue';

import { logActivityClick } from 'Site/js/util/metrics';
import userActions from '../mixin/user.actions';

export default {
    name: 'UserMenu',
    components: {
        Loading
    },
    computed: {
        ...mapState({
            currentUserId: state => state.siteConfig.user.id !== "" ? parseInt(state.siteConfig.user.id) : null,
            gt: state => state.gt.mini_profile
        })
    },
    methods: {
        ...mapActions(['sendMessage']),
        logClick(target) {
            if (this.metrics) {
                logActivityClick(
                    this.metrics.actor[0].id,
                    `mini-profile-${target}`,
                    this.metrics.action,
                    this.metrics.system
                );
            }
        }
    },
    mixins: [userActions],
    props: {
        user: Object,
        metrics: {
            type: Object,
            default: null
        },
        viewProfile: {
            type: Boolean,
            default: false
        }
    }
};

</script>
