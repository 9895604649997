<template>
<a :href="link" @click="clickHandler"
    :class="['profile-result', 'compact-user', { 'selected': selectable && selected, 'selectable': selectable }]">
    <span class="fa-stack fa-lg" v-if="selected">
        <i class="fa fa-circle fa-stack-2x"></i>
        <i class="fa fa-check fa-stack-1x fa-inverse"></i>
    </span>
    <slot></slot>
    <avatar v-if="user.avatartype" :user="user" size="medium"></avatar>
    <div class="compact-user-details">
        <span class="username" v-if="miniprofile" v-miniprofile="user.id">{{ user.userName }}</span>
        <span class="username" v-else>{{ user.userName }}</span>
    </div>
</a>
</template>
<script>
import miniProfileDirective from 'Profile/directive/miniprofile';
import Avatar from './Avatar.vue';

export default {
    name: 'CompactUser',
    components: {
        Avatar
    },
    computed: {
        link() {
            if (!this.linkable || !this.user.links.profile) {
                return '#';
            }
            return this.user.links.profile;
        }
    },
    data() {
        return {
            selected: false
        };
    },
    directives: {
        miniprofile: miniProfileDirective
    },
    methods: {
        clickHandler(e) {
            if (this.selectable) {
                e.preventDefault();
                if (this.selected) {
                    this.$emit('userUnselected', this.user);
                } else {
                    this.$emit('userSelected', this.user);
                }
                this.selected = !this.selected;
            }
        }
    },
    props: {
        linkable: {
            type: Boolean,
            default: false
        },
        miniprofile: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        user: Object
    }
};

</script>
<style lang="scss">
@import "~Site/scss/variables";

a.compact-user {
    height: 50px;
    width: 170px;
    margin-left: 7px;
    margin-right: 7px;
    position: relative;
    .fa-stack {
        position: absolute;
        right: -10px;
        top: -5px;
        z-index: 2;
        .fa-circle {
            color: $brand-primary;
        }
    }
    .profile-result-avatar {
        height: 50px;
        width: 50px;
        float: left;
        .profile-image-background {
            height: 50px;
            width: 50px;
            div {
                height: 50px;
                width: 50px;
            }
        }
    }
}

.compact-user-details {
    float: left;
    padding: 15px 10px;
    .username {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100px;
        float: left;
        transition: none;
    }
    .profile-result-user-icons {
        padding: 0;
    }
}
</style>
