<template>
    <div>
        <form v-if="imageVerified" @submit.prevent="submit" @keydown="validate($event.target.name)">
            <p>{{ gt.site_invite.invite_new_person }}</p>
            <div :class="['form-group', { 'has-error': form.errors.has('email') }]">
                <input name="email" class="form-control" :placeholder="gt.assets.form.enter_valid_email" v-model="form.email">
                <span class="help-block" v-if="form.errors.has('email')">{{ form.errors.get('email') }}</span>
            </div>
        </form>
        <template v-else>
            <p>{{ gt.site_invite.require_image_verify }}</p>
        </template>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Form from '../js/util/form';

export default {
    name: 'SiteInvite',
    computed: mapState(['gt']),
    data() {
        return {
            form: new Form({
                email: ''
            }, {
                email: [
                    { type: 'email' }
                ]
            })
        };
    },
    methods: {
        validate(field) {
            this.form.errors.clear(field);
            this.form.validate(field);
        },
        submit() {
            if (this.imageVerified) {
                const form = this.form.create(this.endpoint);
                if (form) {
                    form.then((response) => {
                        this.$parent.$emit('hideModal');
                        this.$store.dispatch('notify', { msg: response.message });
                    });
                }
            } else {
                window.location.href = this.imageVerifyEndpoint;
            }
        }
    },
    mounted() {
        this.$on('submit', this.submit);
    },
    props: {
        endpoint: String,
        imageVerified: Boolean,
        imageVerifyEndpoint: String
    }
};
</script>
