<template>
    <input @change="newDate" class="form-control" :type="this.time ? 'text' : 'date'" :value="this.value">
</template>

<script>
import $ from 'jquery';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default {
    name: 'DatePicker',
    data() {
        return {
            dateFormat: this.format
        };
    },
    mounted() {
        this.$nextTick(() => {
            const config = {
                startDate: this.value
            };
            if (this.time) {
                config.timePicker = true;
                config.timePicker24Hour = true;
            }
            config.locale = {
                format: this.format
            };
            if (!this.range) {
                config.singleDatePicker = true;
            } else {
                this.dateFormat = `${this.format} - ${this.format}`;
            }
            const dateTypeSupport = document.createElement('input');
            dateTypeSupport.setAttribute('type', 'date');
            if (dateTypeSupport.type === 'text' || this.time) {
                import(/* webpackChunkName: "daterangepicker" */ 'bootstrap-daterangepicker').then(() => {
                    $(this.$el).daterangepicker(config).on('apply.daterangepicker', (ev, picker) => {
                        this.$emit('input', picker.startDate.format(this.dateFormat));
                    });
                });
            }
        });
    },
    methods: {
        newDate(e) {
            this.$emit('input', e.target.value);
        }
    },
    props: {
        format: {
            type: String,
            default: 'DD/MM/YYYY H:mm'
        },
        value: String,
        time: {
            type: Boolean,
            default: false
        },
        range: {
            type: Boolean,
            deafult: false
        }
    }
};
</script>
