<template>
    <div class="col-xs-6 text-center regularOffer">
        <div class="panel panel-plain panel-pricing">
            <!-- Upgrade type -->
            <div class="panel-heading">
                <h4>{{ t.premium_membership }}</h4>
                <span>{{ t.standard_access }}</span>
            </div>
            <!-- Upgrade price and info -->
            <div class="panel-body text-center">
                <!-- Upgrade info placeholder -->
                <div class="placeholder">{{ t.currently_have_membership }}</div>
            </div>
            <!-- Upgrade button -->
            <div class="panel-footer">
                <!-- Disabled upgrade button placeholder -->
                <label class="btn btn-lg btn-default" disabled="disabled">{{ t.current }}</label>
            </div>
            <!-- Upgrade features @todo move to db -->
            <ul class="list-group text-center no-float upgrade-list">
                <li class="list-group-item upgrade-highlight"><i class="fa fa-check text-success"></i> {{ t.reasons.messaging }}</li>
                <li class="list-group-item upgrade-highlight swing"><i class="fa fa-check text-success"></i> {{ t.reasons.adult_media }}</li>
                <li class="list-group-item upgrade-highlight"><i class="fa fa-check text-success"></i> {{ t.reasons.full_chat_access }}</li>
                <li class="list-group-item upgrade-highlight">
                    <i class="fa fa-check text-success"></i><span v-html="t.reasons.chat_cams.replace('%count', 2)"></span>
                </li>
                <li v-if="appName === 'lush'" class="list-group-item upgrade-highlight">
                    <i class="fa fa-check text-success"></i> {{ t.reasons.no_adverts }}
                </li>
                <li v-if="appName !== 'lush'" class="list-group-item">
                    <i class="fa fa-times text-danger"></i> {{ t.reasons.multi_couple_chat }}
                </li>
                <li class="list-group-item">
                    <i class="fa fa-times text-danger"></i> {{ t.reasons.large_media }}
                </li>
                <li class="list-group-item">
                    <i class="fa fa-times text-danger"></i> {{ t.reasons.profile_views }}
                </li>
                <li class="list-group-item">
                    <i class="fa fa-times text-danger"></i> {{ t.reasons.activity_settings }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SiteUpgradeCurrent',
    computed: {
        ...mapState({
            appName: state => state.siteConfig.siteShortCode,
        }),
        t() {
            return this.localConfig.translate;
        }
    },
    props: {
        localConfig: {
            type: Object,
            required: true
        }
    }
};
</script>
