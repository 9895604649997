<template>
<div class="sh-checkboxes">
    <template v-for="(option, index) in options">
        <input v-if="type === 'radio'" :id="'cb' + _uid + 'i' + index" :key="`input1-${index}`" type="radio" v-model="newValue" :value="option.value">
        <input v-if="type === 'checkbox'" :id="'cb' + _uid + 'i' + index" :key="`input2-${index}`" type="checkbox" v-model="newValue"
            :value="option.value">
        <label :for="'cb' + _uid + 'i' + index" :key="`label-${index}`">{{ option.text || option.label }}</label>
    </template>
</div>
</template>

<script>
export default {
    name: 'CheckBoxes',
    computed: {
        newValue: {
            get() { return this.value; },
            set(v) { this.$emit('input', v); }
        }
    },
    props: {
        options: Array,
        type: {
            type: String,
            default: 'checkbox',
            validator(value) {
                return [
                    'checkbox',
                    'radio'
                ].indexOf(value) !== -1;
            }
        },
        value: [Array, Number]
    }
};
</script>

<style lang="scss">
@import "~Site/scss/variables";

.has-error .sh-checkboxes {
    label {
        border: 1px solid $brand-danger;
    }
}
.sh-checkboxes {
    input {
        display: none;
        & + label {
            width: 160px;
            height: 20px;
            font-size: 0.9em;
            line-height: 2px;
            padding: 15px 10px;
            margin-right: 10px;
            border-radius: 4px;
            border: 1px solid #ccc;
            background-color: #eee;
            opacity: 0.9;
            cursor: pointer;
            user-select: none;
        }
        &:checked + label {
            padding: 15px 3px;
            background-color: #fff;
            opacity: 1;
            &::before {
                font: normal normal normal 14px/0 FontAwesome;
                content: '\f00c ';
                padding-right: 5px;
            }
        }
    }
}
</style>
