<template>
<li>
    <a class="notification" :id="notification.id" :href="notification.target.url" @click="clickHandler">
        <avatar :user="user" size="medium"></avatar>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop.prevent="clear">
            <span aria-hidden="true">&times;</span>
        </button>
        <span :class="{'notify-has-user': user}">
            <i class="fa fa-check-square is-verified" v-if="notification.actor.isVerified"
            :title="gt.common.verified.replace('%username', notification.actor.userName)"></i> {{ noticePhrase }}
            <br>
            <time class="published">
                {{ notification.prettyPublished }}
            </time>
        </span>
        <div v-if="buttons" class="notify-buttons">
            <button v-for="(button, index) in buttons" :key="index"
                :class="['btn', 'btn-sm', 'btn-' + button.class]" @click.stop.prevent="buttonClick(button)">
                {{ button.text }}
            </button>
        </div>
    </a>
</li>
</template>

<script>
import Avatar from 'Profile/component/Avatar.vue';
import { mapState } from 'vuex';

import notificationUtil from '../js/util/notification';

export default {
    name: 'Notification',
    components: {
        Avatar
    },
    computed: {
        ...mapState({
            gt: state => state.gt
        }),
        buttons() {
            return notificationUtil.getButtons(this.notification);
        },
        noticePhrase() {
            return this.notification.phrase.single.replace('[USERNAME]', this.user.userName);
        },
        user() {
            const actor = !Array.isArray(this.notification.actor) ? this.notification.actor : this.notification.actor[0];
            delete actor.links;
            return actor;
        }
    },
    methods: {
        buttonClick(button) {
            if (typeof button.callback === 'function') {
                button.callback();
            }
            this.clear();
        },
        clear() {
            notificationUtil.clear(this.notification);
        },
        clickHandler() {
            this.clear();
            if (this.notification.action === 'photoComments' ||
                this.notification.action === 'videoComments' ||
                this.notification.action === 'mediaAuth' ||
                this.notification.action === 'videoLikes' ||
                this.notification.action === 'photoLikes' ||
                this.notification.action === 'followingMedia') {
                this.$emit('mediaviewer', this.notification);
                return false;
            }
            return true;
        }
    },
    props: {
        notification: Object
    }
};

</script>

<style lang="scss">
.notify-has-user {
    .is-verified {
        line-height: 11px;
    }
}
.notification {
    overflow-wrap: break-word;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;

    &:hover .close {
        color: #fff;
    }

    .close {
        padding: 9px;
        margin-top: -10px;
        margin-right: -8px;
    }

    .profile-result-avatar {
        height: 40px;
        width: 40px;
        border-radius: 2px;
        padding: 0;
        float: left;
        margin-right: 10px;
        a {
            padding: 0;
        }
        div {
            height: 40px;
            width: 40px;
            border-radius: 2px;
            padding: 0;
        }
    }
    .notify-buttons button:first-child {
        margin-right: 5px;
    }
    span {
        white-space: normal;
    }

    .published {
        color: #999;
        font-size: .9em;
    }
}
</style>
