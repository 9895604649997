<template>
    <SiteInput name="location" :value="value" @input="updateValue" :label="label || t.label_location"
        autocomplete="off" ref="geo" />
</template>

<script>
import analytics from 'Site/js/util/analytics';
import SiteInput from 'Site/component/SiteInput.vue';
import googleMapsLoader from '../js/googleMapsLoader';

export default {
    name: 'Geocomplete',
    components: {
        SiteInput
    },
    methods: {
        geocode() {
            const autocomplete = new google.maps.places.Autocomplete(this.$refs.geo.$refs.field, {
                fields: ['place_id', 'geometry.location', 'formatted_address', 'address_components'],
            });
            autocomplete.addListener('place_changed', () => {
                analytics('Places Details', window.location.pathname, this.$store.state.siteConfig.user.id);
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    this.$emit('geocomplete', { result: place });
                }
            });
        },
        initGeocomplete() {
            if (!this.$store.state.googleMaps.ready) {
                window.addEventListener('google.maps.ready', this.geocode);
            } else {
                this.geocode();
            }
        },
        updateValue() {
            this.$emit('input', this.value);
        }
    },
    mounted() {
        googleMapsLoader();
        this.initGeocomplete();
    },
    props: {
        label: {
            type: String
        },
        t: {
            type: Object,
            required: true
        },
        value: String
    }
};
</script>

<style>
.pac-container {
    z-index: 10000;
}
</style>
