import { throttle } from 'underscore';
import inViewport from './inViewport';
import socket from './socket';

export default class Impressions {
    constructor(selector, page) {
        this.selector = selector;
        this.page = page;
        this.sentImpressions = [];

        const throttledGetImpressions = throttle(this.getImpressions, 500);
        window.addEventListener('scroll', throttledGetImpressions.bind(this));

        this.getImpressions();
    }

    getImpressions() {
        const allElements = document.querySelectorAll(this.selector);
        const viewportImpressions = [];

        for (let i = 0; i < allElements.length; ++i) {
            const el = allElements[i];
            if (inViewport(el) && el.dataset && el.dataset.id) {
                let { type } = el.dataset;
                let item;

                if (type === 'default') {
                    type = 'photo';
                } else if (type === 'videos') {
                    type = 'video';
                }

                if (typeof type === 'undefined') {
                    item = {
                        id: parseInt(el.dataset.id, 10),
                        type: 'profile'
                    };
                } else {
                    item = {
                        id: parseInt(el.dataset.id, 10),
                        ownerId: parseInt(el.dataset.owner, 10),
                        adult: el.dataset.adult === '1',
                        type
                    };
                }

                if (this.sentImpressions.map(itemMap => itemMap.id).indexOf(parseInt(el.dataset.id, 10)) === -1) {
                    if (viewportImpressions.map(itemMap => itemMap.id).indexOf(parseInt(el.dataset.id, 10)) === -1) {
                        viewportImpressions.push(item);
                    }
                }
            }
        }

        if (viewportImpressions.length) {
            socket.emit('logImpressions', {
                impressions: viewportImpressions,
                page: this.page
            });

            this.sentImpressions = this.sentImpressions.concat(viewportImpressions);
        }
    }
}
