import ajax from 'Site/js/util/ajax.fetch';
import socket from 'Site/js/util/socket';
import store from 'Site/js/store';

export default {
    getActions(notification) {
        let actions = null;
        if (notification.action === 'friendRequest') {
            actions = {
                decline: {
                    url: notification.responseEndpoint,
                    data: { type: 1 }
                },
                accept: {
                    url: notification.responseEndpoint,
                    data: {},
                    method: 'update'
                }
            };
        } else if (notification.action === 'albumInvitation') {
            actions = {
                decline: {
                    url: notification.responseEndpoint,
                    data: {}
                }
            };
        } else if (notification.action === 'groupInvitation') {
            actions = {
                decline: {
                    url: notification.responseEndpoint,
                    data: { accepted: false },
                    method: 'update'
                },
                accept: {
                    url: notification.responseEndpoint,
                    data: { accepted: true },
                    method: 'update'
                }
            };
        } else if (notification.action === 'groupRequest') {
            actions = {
                decline: {
                    url: notification.responseEndpoint,
                    data: {
                        action: 'deny',
                        group: notification.target.id
                    },
                    method: 'update'
                },
                accept: {
                    url: notification.responseEndpoint,
                    data: {
                        action: 'approve',
                        group: notification.target.id
                    },
                    method: 'update'
                }
            };
        } else if (notification.action === 'eventInvitation') {
            actions = {
                decline: {
                    url: notification.responseEndpoint,
                    data: {}
                },
                accept: {
                    url: notification.responseEndpoint,
                    data: {},
                    method: 'update'
                }
            };
        } else if (notification.action === 'eventAttends') {
            actions = {
                decline: {
                    url: notification.responseEndpoint,
                    data: {}
                },
                accept: {
                    url: notification.responseEndpoint,
                    data: {},
                    method: 'update'
                }
            };
        }
        return actions;
    },
    getButtons(notification) {
        const actions = this.getActions(notification);
        const buttons = [];

        if (actions) {
            buttons.push({
                class: 'danger',
                text: store.state.gt.common.decline,
                callback: () => { this.declineRequest(actions); this.clear(notification); }
            });
            if (actions.accept) {
                buttons.push({
                    class: 'success',
                    text: store.state.gt.common.accept,
                    callback: () => { this.acceptRequest(actions); this.clear(notification); }
                });
            }
        }

        return buttons;
    },
    clear(notification) {
        ajax.delete('activity', { id: notification.id });
    },
    declineRequest(actions) {
        const method = actions.decline.method || 'delete';
        ajax[method](actions.decline.url, actions.decline.data).then((response) => {
            store.dispatch('notify', { msg: response.message });
        });
    },
    acceptRequest(actions) {
        const method = actions.accept.method || 'create';
        ajax[method](actions.accept.url, actions.accept.data).then((response) => {
            store.dispatch('notify', { msg: response.message });
        });
    },
    store: null,
    on(event, callback) {
        socket.on(event, callback);
    }
};
