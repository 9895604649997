<template>
    <form @submit.prevent="submit">
        <div v-if="!loading && !callback && !recipient" :class="['form-group', { 'has-error': form.errors.has('user') }]">
            <selectFriends :preload="true" :single="true" @selected-friends="setRecipient"></selectFriends>
            <span class="help-block" v-if="form.errors.has('user')">{{ form.errors.get('user') }}</span>
        </div>
        <div v-if="!loading && message.presetMessages" class="form-group">
            <label>{{ t.preset }}</label>
            <select class="form-control" v-model="preset" @change="usePreset">
                <option value="" selected="selected">{{ t.custom_message }}</option>
                <optgroup v-for="category in presetCategories" :key="category" :label="category">
                    <option v-for="(message, index) in message.presetMessages[category]" :key="message.subject"
                        :value="`${category}--${index}`">{{ message.subject }}</option>
                </optgroup>
            </select>
        </div>
        <div v-if="!loading" :class="['form-group', { 'has-error': form.errors.has('subject') }]">
            <label for="subject">{{ gt.messaging.subject }}</label>
            <input type="text" class="form-control" @input="validate('subject')" :placeholder="gt.messaging.subject"
                v-model="form.subject" ref="messageSubject">
            <span class="help-block" v-if="form.errors.has('subject')">{{ form.errors.get('subject') }}</span>
        </div>
        <slot v-if="!loading"></slot>
        <div v-if="!loading" :class="['form-group', { 'has-error': form.errors.has('body') }]">
            <label for="body">{{ gt.messaging.message }}</label>
            <text-box :media-picker="true" :private-media="true" v-model="form.body" @input="validate('body')"
                @update-media="updateMedia" ref="messageBody"></text-box>
            <span class="help-block" v-if="form.errors.has('body')">{{ form.errors.get('body') }}</span>
        </div>
        <div v-if="!loading && message.presetMessages" class="checkbox">
            <label for="message-from-admin">
                <input id="message-from-admin" type="checkbox" value="1" v-model="form.admin">{{ t.send_from_admin }}
            </label>
        </div>
        <share-card v-if="!loading && form.share" :heading="message.share.heading" :icon="message.share.icon"
            :link="message.share.link" :text="message.share.text">
            <span class="fa-stack fa-stack-remove" @click="removeShare">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-remove fa-stack-1x fa-inverse"></i>
            </span>
        </share-card>
        <loading v-if="loading"></loading>
    </form>
</template>

<script>
import { mapState } from 'vuex';

import analytics from 'Site/js/util/analytics';
import Form from 'Site/js/util/form';
import { logActivityMessage } from 'Site/js/util/metrics';

import Loading from 'Site/component/Loading.vue';
import SelectFriends from 'Site/component/SelectFriends.vue';
import TextBox from 'Site/component/TextBox.vue';
import ShareCard from './ShareCard.vue';

export default {
    name: 'Message',
    components: {
        Loading,
        SelectFriends,
        ShareCard,
        TextBox
    },
    computed: {
        ...mapState(['gt', 'message', 'siteConfig', 't']),
        presetCategories() {
            return Object.keys(this.message.presetMessages);
        }
    },
    data() {
        return {
            form: new Form({
                admin: false,
                body: '',
                media: [],
                user: '',
                share: this.$store.state.message.share ?
                    { id: this.$store.state.message.share.id, type: this.$store.state.message.share.type } : null,
                subject: ''
            }, {
                body: [{ type: 'required', message: this.$store.state.gt.messaging.please_enter_message }],
                user: this.userRequired ? ['required'] : null,
                subject: ['required']
            }),
            loading: false,
            preset: this.$store.state.message.preset
        };
    },
    methods: {
        removeShare() {
            this.form.share = null;
        },
        reset() {
            this.form.body = '';
            this.form.user = this.recipient;
            this.form.subject = '';
            this.form.media = [];
        },
        save() {
            this.loading = true;
            const form = this.form.create('message');
            if (form) {
                if (this.form.share) {
                    analytics('share', 'sent', this.message.share.type);
                }

                if (this.message.metrics) {
                    const cardData = this.message.metrics;

                    logActivityMessage(
                        cardData.actor[0].id,
                        cardData.action
                    );
                }

                form.then((response) => {
                    this.$store.dispatch('notify', { msg: response.message });
                    this.$parent.$emit('hideModal');
                    this.loading = false;
                    this.reset();
                    if (typeof this.message.saveCallback === 'function') {
                        this.message.saveCallback({ noteId: response.noteId });
                    }
                }).catch(() => {
                    this.loading = false;
                });
            }
        },
        setRecipient(recipients) {
            if (recipients[0]) {
                this.form.user = recipients[0].userName;
                this.form.errors.clear();
            } else {
                this.form.user = '';
            }
        },
        submit() {
            if (!this.form.user) {
                this.form.user = this.recipient;
            }
            if (this.form.validate()) {
                if (this.callback) {
                    this.callback({ body: this.form.body, subject: this.form.subject });
                    this.reset();
                    this.$parent.$emit('hideModal');
                } else {
                    this.save();
                }
            }
        },
        updateMedia(medias) {
            this.form.media = medias;
        },
        usePreset() {
            if (this.preset) {
                const presetParts = this.preset.split('--');
                this.form.body = this.message.presetMessages[presetParts[0]][presetParts[1]].body.replace(/\n\n/g, '<br>');
                this.form.subject = this.message.presetMessages[presetParts[0]][presetParts[1]].subject;
            } else {
                this.form.body = '';
                this.form.subject = '';
            }
            this.$refs.messageBody.setContent(this.form.body);
        },
        validate(field) {
            this.form.errors.clear(field);
            this.form.validate(field);
        }
    },
    mounted() {
        this.$on('submit', this.submit);
        this.$on('show', () => {
            // Give modal time to show
            setTimeout(() => {
                this.$refs.messageSubject.focus();
            }, 300);
        });
        if (this.message.presetMessages) {
            this.form.admin = true;
            if (this.preset) {
                this.usePreset();
            }
        }
    },
    props: {
        callback: Function,
        recipient: {
            type: String,
            default: ''
        },
        userRequired: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        'form.user': function formUser() {
            if (this.form.user === this.siteConfig.user.name) {
                this.$store.dispatch('notify', { type: 'error', msg: this.t.messaging.send_message_to_self });
                this.form.user = '';
            }
        }
    }
};

</script>
