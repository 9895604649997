import EmailVerify from 'User/component/EmailVerify.vue';
import Modal from 'Site/component/Modal.vue';
import { mapState } from 'vuex';

export default {
    components: {
        EmailVerify,
        Modal
    },
    computed: mapState({
        notEmailVerified: state => state.config.data.verifyId !== null,
        t: state => state.t.email_verify
    }),
    data() {
        return {
            showEmailVerify: false
        };
    }
};
