<template>
<div class="comments">
    <comment-form v-if="formAtTop && showForm" v-on:add="addComment" :upgrade-prompt="upgradePromptOnForm" :rating="rating"
        :show-like-button="showLikeButton" :share-data="shareData" :id="id" :like-id="like" :type="type" :metrics="metrics"
        :callback="callback" :t="t" :small="small" :require-moderation="requireModeration"></comment-form>
    <div class="comment-container" v-if="!loading">
        <comment v-for="comment in comments" :key="comment.id" :comment="comment" :t="t" :require-moderation="requireModeration"
        :upgrade-prompt="upgradePromptOnForm" :foreign="id" :type="type" :metrics="metrics" @edit="editComment" @delete="deleteComment"
        @reply="addReply"></comment>
        <a v-if="comments.length < totalItems && !loading && !loadingMore" href="#" @click.prevent="loadMore"
            class="btn btn-primary btn-block">
            {{ gt.common.load_x_more.replace('%num', numberToLoad) }}
        </a>
        <loading v-if="loadingMore"></loading>
    </div>
    <loading v-if="loading"></loading>
    <comment-form v-if="!formAtTop && showForm" v-on:add="addComment" :upgrade-prompt="upgradePromptOnForm" :show-like-button="showLikeButton"
        :share-data="shareData" :id="id" :like-id="like" :type="type" :metrics="metrics" :callback="callback" :t="t" :small="small" :rating="rating"
        :require-moderation="requireModeration"></comment-form>
</div>
</template>

<script>
import ajax from 'Site/js/util/ajax.fetch';
import Impressions from 'Site/js/util/impressions';
import Loading from 'Site/component/Loading.vue';
import { mapState } from 'vuex';

import Comment from './Comment.vue';
import CommentForm from './CommentForm.vue';

export default {
    name: 'Comments',
    components: {
        Comment,
        CommentForm,
        Loading
    },
    methods: {
        addComment(commentItem) {
            this.comments.unshift(commentItem);
        },
        addReply({ reply, parent }) {
            parent.replies.push(reply);
            parent.numReplies += 1;
        },
        editComment({ id, newValue }) {
            const commentItem = this.comments.filter(checkComment => checkComment.id === id)[0];
            commentItem.content = newValue;
        },
        deleteComment({ id }) {
            this.comments = this.comments.filter(commentItem => commentItem.id !== id);
        },
        get(loadMore = false) {
            if (!loadMore && this.allComments[this.type + this.id]) {
                this.comments = this.allComments[this.type + this.id].comments;
                this.totalItems = this.allComments[this.type + this.id].totalItems;
                return false;
            }
            if (loadMore) {
                this.loadingMore = true;
            } else {
                this.comments = [];
                this.loading = true;
            }
            const currentId = this.id;
            return ajax.get('feedback', {
                type: this.type,
                foreign: this.id,
                'start-index': this.comments.length,
                'max-results': this.limit
            }).then((data) => {
                this.loading = false;
                this.loadingMore = false;
                // Check we are still showing the comments we requested
                // - fixes mediaviewer showing incorrect comments when quickly scrolling through
                if (this.id !== currentId) {
                    return;
                }
                if (data.canCreateFeedback === false) {
                    this.upgradePromptOnForm = true;
                }
                this.$emit('upgradeprompt', this.upgradePromptOnForm);
                this.comments = this.comments.concat(data.items);
                this.$store.dispatch('cacheComments', { comments: this.comments, totalItems: data.totalItems, key: this.type + this.id });
                this.totalItems = data.totalItems;
                this.rating = data.rating;
                if (data.isLiked) {
                    this.like = data.isLiked;
                }
            }).catch(() => {
                this.loading = false;
                this.$emit('upgradeprompt', true);
            });
        },
        loadMore() {
            this.get(true);
        }
    },
    data() {
        return {
            comments: [],
            loading: false,
            loadingMore: false,
            like: this.likeId,
            limit: 25,
            rating: {},
            totalItems: this.totalComments,
            upgradePromptOnForm: this.upgradePrompt
        };
    },
    computed: {
        ...mapState({
            allComments: state => state.comments,
            gt: state => state.gt
        }),
        numberToLoad() {
            const number = this.totalItems - this.comments.length;
            if (number < this.limit) {
                return number;
            }
            return this.limit;
        }
    },
    mounted() {
        this.comments = this.initialComments || [];
        const profileImpressionsComments = new Impressions('.comment-user', 'Comment');
        profileImpressionsComments.getImpressions();
        this.$on('load', this.get);
        this.$on('add', this.addComment);
        if (!this.initialComments) {
            this.get();
        }
    },
    props: {
        callback: Function,
        formAtTop: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            required: true
        },
        initialComments: Array,
        likeId: [Number, Boolean],
        metrics: Object,
        requireModeration: {
            type: Boolean,
            default: false
        },
        shareData: {
            type: Object
        },
        showForm: {
            type: Boolean,
            default: false
        },
        showLikeButton: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object
        },
        totalComments: {
            type: Number,
            default: 25
        },
        type: {
            type: String,
            required: true
        },
        upgradePrompt: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.comment-container {
    max-height: 245px;
    overflow-y: auto;
    clear: both;
}

#page-comments {
    .comment-container {
        max-height: inherit;
    }
}

@media (min-width: $screen-sm-min) {
    #page-comments {
        .comment-replies {
            padding-left: 60px;
            .comment {
                padding-right: 0;
            }
        }
        .comments > .comment-form {
            margin-bottom: 20px;
        }
        .comment-actions {
            margin-left: 73px;
        }
        .comment-textarea-wrapper {
            margin-bottom: 10px;
        }
        .comment-content {
            padding: 10px 0 10px 15px;
            line-height: 22px;
            margin-left: 58px;
        }
        .comment-user {
            width: 50px;
            height: 50px;
            .profile-result-avatar {
                width: 50px;
                height: 50px;
                div {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
}
</style>
