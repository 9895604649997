import { mapState } from 'vuex';
import SignupLeadin from 'User/component/SignupLeadin.vue';
import { throttle } from 'underscore';
import analytics from '../util/analytics';

/**
 * @mixin
 */
export default {
    components: {
        SignupLeadin
    },
    computed: {
        ...mapState({
            showSignupPrompt: state => state.join.showSignupPrompt,
            userId: state => state.siteConfig.user.id
        }),
        signupPromptAvailable() {
            return this.useScrollPrompt !== false && this.$store.state.siteConfig.data.signupPromptAvailable;
        },
        triggerHeight() {
            // trigger scroll prompt at 30% page scroll
            const trigger = (document.documentElement.scrollHeight / 100) * 70;
            return ((document.documentElement.scrollHeight - trigger) - window.outerHeight);
        }
    },
    data() {
        return {
            shown: false,
            closedByUser: false
        };
    },
    methods: {
        checkPageScroll() {
            // if user is not logged in && window scroll to bottom of page-body content
            if (window.pageYOffset >= this.triggerHeight && window.pageYOffset > 0) {
                if (this.showSignupPrompt) {
                    return;
                }
                // if user has not actively clicked close
                if (!this.closedByUser) {
                    if (!this.shown) {
                        // only log analytics if first time user has seen the prompt on this page
                        analytics('scrollprompt', 'shown to user', null, null);
                        this.shown = true;
                    }

                    this.triggerPrompt(true);
                }
            } else if (this.showSignupPrompt) {
                this.triggerPrompt();
            }
        },
        clickJoin() {
            if (this.$store.state.abtest.tests['story-join-prompt']) {
                this.$store.dispatch('abtest/complete', 'story-join-prompt');
            }
        },
        triggerPrompt(flag = false) {
            this.$store.dispatch('setSignupPrompt', flag);
        },
        promptClosedByUser() {
            this.closedByUser = true;
            this.triggerPrompt();
        }
    },
    mounted() {
        if (!this.userId && this.signupPromptAvailable) {
            // show in analytics that sign up prompt was available;
            analytics('scrollprompt', 'available to user', null, null);

            // see if page loads already scrolled
            this.checkPageScroll();
            window.addEventListener('scroll', throttle(this.checkPageScroll, 300));
        }
    }
};
