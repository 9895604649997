<template>
    <loading v-if="loading"></loading>
    <div v-else-if="!loading && !showPaymentSuccess" class="col-lg-12 upgrade-body" :class="{'mobile' : isMobile}">
        <section id="page-header">
            <header class="text-center">
                <h1>{{ headerText }}</h1>
            </header>
        </section>
        <div v-if="showPaymentFailedNotice" id="payment-failed" class="alert alert-danger text-center">
            <p v-html="t.payment_failed.replace('%ref', paymentReference).replace('%url', contactUrl)"></p>
        </div>
        <section v-if="!showPayViaOptions && !showPaymentForm" class="page-layout">
            <p v-if="!packages" class="text-center">{{ t.no_available_upgrades }}</p>
            <div v-else class="row">
                <div class="col-sm-12">
                    <new-upgrade-info
                        :isSpecialOffer="packages.specialOffer"
                        :localConfig="localConfig"
                        :packageLengths="packages.packageLengths"
                        :services="packageServicesList"
                        :selectedServiceLength="selectedServiceLength"
                        :fixedUpgradeButtons="fixedUpgradeButtons"
                        @set-selected-service-length="setSelectedServiceLength"
                        @service-gateway="upgradeSelected"
                        @payvia-options="setPayViaOptions"
                    ></new-upgrade-info>
                </div>
            </div>
            <!-- Upgrade icons -->
            <div class="row upgrade-icons-set">
                <div class="upgrade-icons">
                    <div class="icon">
                        <i class="fa fa-lock fa-2x"></i> <span>{{ t.secure }}</span>
                    </div>
                </div>
                <div class="upgrade-icons">
                    <div class="icon">
                        <i class="fa fa-user-secret fa-2x"></i> <span>{{ t.discrete_billing }}</span>
                    </div>
                </div>
                <div class="upgrade-icons">
                    <div class="icon">
                        <i class="fa fa-shield fa-2x"></i> <span>{{ t.data_protection }}</span>
                    </div>
                </div>
                <div class="upgrade-icons">
                    <div class="icon">
                        <i class="fa fa-bolt fa-2x"></i> <span>{{ t.instant_access }}</span>
                    </div>
                </div>
            </div>
            <!-- Offline upgrade options -->
            <template v-if="appName !== 'lush'">
                <hr />
                <div class="row offline-upgrade-options">
                    <h2 class="text-center">{{ t.upgrade_offline }}</h2>
                    <div :class="offlineUpgradeClass">
                        <div class="card padded text-center">
                            <h3><i class="fa fa-phone" aria-hidden="true"></i> {{ t.upgrade_by_phone }}</h3>
                            <p>
                                {{ t.simply_dial }}:<br/>
                                <a href="tel:+443704909800" class="tel">+44 (0)370 490 9800</a><br/>
                                {{ t.available }}: {{ lcData.availableTimes }}
                            </p>
                        </div>
                    </div>
                    <div v-if="lcData.upgradeByPost" class="col-md-4 col-sm-6">
                        <div class="card padded text-center">
                            <h3><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ t.upgrade_by_post }}</h3>
                            <p>
                                {{ t.cheques_to }}<br/>
                                <span>Symbios Group</span><br/>
                                <a :href="upgradePdfFormUrl" target="_blank">{{ t.view_upgrade_form }}</a>
                            </p>
                            <br />
                        </div>
                    </div>
                    <div v-if="typeof lcData.upgradeByTransfer !== 'undefined' && lcData.upgradeByTransfer !== false"
                        class="col-md-6 col-sm-8">
                        <div class="card padded text-center">
                            <h3><i class="fa fa-money" aria-hidden="true"></i> {{ t.upgrade_by_bank }}</h3>
                            <p>
                                <strong>{{ t.account }}:</strong> {{ lcData.upgradeByTransfer.name }} / {{ lcData.upgradeByTransfer.number }}<br />
                                <template v-if="typeof lcData.upgradeByTransfer.iban !== 'undefined'">
                                    <span class="tel">
                                        {{ t.iban }}: {{ lcData.upgradeByTransfer.iban }} | {{ t.swift }}: {{ lcData.upgradeByTransfer.swift }}
                                    </span><br />
                                </template>
                                {{ t.include_username_in_ref }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
        </section>
        <div :class="{'hide' : !showPaymentSecure}" id="payment-secure-container">
            <iframe :src="threedFormUrl" id="payment-secure"></iframe>
        </div>
        <pay-via-options v-if="showPayViaOptions"
            :localConfig="localConfig"
            :upgradeService="payViaOptions"
            @service-gateway="upgradeSelected"
        ></pay-via-options>
        <payment-form v-if="showPaymentForm"
            :forceGateway="lcData.gateway"
            :selectedService="selectedService"
            :activityAction="action"
            :actor="actor"
            :statAttributes="statAttributes"
            :userUpgradeReason="reason"
            :localConfig="localConfig"
            @payment-failed="paymentFailed"
            @payment-success="paymentSuccess"
            @payment-3dsecure="payment3DSecure"
        ></payment-form>
    </div>
    <payment-success v-else-if="!loading && showPaymentSuccess"
        :response="paymentSuccessResponse"
        :localConfig="localConfig"
    ></payment-success>
</template>

<script>
import { mapState } from 'vuex';
import ajax from 'Site/js/util/ajax.fetch';
import analytics from '../js/util/analytics';
import Loading from 'Site/component/Loading.vue';
import { logUpgradeDisplay } from '../js/util/metrics';
import NewUpgradeInfo from './NewSiteUpgradeInfo.vue';
import PaymentSuccess from './SitePaymentSuccess.vue';
import PaymentForm from './SitePaymentForm.vue';
import PayViaOptions from './SitePayViaOptions.vue';
import UpgradeCurrent from './SiteUpgradeCurrent.vue';

export default {
    name: 'SiteUpgrade',
    data() {
        return {
            action: this.metrics && this.metrics.action ? this.metrics.action : null,
            actor: this.metrics && this.metrics.actor ? this.metrics.actor[0].id : null,
            fixedUpgradeButtons: false,
            loading: true,
            localConfig: {},
            localConfigLoaded: false,
            paymentReference: '',
            payViaOptions: {},
            showMakePayment: false,
            showPaymentForm: false,
            showPaymentFailedNotice: false,
            showPaymentSecure: false,
            showPaymentSuccess: false,
            showPayViaOptions: false,
            paymentSuccessResponse: {},
            selectedServiceLength: 3,
            selectedService: null,
            userUpgradeReason: '',
            which: decodeURIComponent(document.cookie.replace(new RegExp(`'(?:(?:^|.*;)\\s*'
                ${encodeURIComponent('upgrade').replace(/[-.+*]/g, '\\$&')}
                '\\s*\\=\\s*([^;]*).*$)|^.*$'`), '$1')) || null
        };
    },
    components: {
        Loading,
        NewUpgradeInfo,
        PaymentForm,
        PaymentSuccess,
        PayViaOptions,
        UpgradeCurrent,
    },
    computed: {
        ...mapState({
            appName: state => state.siteConfig.siteShortCode,
            isMobile: state => state.isMobile,
            threedFormUrl: state => state.siteConfig.pages.threeDSecure,
            upgradeUrl: state => state.siteConfig.endpoints.upgrade,
        }),
        checkoutUrl() {
            return this.localConfigLoaded ? this.localConfig.endpoints.checkout : null;
        },
        contactUrl() {
            return this.localConfigLoaded ? this.localConfig.pages.contact : null;
        },
        defaultYearly() {
            if (!this.localConfigLoaded || !this.localConfig.data.defaultYearly) {
                return false;
            }
            return true;
        },
        headerText() {
            if (!this.localConfigLoaded) {
                return '';
            }
            if (this.showMakePayment) {
                return this.t.make_payment;
            }
            return this.userUpgradeReason ? this.userUpgradeReason : this.t.upgrade_now;
        },
        lcData() {
            return this.localConfigLoaded ? this.localConfig.data : null;
        },
        offlineUpgradeClass() {
            if (!this.localConfigLoaded) {
                return null;
            }
            if (this.lcData.upgradeByPost) {
                return 'col-md-4 col-md-offset-2 col-sm-6';
            } else if (this.lcData.upgradeByTransfer !== false) {
                return 'col-md-4 col-md-offset-1 col-sm-12';
            }
            return 'col-md-4 col-md-offset-4 col-sm-12';
        },
        packages() {
            return this.localConfigLoaded ? this.localConfig.data.packages : null;
        },
        packageServicesList() {
            // return either current service placeholder or servies for currently selected package
            return this.packages.services.filter(service => service.service.current ||
                service.service.paymentServiceLength === this.selectedServiceLength);
        },
        restrictions() {
            return this.localConfigLoaded ? this.localConfig.data.restrictions : null;
        },
        statAttributes() {
            return {
                newUpgradeInfo: true // @todo remove when stripping old code
            };
        },
        t() {
            return this.localConfigLoaded ? this.localConfig.translate : null;
        },
        upgradePdfFormUrl() {
            return this.localConfigLoaded ? this.lcData.upgradeForm : null;
        },
        countPackageOptions() {
            if (!this.localConfigLoaded) {
                return null;
            }
            const packageOptions = [];
            Object.keys(this.packages.packageLengths).forEach((length) => {
                packageOptions.push(length);
            });
            return packageOptions.length;
        },
    },
    methods: {
        setDefaultSelectedServiceLength() {
            if (this.defaultYearly) {
                const yearlyAvailable = this.packages.packageLengths.filter(packageLength => packageLength === 4).length === 1;
                this.selectedServiceLength = yearlyAvailable ? 4 : 3;
            } else if (this.packages && this.countPackageOptions > 1) {
                // default is monthly
                this.selectedServiceLength = 3;
            } else {
                // if only one package available make it's length default selected
                this.selectedServiceLength = this.packages ? this.packages.packageLengths[0] : null;
            }
        },
        setSelectedServiceLength(length) {
            this.selectedServiceLength = length;
        },
        loadLocalConfig(reason, reasonOnly = false) {
            const data = { reason };
            if (reasonOnly) {
                data.reasonOnly = true;
            }
            ajax.get(this.upgradeUrl, data).then((response) => {
                this.loading = false;
                if (reasonOnly) {
                    this.userUpgradeReason = response.userUpgradeReason;
                    return;
                }
                this.localConfig = JSON.parse(response);
                this.userUpgradeReason = this.localConfig.data.userUpgradeReason;
                this.localConfigLoaded = true;
                this.setDefaultSelectedServiceLength();
            });
        },
        payment3DSecure() {
            document.querySelector('#payment-form').classList.add('hide');
            this.showPaymentSecure = true;
            this.showMakePayment = true;
        },
        paymentFailed(event) {
            this.showPaymentSecure = false;
            document.querySelector('#payment-form').classList.remove('hide');
            this.showPaymentFailedNotice = event.status;
            this.paymentReference = typeof event.reference !== 'undefined' ? event.reference : '';
        },
        paymentSuccess(response) {
            this.showPaymentSecure = false;
            this.paymentSuccessResponse = response;
            this.showPaymentSuccess = true;
            analytics('upgrade', 'complete', this.which);
        },
        setPayViaOptions(currentPackage) {
            this.showPayViaOptions = true;
            this.showMakePayment = true;
            this.payViaOptions = currentPackage;
        },
        upgradeSelected(selectedService) {
            this.selectedService = selectedService;
            // redirect for PayPal
            if (selectedService.serviceGateway.gateway.type === 'form') {
                window.location.href = `${this.checkoutUrl}/${selectedService.serviceGateway.id}`;
            } else {
                // take card payment
                this.showPayViaOptions = false;
                this.showPaymentForm = true;
                this.showMakePayment = true;
            }
        },
        upgradeText(serviceGateway, totalGateways) {
            return totalGateways === 1 ? this.t.upgrade_now : serviceGateway.gateway.description;
        },
    },
    created() {
        // get json config data
        if (this.$store.state.config && this.$store.state.config.data && this.$store.state.config.data.upgradePage) {
            this.localConfig = this.$store.state.config;
            this.localConfigLoaded = true;
            this.setDefaultSelectedServiceLength();
            this.loading = false;
        }
    },
    mounted() {
        if (this.reason) {
            this.userUpgradeReason = this.reason;
        }
        // modal analytics and pass in reason for trigger
        this.$on('show', () => {
            this.loading = true;
            this.showPaymentForm = false;
            this.showPayViaOptions = false;
            this.showPaymentSecure = false;
            this.showMakePayment = false;
            this.fixedUpgradeButtons = true;
            // if localConfig already loaded get reason only
            const reasonOnly = this.localConfigLoaded;
            this.loadLocalConfig(this.reason, reasonOnly);
            this.$parent.$emit('setclasses', 'modal-upgrade-notice');
            analytics('upgradeModal', 'show', this.reason);

            let action = null;
            let actor = null;
            /* eslint prefer-destructuring: 0 */
            if (this.metrics && this.metrics.action) {
                action = this.metrics.action;
                actor = this.metrics.actor[0].id;
            }
            logUpgradeDisplay(this.reason, action, actor);
        });
    },
    props: {
        metrics: {
            type: Object
        },
        reason: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-body .col-sm-12 {
    padding-left: 5px;
    padding-right: 5px;
}
</style>

<style lang="scss">
@import "~Site/scss/variables";
@import "~Site/scss/upgrade.scss";
@import "~Site/scss/payment.response.scss";

 .upgrade-body {
    padding-bottom: 30px;
    float: none !important;
}
.upgrade-body.mobile #page-header h1 {
    margin-bottom: 10px;
}
</style>
