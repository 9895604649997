import { getCookie } from './cookie';
import socket from './socket';
import store from '../store';
import upgradePath from './upgradePath';

let interactions = 0;

export default {
    interactions
};

export function isEnabled() {
    return (store.state.config && store.state.config.data && store.state.config.data.ActivityMetrics &&
        store.state.config.data.ActivityMetrics.enabled) ||
        store.state.siteConfig.data.siteMetrics;
}

export function logAccountUpgrade(target, action, subject) {
    if (isEnabled() || store.state.siteConfig.data.siteMetrics) {
        socket.emit('logAccountUpgrade', {
            user: store.state.siteConfig.user.id,
            target,
            action,
            session_id: getCookie('SID'),
            subject,
            path: upgradePath.get()
        });

    }
}

export function logActivityClick(subject, target, action, system) {
    interactions += 1;
    system = system === true ? 1 : 0;

    if (isEnabled()) {
        socket.emit('createActivityFeedClick', {
            user: store.state.siteConfig.user.id,
            subject,
            target,
            action,
            session_id: getCookie('SID'),
            system
        });
    }
}

export function logActivityMessage(subject, action, system) {
    interactions += 1;

    if (isEnabled()) {
        socket.emit('logActivityMessage', {
            user: store.state.siteConfig.user.id,
            subject,
            action,
            session_id: getCookie('SID'),
            system
        });
    }
}

export function logUpgradeDisplay(target, action, subject) {
    if (isEnabled() || store.state.siteConfig.data.siteMetrics) {
        socket.emit('logUpgradeDisplay', {
            user: store.state.siteConfig.user.id,
            target,
            action,
            session_id: getCookie('SID'),
            subject
        });
    }
}

/* eslint camelcase: 0 */
export function logInteractionRate(cards_in_viewport, time_on_page, interaction_time) {
    if (isEnabled()) {
        const session_id = getCookie('SID');

        if (session_id) {
            socket.emit('logInteractionRate', {
                user: store.state.siteConfig.user.id,
                cards_in_viewport,
                interactions,
                time_on_page,
                interaction_time,
                session_id
            });
        }
    }
}
