export default function (element) {

    const $el = document.querySelector(element);
    if (!$el) {
        return;
    }
    let duration = $el.getAttribute('data-countdown') * 1000;
    const interval = 1000;
    const countdown = () => {
        duration -= interval;

        const days = Math.floor(duration / (24 * 60 * 60 * 1000));
        const daysms = duration % (24 * 60 * 60 * 1000);
        const hours = Math.floor((daysms) / (60 * 60 * 1000));
        const hoursms = duration % (60 * 60 * 1000);
        const minutes = Math.floor((hoursms) / (60 * 1000));
        const minutesms = duration % (60 * 1000);
        const seconds = Math.floor((minutesms) / (1000));

        document.querySelector('span.days').innerText = days < 10 ? `0${days}` : days;
        document.querySelector('span.hours').innerText = hours < 10 ? `0${hours}` : hours;
        document.querySelector('span.minutes').innerText = minutes < 10 ? `0${minutes}` : minutes;
        document.querySelector('span.seconds').innerText = seconds < 10 ? `0${seconds}` : seconds;
    };

    if (duration > 0) {
        countdown();
        setInterval(countdown.bind(this), interval);
    }
}
