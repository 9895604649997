import Vue from 'vue';
import EmailVerify from 'User/component/EmailVerify.vue';
import store from 'Site/js/store';

export default function () {
    new Vue({ /* eslint no-new: 0 */
        el: '#page-body',
        name: 'PleaseVerify',
        store,
        components: {
            EmailVerify
        }
    });
}
