<template>
<div :class="['signup-leadin', { 'show-leadin': show }]">
    <div :class="['signup-content', { 'show-content': show }]">
        <button v-if="closable" type="button" class="close" @click.stop="hide" :aria-label="gt.close"><span aria-hidden="true">&times;</span></button>
        <h2>{{ gt.sign_up_for_free }}</h2>
        <button type="submit" @click="showJoin" class="btn btn-success btn-lg">{{ gt.join_now }}</button>
    </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'SignupLeadin',
    computed: mapState({
        gt: state => Object.assign({}, state.gt.join, state.gt.common),
        show: state => state.join.showSignupPrompt
    }),
    methods: {
        hide() {
            this.$emit('hide');
        },
        showJoin() {
            this.$emit('click-join');
            this.$store.dispatch('showJoin');
        }
    },
    props: {
        closable: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";
.signup-leadin {
    .signup-content {
        width: 100%;
        text-align: center;
        position: fixed;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 999;
        padding: 15px;
        bottom: -375px;
        opacity: 1;
        transition: bottom .8s;
        &.show-content {
            bottom: 0;
        }
        .close {
            color: #fff;
            opacity: 0.8;
            position: fixed;
            right: 10px;
            margin-top: -5px;
            text-align: right;
        }
        h2 {
            text-align: center;
            color: #fff;
            padding-bottom: 5px;
            font-weight: 400;
        }
        .btn {
            margin: 2px auto;
            min-width: 25%;
        }
    }
}
</style>
