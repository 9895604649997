
/**
 * @mixin - determine the x y coords for a user triggered popover component, within the boundary of the window.
 */
export default {
    data() {
        return {
            posLeft: '0px',
            posTop: '0px'
        };
    },
    methods: {
        setPosition(e, popoverWidth, popoverHeight) {
            const rect = e.target.getBoundingClientRect();
            const margin = 10;

            const scrollTop = document.querySelector('body').scrollTop || document.querySelector('html').scrollTop;

            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;

            // set center of popover to align with center of e.target
            this.posLeft = (rect.left + (rect.width / 2)) - (popoverWidth / 2);
            this.posTop = rect.bottom + scrollTop;

            const positionAbove = this.posTop - (rect.height + popoverHeight);

            // Close to left
            if (rect.left + (rect.width / 2) < (popoverWidth / 2) + margin) {
                this.posLeft = rect.left;
                this.posTop = positionAbove;
            // Close to right
            } else if (this.posLeft + popoverWidth + margin > windowWidth) {
                const pushLeft = (this.posLeft + popoverWidth + (margin * 2)) - windowWidth;
                this.posLeft = this.posLeft - pushLeft;
            }

            // Close to bottom
            if (rect.bottom + popoverHeight + (margin * 2) > windowHeight) {
                this.posTop = positionAbove;
            }

            this.posLeft = `${this.posLeft}px`;
            this.posTop = `${this.posTop}px`;
        }
    }
};
